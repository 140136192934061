// material
import { Box, Card, Grid, Stack, TextField, Button } from "@mui/material";
// utils
//
import { useSnackbar } from "notistack";
import { Form, FormikProvider, useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { LoadingButton } from "@mui/lab";

import { object, string } from "yup";

import DeleteButton from "../content/DeleteButton";
import { ColourPaletteFromFirestore } from "models/ColourPalette";
import { updateColourPalette } from "utils/firebase/contentUtils";
import { ErrorException } from "../../../types/settings";

// ----------------------------------------------------------------------

const ColourPaletteSchema = object({
    id: string()?.required(),
    color1: string()?.required(),
    color2: string()?.required(),
    color3: string()?.required(),
    color4: string()?.required(),
    color5: string()?.required(),
    color6: string()?.required(),
    color7: string()?.required(),
});

type Props = {
  data?: ColourPaletteFromFirestore;
  colourPaletteId?: string;
  deleteColourPalette: (id: string) => void;
};

export default function ColourPaletteContentForm({ data, colourPaletteId, deleteColourPalette }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data ?? {
        id: colourPaletteId ?? "",
        color1: "",
        color2: "",
        color3: "",
        color4: "",
        color5: "",
        color6: "",
        color7: "",
    },
    validationSchema: ColourPaletteSchema,
    onSubmit: async (values) => {
      try {
        enqueueSnackbar(`Updating ${values?.id}`);
        const { id, color1, color2, color3, color4, color5, color6, color7 } = values;

        const doc: ColourPaletteFromFirestore = {
            id,
            color1,
            color2,
            color3,
            color4,
            color5,
            color6,
            color7,
        };
        
        await updateColourPalette(doc);
        enqueueSnackbar("Saved successfully!", { variant: "success" });
      } catch (error: ErrorException) {
        console.error("An error occurred: ", error);
        enqueueSnackbar("Failed to save", { variant: "error" });
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleReset } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit}>
              <fieldset style={{ border: "none" }}>
                <FocusError formik={formik} />
                <Stack spacing={3}>
                  <Stack
                    direction={{ xs: "column" }}
                    spacing={{ xs: 3, sm: 2 }}
                  >
                    <Stack spacing={3}>
                      <TextField
                        fullWidth
                        label="Id"
                        {...getFieldProps("id")}
                        error={Boolean(touched.id && errors.id)}
                        helperText={touched.id && errors.id}
                    />

                    {Array.from({ length: 7 }, (_, i) => {
                    const colorName = `color${i + 1}` as keyof ColourPaletteFromFirestore;
                    return (
                        <TextField
                        key={colorName}
                        fullWidth
                        label={`Color ${i + 1}`}
                        {...getFieldProps(colorName)}
                        error={Boolean(touched[colorName] && errors[colorName])}
                        helperText={touched[colorName] && errors[colorName]}
                        />
                    );
                    })}
                    </Stack>
                  </Stack>
                </Stack>

                <Box
                  sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                >
                  <Button sx={{ mr: 1 }} onClick={handleReset} type="button">Reset</Button>
                  {colourPaletteId && (
                    <DeleteButton
                    deleteItem={() => {deleteColourPalette(colourPaletteId)}}
                    />
                  )}
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Save Changes
                  </LoadingButton>
                </Box>
              </fieldset>
            </Form>
          </FormikProvider>
        </Card>
      </Grid>
    </Grid>
  );
}
