// material
import { Box, Card, Grid, Stack, TextField, Button, FormControlLabel, Checkbox, InputLabel, Select, MenuItem, FormControl } from "@mui/material";
// utils
//
import { useSnackbar } from "notistack";
import { Form, FormikProvider, useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { LoadingButton } from "@mui/lab";

import { boolean, object, string } from "yup";

import {updateImageContent} from "../../../utils/firebase/contentUtils";
import DeleteButton from "../content/DeleteButton";
import { ImageFromFirestore } from "models/Image";
import { ErrorException } from "../../../types/settings";

// ----------------------------------------------------------------------

const ImageContentSchema = object({
  id: string()?.required(),
  backupUrl: string()?.required(),
  isAnimation: boolean()?.required(),
  name: string()?.required(),
  styling: string()?.required(),
});

type Props = {
  data?: ImageFromFirestore;
  imageId?: string;
  deleteImage: (id: string) => void;
};

export default function ImageContentForm({ data, imageId, deleteImage }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data ?? {
      id: imageId ?? "",
      backupUrl: "",
      isAnimation: false,
      name: "",
      styling: "",
      width: 0,
    },
    validationSchema: ImageContentSchema,
    onSubmit: async (values) => {
      try {
        enqueueSnackbar(`Updating ${values?.id}`);
        const { id, backupUrl, isAnimation, name, styling, width } = values;
        const formattedStyling = styling.toLocaleLowerCase();
        const selectedWidth = Number(width);

        const doc: ImageFromFirestore = {
          id,
          backupUrl,
          isAnimation,
          name,
          styling: formattedStyling,
        };

        if (selectedWidth && selectedWidth > 0) {
          doc.width = selectedWidth;
        }
        
        await updateImageContent(doc);
        enqueueSnackbar("Saved successfully!", { variant: "success" });
      } catch (error: ErrorException) {
        console.error("An error occurred: ", error);
        enqueueSnackbar("Failed to save", { variant: "error" });
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleReset } = formik;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit}>
              <fieldset style={{ border: "none" }}>
                <FocusError formik={formik} />
                <Stack spacing={3}>
                  <Stack
                    direction={{ xs: "column" }}
                    spacing={{ xs: 3, sm: 2 }}
                  >
                    <Stack spacing={3}>
                      <TextField
                        fullWidth
                        label="Id"
                        {...getFieldProps("id")}
                        error={Boolean(touched.id && errors.id)}
                        helperText={touched.id && errors.id}
                      />

                      <TextField
                        fullWidth
                        label="Name"
                        {...getFieldProps("name")}
                        error={Boolean(touched?.name && errors?.name)}
                        helperText={touched?.name && errors?.name}
                      />

                      <TextField
                        fullWidth
                        label="BackupUrl"
                        {...getFieldProps("backupUrl")}
                        error={Boolean(touched?.backupUrl && errors?.backupUrl)}
                        helperText={touched?.backupUrl && errors?.backupUrl}
                      />

                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Alignment</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Alignment"
                            {...getFieldProps("styling")}
                        >
                            <MenuItem value={"center"}>Center</MenuItem>
                            <MenuItem value={"right"}>Right</MenuItem>
                        </Select>
                      </FormControl>

                      <TextField
                        fullWidth
                        label="Width (recommended: +80)"
                        {...getFieldProps("width")}
                        error={Boolean(touched?.width && errors?.width)}
                        helperText={touched?.width && errors?.width}
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            {...getFieldProps("isAnimation")}
                            checked={getFieldProps("isAnimation").value}
                          />
                        }
                        label="is Animation?"
                      />
                    </Stack>
                  </Stack>
                </Stack>

                <Box
                  sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                >
                  <Button sx={{ mr: 1 }} onClick={handleReset} type="button">Reset</Button>
                  {imageId && (
                    <DeleteButton
                    deleteItem={() => {deleteImage(imageId)}}
                    />
                  )}
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Save Changes
                  </LoadingButton>
                </Box>
              </fieldset>
            </Form>
          </FormikProvider>
        </Card>
      </Grid>
    </Grid>
  );
}
