import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";

import { db } from "../../contexts/FirebaseContext";
import { AccessRole } from "../../types/role/CCRoles";
import { DBCollectionNames } from "./constants";
import { omit } from "lodash";

export function useRoles() {
  const [loading, setLoading] = useState<boolean>(true);
  const [roles = [], setRoles] = useState<Array<AccessRole>>([]);
  const [rolesTitle = [], setRolesTitle] = useState<Array<string>>([]);

  useEffect(() => {
   let dbRef: firebase.firestore.CollectionReference | firebase.firestore.Query = db
      .collection(DBCollectionNames.ROLES)

    setLoading(true);
    let unsub = dbRef
      .onSnapshot(async (snapshot) => {
        const dbRoles= []
        for await(const doc of snapshot.docs) {
          const roleItem = doc.data();
          dbRoles.push({
            ...(roleItem as AccessRole), id: doc.id, createdOrUpdatedAt:
              (roleItem.createdOrUpdatedAt as firebase.firestore.Timestamp)?.toDate(),
          });
        }

        const titles = dbRoles.map((role) => role?.title);
        setRoles(dbRoles ?? []);
        setRolesTitle(titles ?? []);

        setLoading(false);
      });
    return () => unsub();
  }, []);

  const getCustomClaimsByRoleTitle = (roleTitle: String) => {
    for (const role of roles) {
      if (role.title === roleTitle) {
        return ` (${role.customClaims.join(", ")})`
      }
    }

    return ""
  }

  return { roles, rolesTitle, loading, getCustomClaimsByRoleTitle};
}

export function useRole(id: string) {
  const [loading, setLoading] = useState<boolean>(false);
  const [role, setRole] = useState<AccessRole>()

  useEffect(() => {
    if (!id?.length) {
      return
    }

    setLoading(true)
    const unsub = db
      .collection(DBCollectionNames.ROLES)
      .doc(id)
      .onSnapshot(
        (doc) => {
          if (doc?.exists) {
            setRole({ ...doc.data(), id: doc.id } as AccessRole)
          }
          setLoading(false)
        }
      );

    return () => unsub();
  }, [id]);

  return { role, loading }
}

export const deleteRoleByIdFromDB = async (
  roleId: string
): Promise<void> => {
  return db
    .collection(DBCollectionNames.ROLES)
    .doc(roleId)
    .delete()
};

export async function createOrUpdateRole(
  role: AccessRole
) {
  const docData = await db.collection(DBCollectionNames.ROLES).where("title","==", role.title).get();
  const existedRolesIds = docData.docs.map((doc) => doc.id);

  for (const existedRolesId of existedRolesIds) {
    if (existedRolesId !== role.id) {
      throw new Error("Role Already Exist with Same Title")
    }
  }


  return db.collection(DBCollectionNames.ROLES)
    .doc(role.id)
    .set({
      ...omit(role, "id"),
      createdOrUpdatedAt: (firebase.firestore.Timestamp.fromDate(new Date()) as firebase.firestore.Timestamp).toDate()
    }, { merge: true });
}

