import { db, firebaseAuth } from "contexts/FirebaseContext";
import firebase from 'firebase/compat/app'; 
import { VideoCallStatus } from "types/chat";
import { saveOrEditRelationship } from "./cloudFunctions";

export type ChineseUserRelationship = {
    counsellor: {
      name: string;
      id: string;
    };
    userEmail: string;
    status: string;
    createdAt: firebase.firestore.Timestamp;
    id: string;
    completedSessions?: RelationshipSession[];
    invitationCode: string;
    lastRequestAt: firebase.firestore.Timestamp;
};

export type RelationshipSession = {
  id: string;
  date: firebase.firestore.Timestamp;
  status: string;
}

export function getChineseUserRelationships(
  onSnapshot: (requests: ChineseUserRelationship[]) => void
) {
  return db.collection("chinese_user_relationships")
    .orderBy("createdAt", "desc")
    .onSnapshot(
      async (snapshot) => {
        let relationshipPromises: Promise<ChineseUserRelationship>[] = snapshot.docs.map(async (doc) => {
          const data = doc.data() as ChineseUserRelationship;
          const completedSessions = await doc.ref.collection("sessions")
            .where("status", "==", VideoCallStatus.BOTH_JOINED)
            .get()
            .then((snap) => {
              return snap.docs.map((sessionDoc) => {
                const sessionData = sessionDoc.data() as RelationshipSession;
                return {
                  ...sessionData,
                  id: sessionDoc.id,
                }
              });
            });

          return {
            ...data,
            id: doc.id,
            completedSessions,
            lastRequestAt: data.lastRequestAt ?? data.createdAt,
          };
        });

        const relationships = await Promise.all(relationshipPromises);
        relationships.sort((a, b) => {
          return b.lastRequestAt.toMillis() - a.lastRequestAt.toMillis();
        });

        onSnapshot(relationships);
      },
      (error) => {
        console.warn(`Error loading the chinese user relationships`, error);
      }
    );
}


  export function getChineseUserRelationshipById(
    relationshipId: string,
    onSnapshot: (relationship: ChineseUserRelationship) => void
  ){
    return db.collection("chinese_user_relationships")
    .doc(relationshipId)
    .onSnapshot(
      async (snapshot) => {
        const data = snapshot.data() as ChineseUserRelationship;
        onSnapshot({
          ...data,
          id: snapshot.id,
        });
      },
      (error) => {
        console.warn(`Error loading the chinese user relationship`, error);
      }
    );
  }

  export function getRelationshipSessions(
    relationshipId: string,
    onSnapshot: (sessions: RelationshipSession[]) => void
  ){
    return db.collection(`chinese_user_relationships/${relationshipId}/sessions`)
    .onSnapshot(
      async (snapshot) => {
        let sessions: RelationshipSession[] = snapshot.docs.map((doc) => {
          const data = doc.data() as RelationshipSession;
           
          return {
            ...data,
            id: doc.id,
          }
        });

        onSnapshot(sessions);
      },
      (error) => {
        console.warn(`Error loading the relationship sessions`, error);
      }
    );
  }


export function addOrUpdateRelationship(
  relationshipData: ChineseUserRelationship
) {

  const newData = {
    ...relationshipData,
    lastUpdatedBy: {
      email: firebaseAuth().currentUser?.email,
      uid: firebaseAuth().currentUser?.uid,
    },
  }

  return saveOrEditRelationship({ ...newData });
}

  export async function addOrUpdateSession(session: Partial<RelationshipSession>, relationshipId: string) {
    const newData = {
      ...session,
      lastUpdatedBy: {
        email: firebaseAuth().currentUser?.email,
        uid: firebaseAuth().currentUser?.uid,
      },
    }

    if (session.id) {
      return db.collection(`chinese_user_relationships/${relationshipId}/sessions`)
        .doc(session.id)
        .set(newData, { merge: true });
    } else {
      return db.collection(`chinese_user_relationships/${relationshipId}/sessions`)
        .add(newData);
    }
  }

  export async function deleteChineseUserRelationship(relationshipId: string) {
    const sessions = await db.collection(`chinese_user_relationships/${relationshipId}/sessions`).get();
    sessions.forEach((session) => {
      session.ref.delete();
    });

    return db.collection("chinese_user_relationships")
      .doc(relationshipId)
      .delete();
  }

  export async function deleteSession(relationshipId: string, sessionId: string) {
    return db.collection(`chinese_user_relationships/${relationshipId}/sessions`)
      .doc(sessionId)
      .delete();
  }

  export function getRelationshipSessionById(
    relationshipId: string,
    sessionId: string,
    onSnapshot: (session: RelationshipSession) => void
  ){
    return db.collection(`chinese_user_relationships/${relationshipId}/sessions`)
    .doc(sessionId)
    .onSnapshot(
      async (snapshot) => {
        const data = snapshot.data() as RelationshipSession;
        onSnapshot({
          ...data,
          id: snapshot.id,
        });
      },
      (error) => {
        console.warn(`Error loading the relationship session`, error);
      }
    );
  }

