import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
// material
import {Container} from "@mui/material";
// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import LoadingScreen from "../../components/LoadingScreen";
import Page404 from "../Page404";

// types
import {RegistrationCode} from "../../types/invitation/code";
import {getRegistrationCode} from "../../utils/firebase/registrationCodeUtils";
import RegistrationCodeForm from "../../components/_dashboard/registration-codes/RegistrationCodeForm";
import { ErrorException } from "../../types/settings";

// ----------------------------------------------------------------------

export default function CreateRegistrationCode() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const { code = "" } = useParams();
  const isEdit = pathname.includes("edit");
  const isView = pathname.includes("view");
  const [currentCode, setCurrentCode] = useState<RegistrationCode | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchCode() {
      try {
        setLoading(true);
        setError(null);

        const registrationCode = await getRegistrationCode(code);
        setCurrentCode(registrationCode);
        setLoading(false);
      } catch (e: ErrorException) {
        setError(e?.message || "something went wrong.");
        console.error({ errorGettingRegistrationCode: e });
      }
    }
    if (code) {
      fetchCode();
    } else if (!isEdit) {
      setLoading(false);
    }
  }, [code, isEdit]);

  let ContentToDisplay = <LoadingScreen />;
  if (error) {
    ContentToDisplay = <Page404 />;
  }
  if (!loading && !error) {
    ContentToDisplay = (
      <RegistrationCodeForm
        isEdit={isEdit}
        isView={isView}
        currentCode={currentCode}
      />
    );
  }

  let heading = "Create a new registration code";
  if (isEdit) {
    heading = "Edit registration code";
  } else if (isView) {
    heading = "registration code details";
  }

  return (
    <Page title="Create a new code">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={heading} links={[]} />
        {ContentToDisplay}
      </Container>
    </Page>
  );
}
