import { ReactNode, useEffect, useState } from 'react';
import { Container } from '@mui/material';
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';
import GoogleSignInButton from '../components/GoogleSignInButton';
import { ErrorException } from "../types/settings";

// ----------------------------------------------------------------------

type AuditLogsGuardProp = {
  children: ReactNode | string;
};

export default function AuditLogsGuard({ children }: AuditLogsGuardProp) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const { providerAccessToken, user } = useAuth();

  const haveAccessToken = (usr: typeof user, tokenObj: typeof providerAccessToken) =>
    usr?.id === tokenObj?.uid && tokenObj?.accessToken;

  useEffect(() => {
    try {
      if (haveAccessToken(user, providerAccessToken)) {
        setLoading(false);
        setError(false);

        return;
      }
    } catch (e: ErrorException) {
      console.error(e);

      setError(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (haveAccessToken(user, providerAccessToken)) {
      setLoading(false);
      setError(false);
    }
    // eslint-disable-next-line
  }, [providerAccessToken?.uid, providerAccessToken?.accessToken]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!Boolean(providerAccessToken?.accessToken) || error) {
    return (
      <Container>
        <GoogleSignInButton auditLogs={true} auditLogsError={error} />
      </Container>
    );
  }

  return <>{children}</>;
}
