import { db } from "contexts/FirebaseContext";
import firebase from "firebase/compat/app";

/**
 * `useDocumentData` will cause errors and crashes if an invalid path is
 * supplied to create a DocumentReference. That can happen when the user is typing.
 */
export default function validPathOrUndefined(
  path: string
): firebase.firestore.DocumentReference | undefined {
  const segments = path.split("/");
  const evenNumberOfSegments = segments.length % 2 === 0;
  const lastSegment = segments.slice(-1)[0];
  const lastSegmentNotBlank = lastSegment.trim().length > 0;
  if (evenNumberOfSegments && lastSegmentNotBlank) {
    return db.doc(path);
  }

  return undefined;
}
