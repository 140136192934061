import { CCRolesPossibleVals } from '../types/role/CCRoles';
import { firebaseAuth } from "contexts/FirebaseContext";
import { useEffect, useState } from "react";
import useAuth from "./useAuth";
import { ErrorException } from "../types/settings";

export const useCurrentRole = () => {
    const { user, therapistId } = useAuth();
    // usrRoles are the roles coming from customClaims.
    const [usrRoles, setUsrRoles] = useState<Array<CCRolesPossibleVals>>([]);
  
    useEffect(() => {
      firebaseAuth().currentUser
        ?.getIdTokenResult()
        ?.then((idTokenResult: any) => {
          setUsrRoles(idTokenResult?.claims?.usrRoles || []);
        })
        .catch((error: ErrorException) => {
          setUsrRoles([]);
          console.log(error);
        });
    }, [user, therapistId]);
  
    return usrRoles;
  };