import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { array, object, string } from "yup";

// material
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, Grid, Stack, TextField } from "@mui/material";

import { ReleaseNotes } from "../../../../types/releaseNotes";
import {
  AppLanguageTranslationType,
  AppTranslationKeyDescriptionType,
} from "../../../../types/translations";
import {
  addOrUpdateLanguageTranslationInBulk,
  addOrUpdateTranslationKeyAndDescription,
  metaInformationToLanguageField,
} from "../../../../utils/firebase/translationUtils";
import { find } from "lodash";
import firebase from "firebase/compat/app";
import { generateAndSaveAIBasedTranslations } from "../../../../utils/firebase/cloudFunctions";
import { SupportedLanguagesEnum } from "../../../../utils/constants";
import { firebaseAuth } from "../../../../contexts/FirebaseContext";
import { ErrorException } from "../../../../types/settings";

// ----------------------------------------------------------------------

type SuggestedTranslationFormProps = {
  isEdit: boolean;
  isView?: boolean;
  isNew?: boolean;
  theme: any;
  translationKeyWithDescription?: AppTranslationKeyDescriptionType;
  translationsValueAndApprovedBy?: Array<AppLanguageTranslationType>;
  accessToAllLanguagesTranslation: boolean;
  showEnglishTranslationViewOnly: boolean;
  accessLanguagesTranslationList: string[];
  releaseNote?: ReleaseNotes;
  translationsCodeToName: Record<string, string>;
};

export default function SuggestedTranslationForm({
  isNew,
  isView,
  isEdit,
  translationKeyWithDescription,
  translationsValueAndApprovedBy,
  accessToAllLanguagesTranslation,
  accessLanguagesTranslationList,
  theme,
  showEnglishTranslationViewOnly,
  translationsCodeToName,
}: SuggestedTranslationFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isSaveWithOutGenerating, setIsSaveWithoutGenerating] = useState(false);

  const ReleaseNoteSchema = Yup.object().shape({
    id: Yup.string().optional(),
    key: Yup.string().required().required("Translated Key is required"),
    description: Yup.string().required().required("Description is required"),
    languages: array(
      object().shape({
        locale: string(),
        value: string(),
      })
    ),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      id: translationKeyWithDescription?.id,
      key: translationKeyWithDescription?.key ?? "",
      description: translationKeyWithDescription?.description ?? "",
      languages: [],
    },

    validationSchema: ReleaseNoteSchema,
    onSubmit: async (values) => {
      try {
        let id = values.id || values.key;
        const enValue =
          (
            values.languages.find(
              ({ locale }) => locale === SupportedLanguagesEnum.ENGLISH
            ) as
              | {
                  value?: string;
                }
              | undefined
          )?.value ?? "";
        if (isNew && !enValue) return;

        if (
          values.key !== translationKeyWithDescription?.key ||
          values.description !== translationKeyWithDescription?.description
        ) {
          const newTranslationPayload: AppTranslationKeyDescriptionType = {
            id,
            key: values.key,
            description: values.description,
          };

          if (isNew) {
            newTranslationPayload.createdAt =
              firebase.firestore.Timestamp.fromDate(new Date());
          }

          await addOrUpdateTranslationKeyAndDescription(
            newTranslationPayload,
            true
          );
        }

        let translationValuesInLanguages: Array<AppLanguageTranslationType> =
          values.languages;
        if (isNew) {
          translationValuesInLanguages = translationValuesInLanguages.map(
            (translationValue) => {
              if (translationValue.locale === SupportedLanguagesEnum.ENGLISH) {
                translationValue.approvedBy = firebaseAuth().currentUser?.uid;
              }
              return translationValue;
            }
          );
        }

        if (showEnglishTranslationViewOnly) {
          translationValuesInLanguages = translationValuesInLanguages.filter(
            ({ locale }) => locale !== SupportedLanguagesEnum.ENGLISH
          );
        }

        if (isNew) {
          translationValuesInLanguages = translationValuesInLanguages.map(
            (translationValuesInLanguage) => ({
              ...translationValuesInLanguage,
              approvedBy: null,
            })
          );
        }

        if (isNew) {
          translationValuesInLanguages = translationValuesInLanguages.map(
              (translationValuesInLanguage) => ({
                ...translationValuesInLanguage,
                approvedBy: null,
              })
          );
        }

        await addOrUpdateLanguageTranslationInBulk(id, translationValuesInLanguages, true)

        const filterLanguagesWithEmptyValue = values.languages.filter(
          (item: { locale: string; value: string }) => !item.value.length
        );

        if (filterLanguagesWithEmptyValue.length && !isSaveWithOutGenerating) {
          await generateAndSaveAIBasedTranslations({
            translationId: id,
            targetLanguages: filterLanguagesWithEmptyValue?.map(
              ({ locale }) => locale
            ),
            translateKey: values.key,
            valueInEN: enValue,
            description: values.description,
            isSuggestedTranslation: true,
          });
        }

        enqueueSnackbar('Saved successfully!', { variant: 'success' });
        navigate(-1)
      } catch (e: ErrorException) {
        console.error(e);
        enqueueSnackbar("Failed to save", { variant: "error" });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  const languagesKey = "languages";
  const languagesFieldValues = getFieldProps(languagesKey).value || [];

  useEffect(() => {
    let langKeys = Object.keys(translationsCodeToName);
    if (!accessToAllLanguagesTranslation && !isNew) {
      langKeys = langKeys.filter((key) =>
        accessLanguagesTranslationList.includes(key)
      );
    }

    if (!langKeys.includes(SupportedLanguagesEnum.ENGLISH)) {
      langKeys = [SupportedLanguagesEnum.ENGLISH, ...langKeys];
    }

    const langFields = [];
    for (const locale of langKeys) {
      langFields.push({
        locale,
        value: find(translationsValueAndApprovedBy, { locale })?.value ?? "",
      });
    }
    setFieldValue(languagesKey, langFields);
  }, [
    setFieldValue,
    accessLanguagesTranslationList,
    accessToAllLanguagesTranslation,
    isNew,
    translationsValueAndApprovedBy,
    translationsCodeToName,
  ]);

  let submitBtnTitle = "Create Suggested translation";
  if (isView) {
    submitBtnTitle = "View Only";
  } else if (isEdit) {
    submitBtnTitle = "Save Changes with Translations";
  }

  const onSaveWithoutTranslations = () => {
    setIsSaveWithoutGenerating(true);
    formik.submitForm();
  };

  const onSaveWithTranslations = () => {
    setIsSaveWithoutGenerating(false);
    formik.submitForm();
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <fieldset disabled={isView} style={{ border: "none" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label="Translated Key"
                    disabled={isView || !accessToAllLanguagesTranslation}
                    {...getFieldProps("key")}
                    error={Boolean(touched.key && errors.key)}
                    helperText={touched.key && errors.key}
                  />
                  <TextField
                    fullWidth
                    label="Description"
                    disabled={isView || !accessToAllLanguagesTranslation}
                    {...getFieldProps("description")}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />

                  <Card
                    sx={{
                      ml: 3,
                      mr: 3,
                      border: `1px dashed ${theme.palette.grey}`,
                    }}
                  >
                    <CardContent>
                      {languagesFieldValues?.map(
                        (
                          item: { locale: string; value: string },
                          index: number
                        ) => (
                          <TextField
                            key={item.locale || index}
                            InputLabelProps={{ shrink: true }}
                            multiline
                            disabled={
                              isView ||
                              (item.locale === SupportedLanguagesEnum.ENGLISH &&
                                showEnglishTranslationViewOnly)
                            }
                            sx={{ width: "100%", mb: 5 }}
                            placeholder={`${metaInformationToLanguageField(
                              item.locale,
                              translationsCodeToName,
                              isNew
                            )}`}
                            label={`Enter Translated Value in ${
                              translationsCodeToName[item.locale] ?? ""
                            }`}
                            defaultValue={""}
                            {...getFieldProps(`${languagesKey}.${index}.value`)}
                            type={"text"}
                          />
                        )
                      )}
                    </CardContent>
                  </Card>

                  <Box
                    sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                  >
                    <LoadingButton
                      type="button"
                      variant="contained"
                      onClick={onSaveWithoutTranslations}
                      loading={isSubmitting}
                      sx={{ mr: 4 }}
                    >
                      Save Without Translations
                    </LoadingButton>

                    <LoadingButton
                      type="button"
                      variant="contained"
                      onClick={onSaveWithTranslations}
                      loading={isSubmitting}
                    >
                      {submitBtnTitle}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </fieldset>
      </Form>
    </FormikProvider>
  );
}
