import { ErrorException } from "../../types/settings";

export function parsePort(port: string, fallbackPort: number): number {
  let emulatorPort = fallbackPort;
  try {
    emulatorPort = parseInt(port, 10);
  } catch (e: ErrorException) {
    console.warn(
      `Error parsing port '${port}'. Using fall back port '${fallbackPort}' instead.`,
      e
    );
  }
  return emulatorPort;
}
