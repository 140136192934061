import React from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// material
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { ContentVersionsField } from "../../../../types/translations";
import { incrementContentVersion } from "../../../../utils/firebase/cloudFunctions";
import { MultilingualLocale } from "types/multilingualLocales";
import { addOrUpdateMultilingualLocale } from "utils/firebase/multilingualLocaleUtils";
import { removeDuplicateStrings } from "utils/helpers";
import { ROLES } from "utils/constants";
import { ErrorException } from "../../../../types/settings";

// ----------------------------------------------------------------------

type MultilingualFormProps = {
  isEdit: boolean;
  isView?: boolean;
  multilingualLocale?: MultilingualLocale;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultilingualLocaleForm({
  isView,
  isEdit,
  multilingualLocale,
}: MultilingualFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const ReleaseNoteSchema = Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string().required().required("Name is required"),
    locale: Yup.string().required().required("Locale is required"),
    active: Yup.boolean().required().required("Active is required"),
    allowedRoles: Yup.array().of(Yup.string()).optional(),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      id: multilingualLocale?.id ?? "",
      active: multilingualLocale?.active ?? false,
      locale: multilingualLocale?.locale ?? "",
      name: multilingualLocale?.name ?? "",
      allowedRoles: multilingualLocale?.allowedRoles ?? [],
    },

    validationSchema: ReleaseNoteSchema,
    onSubmit: async (values) => {
      try {
        await addOrUpdateMultilingualLocale(values);

        await incrementContentVersion({
          fieldName: ContentVersionsField.MULTILINGUAL_LOCALES_VERSION,
        });

        enqueueSnackbar("Saved successfully!", { variant: "success" });
        navigate(-1);
      } catch (e: ErrorException) {
        console.error(e);
        enqueueSnackbar("Failed to save", { variant: "error" });
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  let submitBtnTitle = "Create New Locale";
  if (isView) {
    submitBtnTitle = "View Only";
  } else if (isEdit) {
    submitBtnTitle = "Save Changes";
  }

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <fieldset disabled={isView} style={{ border: "none" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label="ID"
                    disabled={isView}
                    {...getFieldProps("id")}
                    error={Boolean(touched.id && errors.id)}
                    helperText={touched.id && errors.id}
                  />
                  <TextField
                    fullWidth
                    label="Name"
                    disabled={isView}
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    label="Locale Code"
                    disabled={isView}
                    {...getFieldProps("locale")}
                    error={Boolean(touched.locale && errors.locale)}
                    helperText={touched.locale && errors.locale}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...getFieldProps("active")}
                        checked={getFieldProps("active").value}
                      />
                    }
                    label="is Active?"
                  />
                  <Typography variant="subtitle2">
                    Visibility Restrictions
                  </Typography>
                  <Typography variant="body2">
                    To restrict visibility to a specific group of users, choose
                    the roles of users who can view this locale. Leaving this
                    field empty will allow all users to access this specific
                    language version
                  </Typography>
                  <Autocomplete
                    multiple
                    // id="checkboxes-tags-demo"
                    {...getFieldProps("allowedRoles")}
                    value={formik.values.allowedRoles}
                    onChange={(event, value) => {
                      formik.setFieldValue("allowedRoles", value);
                    }}
                    options={removeDuplicateStrings([
                      ...(formik.values.allowedRoles || []),
                      ...Object.values(ROLES),
                    ])}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Allowed Roles"
                        placeholder="Choose Roles to allow viewing this locale"
                      />
                    )}
                  />

                  <Box
                    sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                  >
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {submitBtnTitle}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </fieldset>
      </Form>
    </FormikProvider>
  );
}
