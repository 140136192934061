import {AIPrompt, AIPromptId} from "../../types/aiPrompts";
import {db, firebaseAuth} from "../../contexts/FirebaseContext";


export const createOrUpdateAIPrompt = async (
    promptId: AIPromptId,
    prompt: AIPrompt,
): Promise<void> => {
    const lastUpdatedBy = firebaseAuth().currentUser?.uid

    await db.collection("ai_prompts")
        .doc(promptId)
        .set({
                ...prompt,
                lastUpdatedBy,
            },
            {merge: true}
        );
};


export async function getPromptById(
    id: AIPromptId,
): Promise<AIPrompt> {
    const doc = await db.collection("ai_prompts")
        .doc(id)
        .get();

    if (!doc.exists) {
        throw new Error("No prompt exists with this Id");
    }

    return {
        id,
        ...doc.data()
    } as AIPrompt
}
