import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import * as CloudFunctions from "utils/firebase/cloudFunctions";
import { MfaType } from "../utils/constants";
import { LoadingButton } from "@mui/lab";
import { MIconButton } from "./@material-extend";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import { useSnackbar } from "notistack";
import useAuth from "../hooks/useAuth";
import { ErrorException } from "../types/settings";

type SelectMFATypeProps = {
  open: boolean;
  onClose: () => void;
  hideMfaModel: () => void;
  mfaType?: MfaType;
  description?: string;
  onUserUnEnroll?: () => void;
};

export const UnEnrollMfaAuthenticationDialog = ({
  open,
  onClose,
  hideMfaModel,
  mfaType,
  description,
  onUserUnEnroll
}: SelectMFATypeProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { logout } = useAuth();

  const onUnEnroll = async () => {
    if (onUserUnEnroll) {
      onUserUnEnroll();
      return;
    }


    try {
      setLoading(true);
      await CloudFunctions.unenrollMultiFactorAuthentication({
        mfaType,
      });

      await logout();
      setLoading(false);
      hideMfaModel();
      enqueueSnackbar("Successfully UnEnroll mfa", {
        variant: "success",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
    } catch (e: ErrorException) {
      enqueueSnackbar("Failed to unEnroll mfa", {
        variant: "error",
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
      hideMfaModel();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth={"sm"} open={open} onClose={onClose}>
      <DialogTitle style={{ fontWeight: 700, fontSize: 24 }}>
        UnEnroll MFA
      </DialogTitle>
      <DialogContent>
        <Box alignItems={"flex-end"} justifyContent={"flex-end"}>
          <Typography>{description || "Are you sure you want to turn off MFA authentication? You'll need to log in again."}</Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          size="small"
          type="submit"
          loading={loading}
          variant="contained"
          color={"primary"}
          onClick={onUnEnroll}
        >
          Ok
        </LoadingButton>

        <Button color={"inherit"} variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
