import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { hasValidPermissions } from "utils/helpers";
import { useCurrentRole } from "hooks/useCurrentRole";
import { ROLES } from "utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import {LoadingButton} from "@mui/lab";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { ErrorException } from "../../../types/settings";


type Props = {
  deleteItem: () => void;
};

export default function DeleteButton(props: Props) {
  const { deleteItem } = props;
  const userRoles = useCurrentRole();
  const [deleting, setDeleting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const handleDelete = async (): Promise<void> => {
    setDeleting(true);
    try {
      enqueueSnackbar("Deleting item", {variant: "default"});
      await deleteItem();
      enqueueSnackbar("Deleted successfully", { variant: "success" });
    } catch (error: ErrorException) {
      console.error("An error occurred:", error);
      enqueueSnackbar("Failed to delete", { variant: "error" });
    }
    setDeleting(false);
    setShowConfirmationDialog(false);
  };

  if (
    !hasValidPermissions(userRoles, [
      ROLES.SUPER_ADMIN,
      ROLES.BOT_DATA_WRITE
    ])
  ) {
    return null;
  }

  return (
    <>
      <LoadingButton
        loading={deleting}
        endIcon={<DeleteIcon />}
        onClick={()=> setShowConfirmationDialog(true)}
        sx={{ mr: 1 }}
      >
        Delete
      </LoadingButton>
      <DeleteConfirmationDialog
        open={showConfirmationDialog}
        loading={deleting}
        onClose={()=> setShowConfirmationDialog(false)}
        handleDelete={handleDelete}
      />
    </>
  );
}
