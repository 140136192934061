// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
// import Label from '../../components/Label';
import SvgIconStyle from "../../components/SvgIconStyle";
import { CCRolesPossibleVals } from "../../types/role/CCRoles";
import {
  ACC_SETTINGS_ROLES,
  SUPER_ADMIN_ROLES,
  APP_STABLE_VERSION_ROLES,
  BOT_DATA_ROLES,
  ANALYTICS_ROLES,
  INV_CODE_ROLES,
  SURVEY_ROLES,
  THERAPIST_ROLES,
  CONFERENCE_CALL_ROLES,
  RESET_MFA_ROLES,
  USERS_CREDITS_ROLES,
  CORPORATE_DASHBOARD_ROLES,
  INVOICES_ROLES,
  SEARCH_USER_ROLE,
  AUDIO_ROLES,
  RELEASE_NOTES_ROLES,
  MEDITATION_VOICES_ROLES,
  USER_GENERATED_AUDIO_ROLES,
  APP_TRANSLATIONS_ROLES,
  PRIVACY_POLICY_ROLES,
  AI_PROMPT_ROLES,
  JSON_KEYS_TRANSLATIONS_ROLES,
  EMAIL_ADMIN_ROLES,
  USER_MIGRATION_ROLES,
} from "../../utils/constants";
import { hasValidPermissions } from "../../utils/helpers";
import { concat } from "lodash";

// ----------------------------------------------------------------------

export const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

type NavItemBase = {
  title: string;
  path: string;
  icon: typeof ICONS.chat;
};

type NavItem = NavItemBase & {
  children?: Array<NavItemBase>;
};

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  settings: getIcon("ic_settings"),
  confernceCalls: getIcon("ic_chat"),
  translate: getIcon("ic_translate"),
  speaker: getIcon("ic_speaker"),
  shield: getIcon("ic_shield"),
};

const sidebarConfig = (usrRoles: Array<CCRolesPossibleVals> = []) => {
  function createSection(): [NavItem[], typeof addToSection] {
    const section: NavItem[] = [];
    function addToSection(
      requiredRoles: true | CCRolesPossibleVals[],
      item: NavItem
    ) {
      if (requiredRoles === true) {
        section.push(item);
        return;
      }

      if (hasValidPermissions(usrRoles, requiredRoles)) {
        section.push(item);
      }
    }

    return [section, addToSection];
  }

  function navItem(title: string, path: string, icon: JSX.Element): NavItem {
    return {
      title,
      path,
      icon,
    };
  }

  function bindHasPermission(userRoles: CCRolesPossibleVals[]) {
    return (requiredRoles: CCRolesPossibleVals[]) => {
      return hasValidPermissions(userRoles, requiredRoles);
    };
  }

  /**
   * Take the main item for the sub section and a list of functions
   * that produce nav items. If no nav items are produced for the sub section
   * then it is omitted (not added to the parent).
   */
  function subSection(title: NavItem, ...children: NavItem[][]) {
    return (parent: NavItem[]) => {
      const visibleItems = children.flat();
      if (visibleItems.length === 0) {
        return;
      }
      title.children = visibleItems;
      parent.push(title);
    };
  }

  const hasPermission = bindHasPermission(usrRoles);

  /**
   * Return a function that produces NavItems. If the given permissions
   * match return the passed NavItems. Otherwise an empty array.
   */
  function connectPermission(permission: CCRolesPossibleVals[]) {
    return (...items: NavItem[]): NavItem[] => {
      if (hasPermission(permission)) {
        return items;
      }

      return [];
    };
  }

  const hasAccessToAnalytics = connectPermission(ANALYTICS_ROLES);
  const hasAccessToAiPrompt = connectPermission(AI_PROMPT_ROLES);
  const hasAccessToBotData = connectPermission(BOT_DATA_ROLES);
  const hasAnyMFARole = connectPermission([...RESET_MFA_ROLES]);
  const hasAccessToTherapists = connectPermission(THERAPIST_ROLES);
  const hasAccessToMigrations = connectPermission(USER_MIGRATION_ROLES);
  const hasAccessToInvoices = connectPermission([
    ...SUPER_ADMIN_ROLES,
    ...INVOICES_ROLES,
  ]);
  const isSuperAdmin = connectPermission(SUPER_ADMIN_ROLES);
  const hasAccessToMultilingualLocales = connectPermission([
    ...SUPER_ADMIN_ROLES,
    "app_translation_crud",
  ]);

  const [dashboardNavItems, addToMainSection] = createSection();

  // show invitation code menu if usrRoles have invitation code roles
  addToMainSection(INV_CODE_ROLES, {
    title: "invitation codes",
    path: PATH_DASHBOARD.invitationCodes.root,
    icon: ICONS.kanban,
  });
  addToMainSection(INV_CODE_ROLES, {
    title: "registration codes",
    path: PATH_DASHBOARD.registrationCodes.root,
    icon: ICONS.kanban,
  });

  addToMainSection(INV_CODE_ROLES, {
    title: "Payment links",
    path: PATH_DASHBOARD.paymentLinks.root,
    icon: ICONS.ecommerce,
  });

  subSection(navItem("therapists", "therapists", ICONS.user), [
    ...hasAccessToTherapists(
      navItem("List", PATH_DASHBOARD.therapist.list, ICONS.user),
      navItem("Filter Tags", PATH_DASHBOARD.therapist.filters, ICONS.user),
      navItem("Chinese Users relationships", PATH_DASHBOARD.therapist.chineseUserRelationships.list, ICONS.user),
      navItem("MFA Enrollments", PATH_DASHBOARD.therapist.therapistMfa, ICONS.user)
    ),
    ...hasAccessToInvoices(
      navItem("Invoices", PATH_DASHBOARD.therapist.invoices, ICONS.user)
    ),
  ])(dashboardNavItems);

  subSection(
    navItem("users", "users", ICONS.user),
    isSuperAdmin(
      navItem(
        "Delete Requests",
        PATH_DASHBOARD.users.deleteUsersRequestList,
        ICONS.user
      )
    ),
    connectPermission(USERS_CREDITS_ROLES)(
      navItem(
        "Users Extra Credits",
        PATH_DASHBOARD.users.usersSearch,
        ICONS.user
      )
    ),
    hasAccessToMigrations(
      navItem("Migrate Users", PATH_DASHBOARD.users.migrate, ICONS.banking)
    ),
    connectPermission(SEARCH_USER_ROLE)(
      navItem("Find User", PATH_DASHBOARD.users.find, ICONS.banking)
    )
  )(dashboardNavItems);

  addToMainSection(SUPER_ADMIN_ROLES, {
    title: "blended care",
    path: PATH_DASHBOARD.blendedCare.root,
    icon: ICONS.confernceCalls,
  });

  subSection(
    navItem("Analytics", PATH_DASHBOARD.analytics.root, ICONS.analytics),
    hasAccessToAnalytics(
      navItem(
        "chats analytics",
        PATH_DASHBOARD.chatAnalytics.root,
        ICONS.analytics
      ),
      navItem(
        "call analytics",
        PATH_DASHBOARD.callAnalytics.root,
        ICONS.analytics
      ),
      navItem("call ratings", PATH_DASHBOARD.callRatings.root, ICONS.analytics),
      navItem(
        "therapist sessions",
        PATH_DASHBOARD.therapistSessions.root,
        ICONS.analytics
      )
    )
  )(dashboardNavItems);

  addToMainSection(CONFERENCE_CALL_ROLES, {
    title: "conference call rooms",
    path: PATH_DASHBOARD.conferenceCall.list,
    icon: ICONS.confernceCalls,
  });

  addToMainSection(SURVEY_ROLES, {
    title: "surveys",
    path: PATH_DASHBOARD.surveys.list,
    icon: ICONS.analytics,
  });

  addToMainSection(APP_STABLE_VERSION_ROLES, {
    title: "app stable versions",
    path: PATH_DASHBOARD.appStableVersion.root,
    icon: ICONS.kanban,
  });

  subSection(
    navItem("Content", "content", ICONS.blog),
    hasAccessToBotData(
      navItem("bot data", PATH_DASHBOARD.botData.root, ICONS.blog),
      navItem("bot data table", PATH_DASHBOARD.botdTable.root, ICONS.blog),
      navItem("bots overview", PATH_DASHBOARD.botDataOverview.root, ICONS.blog),
      navItem("bots rating", PATH_DASHBOARD.botsRating.root, ICONS.blog),
      navItem("content unit", PATH_DASHBOARD.contentUnit.root, ICONS.blog),
      navItem("content themes", PATH_DASHBOARD.contentThemes.root, ICONS.blog),
      navItem("content images", PATH_DASHBOARD.contentImages.root, ICONS.blog),
      navItem("svg images", PATH_DASHBOARD.svgImages.root, ICONS.blog),
      navItem("content Colour Palettes", PATH_DASHBOARD.contentColourPalettes.root, ICONS.blog),
      navItem(
        "content modules",
        PATH_DASHBOARD.contentModules.root,
        ICONS.blog
      ),
      navItem(
        "parent content modules",
        PATH_DASHBOARD.parentContentModules.root,
        ICONS.blog
      )
    ),
    connectPermission(APP_TRANSLATIONS_ROLES)(
      navItem(
        "App Translations",
        PATH_DASHBOARD.appSideTranslations.root,
        ICONS.translate
      )
    ),
    connectPermission(APP_TRANSLATIONS_ROLES)(
      navItem(
        "Missing Translations",
        PATH_DASHBOARD.missingTranslation.root,
        ICONS.translate
      )
    ),
    hasAccessToMultilingualLocales(
      navItem(
        "Multilingual Locales",
        PATH_DASHBOARD.multilingualLocales.root,
        ICONS.translate
      ),
      navItem(
        "Suggested Translations",
        PATH_DASHBOARD.suggestedTranslations.root,
        ICONS.translate
      )
    ),

    isSuperAdmin(
      navItem("settings", PATH_DASHBOARD.settings.root, ICONS.settings)
    )
  )(dashboardNavItems);

  addToMainSection(concat(SUPER_ADMIN_ROLES, CORPORATE_DASHBOARD_ROLES), {
    title: "organisations",
    path: PATH_DASHBOARD.organisations.list,
    icon: ICONS.banking,
  });
  addToMainSection(concat(SUPER_ADMIN_ROLES, CORPORATE_DASHBOARD_ROLES), {
    title: "Invitation Code Groups",
    path: PATH_DASHBOARD.invitationCodeGroup.list,
    icon: ICONS.kanban,
  });

  addToMainSection(RELEASE_NOTES_ROLES, {
    title: "Release Notes",
    path: PATH_DASHBOARD.releaseNotes.list,
    icon: ICONS.calendar,
  });

  addToMainSection(PRIVACY_POLICY_ROLES, {
    title: "Privacy Policies",
    path: PATH_DASHBOARD.privacyPolicy.list,
    icon: ICONS.shield,
  });

  subSection(
    navItem("AI Prompts", "aiPrompts", ICONS.calendar),
    hasAccessToAiPrompt(
      navItem("AI Prompts", PATH_DASHBOARD.aiPrompts.list, ICONS.calendar),
      navItem("Templates", PATH_DASHBOARD.templates.list, ICONS.calendar),
      navItem("Snippets", PATH_DASHBOARD.snippets.list, ICONS.calendar),
      navItem("Filter Content", PATH_DASHBOARD.filterContent.root, ICONS.blog)
    )
  )(dashboardNavItems);

  addToMainSection(AUDIO_ROLES, {
    title: "Audio Meditation Generation",
    path: PATH_DASHBOARD.audioMeditationGenerations.list,
    icon: ICONS.translate,
  });

  addToMainSection(USER_GENERATED_AUDIO_ROLES, {
    title: "User Generated Audio Meditations",
    path: PATH_DASHBOARD.userGeneratedAudioMeditations.list,
    icon: ICONS.translate,
  });

  addToMainSection(MEDITATION_VOICES_ROLES, {
    title: "Audio Meditation Voices",
    path: PATH_DASHBOARD.audioMeditationVoices.list,
    icon: ICONS.translate,
  });

  subSection(
    navItem("IAM", "iam", ICONS.user),
    hasAnyMFARole(
      navItem("Roles", PATH_DASHBOARD.iam.roles.list, ICONS.user),
      navItem("User Role Manage", PATH_DASHBOARD.iam.userRoleManage, ICONS.user),
      navItem("Reset MFA", PATH_DASHBOARD.iam.mfa, ICONS.user),
      navItem(
        "Corporate Dashboard Permissions",
        PATH_DASHBOARD.iam.corporateDashboardPermissions,
        ICONS.user
      )
    )
  )(dashboardNavItems);

  addToMainSection(JSON_KEYS_TRANSLATIONS_ROLES, {
    title: "JSON Keys Translations",
    path: PATH_DASHBOARD.jsonKeysTranslations.root,
    icon: ICONS.translate,
  });

  addToMainSection(SUPER_ADMIN_ROLES, {
    title: "audit logs",
    path: PATH_DASHBOARD.auditLogs.root,
    icon: ICONS.kanban,
  });

  addToMainSection(SUPER_ADMIN_ROLES, {
    title: "Notifications Tool",
    path: PATH_DASHBOARD.notifications.history,
    icon: ICONS.mail,
  });

  addToMainSection(SUPER_ADMIN_ROLES, {
    title: "Changelog",
    path: PATH_DASHBOARD.changelog.root,
    icon: ICONS.banking,
  });

  addToMainSection(EMAIL_ADMIN_ROLES, {
    title: "Email Templates",
    path: PATH_DASHBOARD.emailTemplates,
    icon: ICONS.mail,
  });

  /*----------------------------------*/

  /**
   * account nav items arr construction
   * based upon the permission
   */
  const accountSettingsNavItems: Array<NavItem> = [];
  if (hasValidPermissions(usrRoles, ACC_SETTINGS_ROLES)) {
    accountSettingsNavItems.push({
      title: "account settings",
      path: PATH_DASHBOARD.user.account,
      icon: ICONS.user,
    });
  }
  /*----------------------------------*/

  return [
    // DASHBOARD
    // ----------------------------------------------------------------------
    {
      subheader: "dashboard",
      items: dashboardNavItems,
    },

    // ACCOUNT
    // ----------------------------------------------------------------------
    {
      subheader: "account",
      items: accountSettingsNavItems,
    },
  ];
};

export default sidebarConfig;
