import { ThemeFromFirestore } from "../../models/Theme";
import firebase from "firebase/compat/app";
import {
  ContentFromFirestore,
  ModuleFromFirestore,
} from "../../models/ContentUnit";
import { SupportedLanguages } from "../../types/invitation/code";
import { generateAndSaveMultilingualContentFunction, incrementContentVersion } from "./cloudFunctions";
import { ContentVersionsField, MultilingualLocalesTypes } from "types/translations";
import { ImageFromFirestore } from "models/Image";
import { db } from "contexts/FirebaseContext";
import { MULTI_LINGUAL_OPTION } from "../../components/LanguageSelectionDropDown";
import { ColourPaletteFromFirestore } from "models/ColourPalette";

export enum ContentCollection {
  CONTENT = "content",
  MODULES = "modules",
  PARENT_MODULES = "parent_modules",
}

export const updateContentUnit = async (
  doc: Partial<ContentFromFirestore>,
  selectedLanguage: SupportedLanguages
): Promise<void> => {
  const docName = doc?.id ?? "";
  await db
    .collection("content")
    .doc(selectedLanguage)
    .set(
      {
        [docName]: {
          ...doc,
        },
      },
      { merge: true }
    );

  await incrementContentVersion({
    fieldName: ContentVersionsField.BOT_DATA_VERSION,
  });
};

export const updateModuleContent = async (
  doc: Partial<ContentFromFirestore>,
  selectedLanguage: SupportedLanguages | string,
  selectedMultilingualLanguages?: MultilingualLocalesTypes[],
  isOnlySaveNonTranslatedFields?: boolean
): Promise<void> => {
  const docName = doc?.id ?? "";
  if (selectedLanguage === MULTI_LINGUAL_OPTION) {
    return generateAndSaveMultilingualContentFunction({
      ...doc, collectionName: ContentCollection.MODULES,
      targetMultilingualLocales: selectedMultilingualLanguages,
      isOnlySaveNonTranslatedFields
    });
  }

  await db
    .collection("modules")
    .doc(selectedLanguage)
    .set(
      {
        [docName]: {
          ...doc,
        },
      },
      { merge: true }
    );
  await incrementContentVersion({
    fieldName: ContentVersionsField.BOT_DATA_VERSION,
  });
};

export const updateParentModuleContent = async (
  doc: Partial<ModuleFromFirestore>,
  selectedLanguage: SupportedLanguages | string,
  selectedMultilingualLanguages?: MultilingualLocalesTypes[],
  isOnlySaveNonTranslatedFields?: boolean
): Promise<void> => {
  const docName = doc?.id ?? "";
  if (selectedLanguage === MULTI_LINGUAL_OPTION) {
    return generateAndSaveMultilingualContentFunction({
      ...doc,
      collectionName: ContentCollection.PARENT_MODULES,
      targetMultilingualLocales: selectedMultilingualLanguages,
      isOnlySaveNonTranslatedFields
    });
  }

  await db
    .collection("parent_modules")
    .doc(selectedLanguage)
    .set(
      {
        [docName]: {
          ...doc,
        },
      },
      { merge: true }
    );
  await incrementContentVersion({
    fieldName: ContentVersionsField.BOT_DATA_VERSION,
  });
};
export const updateThemeContent = async (
  doc: ThemeFromFirestore
): Promise<void> => {
  const { id, ...rest } = doc;
  await db.collection("themes").doc(doc?.id).set(rest, { merge: true });
  await incrementContentVersion({
    fieldName: ContentVersionsField.BOT_DATA_VERSION,
  });
};

export const updateImageContent = async (
  doc: ImageFromFirestore
): Promise<void> => {
  const { id, ...rest } = doc;
  await db.collection("images").doc(doc?.id).set(rest, { merge: true });
  await incrementContentVersion({
    fieldName: ContentVersionsField.BOT_DATA_VERSION,
  });
};

export const updateColourPalette = async (
  doc: ColourPaletteFromFirestore
): Promise<void> => {
  const { id, ...rest } = doc;
  await db.collection("colour_palettes").doc(doc?.id).set(rest, { merge: true });
  await incrementContentVersion({
    fieldName: ContentVersionsField.BOT_DATA_VERSION,
  });
};

export const deleteContentItem = async (
  collection: ContentCollection,
  contentId: string,
  selectedLanguage: SupportedLanguages | string,
  multilingualLocales: Array<MultilingualLocalesTypes>
): Promise<void> => {
  const promises = [];
  if (selectedLanguage === MULTI_LINGUAL_OPTION) {
    promises.push(...multilingualLocales.map(multilingualLocale => {
      const multilingualContentId = `${ contentId }-${ multilingualLocale.locale.toLowerCase() }`
      return db.collection(collection).doc(multilingualLocale.locale.toLowerCase()).update({ [multilingualContentId]: firebase.firestore.FieldValue.delete() })
    }))
  }

  promises.push(db
    .collection(collection)
    .doc(selectedLanguage)
    .update({ [contentId]: firebase.firestore.FieldValue.delete() }));

  await Promise.all(promises);
  await incrementContentVersion({
    fieldName: ContentVersionsField.BOT_DATA_VERSION,
  });
};

export const deleteTheme = async (themeId: string): Promise<void> => {
  await firebase.firestore().collection("themes").doc(themeId).delete();

  await incrementContentVersion({
    fieldName: ContentVersionsField.BOT_DATA_VERSION,
  });
};

export const deleteImage = async (imageId: string): Promise<void> => {
  await firebase.firestore().collection("images").doc(imageId).delete();

  await incrementContentVersion({
    fieldName: ContentVersionsField.BOT_DATA_VERSION,
  });
};

export const deleteColourPalette = async (colourPaletteId: string): Promise<void> => {
  await firebase.firestore().collection("colour_palettes").doc(colourPaletteId).delete();

  await incrementContentVersion({
    fieldName: ContentVersionsField.BOT_DATA_VERSION,
  });
};
