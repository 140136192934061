import React from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// material
import { LoadingButton } from "@mui/lab";
import { Box, Card, Grid, Stack, TextField } from "@mui/material";

import { SupportedLanguages } from "../../../types/invitation/code";

import { ReleaseNotes } from "../../../types/releaseNotes";
import firebase from "firebase/compat/app";
import { createOrUpdateReleaseNote } from "../../../utils/firebase/releaseNotesUtils";
import { MultiLingualContentGenerationType, MultilingualLocalesTypes } from "../../../types/translations";
import { generateAndSaveMultilingualContentFunction } from "../../../utils/firebase/cloudFunctions";
import { collections } from "../../../utils/constants";
import { Locale } from "../../../types/user";
import { format } from "date-fns";
import { ErrorException } from "../../../types/settings";

// ----------------------------------------------------------------------

type ReleaseNoteNewFormProps = {
  isEdit: boolean;
  isView?: boolean;
  isNew?: boolean;
  selectedLanguage: SupportedLanguages | string;
  releaseNote: ReleaseNotes;
  targetMultilingualLocales: MultilingualLocalesTypes[];
  setReleaseNoteId: React.Dispatch<React.SetStateAction<string>>;
  setEnableFormView: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ReleaseNoteNewForm({
  isNew,
  isView,
  isEdit,
  selectedLanguage,
  releaseNote,
  targetMultilingualLocales,
  setEnableFormView,
  setReleaseNoteId
}: ReleaseNoteNewFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const ReleaseNoteSchema = Yup.object().shape({
    id: Yup.string().required(),
    locale: Yup.string().required(),
    title: Yup.string().required(),
    markdownText: Yup.string().optional(),
    description: Yup.string().optional(),
    thumbnail: Yup.string().optional(),
    releasedAt: Yup.date().required("Release date and time is required."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: releaseNote?.id ?? "",
      locale: selectedLanguage,
      thumbnail: releaseNote?.thumbnail ?? "",
      title: releaseNote?.title ?? "",
      description: releaseNote?.description ?? "",
      markdownText: releaseNote?.markdownText ?? "",
      releasedAt: releaseNote?.releasedAt ? format(
        new Date(releaseNote?.releasedAt),
        "yyyy-MM-dd'T'HH:mm"
      ): "",
    },
    validationSchema: ReleaseNoteSchema,
    onSubmit: async (values) => {
      let successMessage = "Successfully saved changes";
      let errorMessage = "Failed to save changes";
      if (isNew) {
        successMessage = "Release Note Created Successfully";
        errorMessage = "Failed to create release note";
      }
      try {
        const finalValues = {
          ...(values || {}),
          releasedAt: firebase.firestore.Timestamp.fromDate(
            new Date(values?.releasedAt ?? "")
          ),
        };

        if (selectedLanguage === Locale.EN) {
          setEnableFormView(false);
          setReleaseNoteId(finalValues.id);
          await generateAndSaveMultilingualContentFunction({
            ...finalValues, collectionName: collections.RELEASE_NOTES,
            targetMultilingualLocales,
            releasedAt: new Date(values?.releasedAt ?? "").toISOString(),
            docId: Locale.EN,
            isOnlyTranslateContent: true,
          } as MultiLingualContentGenerationType);
        } else {
          await createOrUpdateReleaseNote(
            { ...finalValues },
            selectedLanguage
          );
        }
        navigate(-1);
        enqueueSnackbar(successMessage, { variant: "success" });
      } catch (e: ErrorException) {
        console.error("FailedToCreateReleaseNote", e);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  let submitBtnTitle = "Create Release Notes";
  if (isView) {
    submitBtnTitle = "View Only";
  } else if (isEdit) {
    submitBtnTitle = "Save Changes";
  }

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <fieldset disabled={isView} style={{ border: "none" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label="Id"
                    {...getFieldProps("id")}
                    error={Boolean(touched.id && errors.id)}
                    helperText={touched.id && errors.id}
                  />

                  <TextField
                    fullWidth
                    label="Title"
                    {...getFieldProps("title")}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />
                  <TextField
                    fullWidth
                    label="Description"
                    {...getFieldProps("description")}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />

                  <TextField
                    fullWidth
                    multiline={true}
                    label="Markdown text"
                    {...getFieldProps("markdownText")}
                    error={Boolean(touched.markdownText && errors.markdownText)}
                    helperText={touched.markdownText && errors.markdownText}
                  />

                  <TextField
                    fullWidth
                    label="Thumbnail"
                    {...getFieldProps("thumbnail")}
                    error={Boolean(touched.thumbnail && errors.thumbnail)}
                    helperText={touched.thumbnail && errors.thumbnail}
                  />

                  <TextField
                    fullWidth
                    label="Released at"
                    {...getFieldProps("releasedAt")}
                    error={Boolean(touched.releasedAt && errors.releasedAt)}
                    helperText={touched.releasedAt && errors.releasedAt}
                    type="datetime-local"
                  />

                  <Box
                    sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                  >
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {submitBtnTitle}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </fieldset>
      </Form>
    </FormikProvider>
  );
}
