import { lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";

// layouts
import DashboardLayout from "../layouts/dashboard";

// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import RoleBasedGuard from "../guards/RoleBasedGuard";

// components
import LoadingScreen from "../components/LoadingScreen";
import Page404 from "../pages/Page404";
import {
  AI_PROMPT_ROLES,
  ANALYTICS_ROLES,
  APP_STABLE_VERSION_ROLES,
  APP_TRANSLATIONS_ROLES,
  AUDIO_ROLES,
  BOT_DATA_ROLES,
  CONFERENCE_CALL_ROLES,
  INV_CODE_ROLES,
  INVOICES_ROLES,
  JSON_KEYS_TRANSLATIONS_ROLES,
  MEDITATION_VOICES_ROLES,
  MULTILINGUAL_ROLES,
  PRIVACY_POLICY_ROLES,
  RELEASE_NOTES_ROLES,
  RESET_MFA_ROLES,
  ROLES,
  SEARCH_USER_ROLE,
  SETTINGS_ROLES,
  SUPER_ADMIN_ROLES,
  SURVEY_ROLES,
  THERAPIST_ROLES,
  TRANSLATIONS_ROLES,
  USER_GENERATED_AUDIO_ROLES,
  USER_MIGRATION_ROLES,
  USERS_CREDITS_ROLES,
} from "../utils/constants";
import CodeCreate from "../pages/invitationCode/CodeCreate";
import AddEditTableList from "../pages/dashboard/bot-dtable/AddEditTableList";
import AuditLogsGuard from "../guards/AuditLogsGuard";
import { PATH_DASHBOARD } from "./paths";
import EditCallDetails from "../pages/dashboard/edit-video-call/EditCallDetails";
import CreditPeriodsPage from "../pages/invitationCode/CreditPeriodsPage";
import { CreditPeriodsProvider } from "../pages/invitationCode/hooks";
import UserCreditsProvider from "pages/users/hooks/UserCreditsProvider";
import CreateRegistrationCode from "../pages/registrationCode/CreateRegistrationCode";
import { RenewalChallengePage } from "pages/invitationCode/RenewalChallengePage";
import RenewalChallengeUpdatePage from "pages/invitationCode/RenewalChallengeUpdatePage";
import ViewInvoice from "pages/dashboard/therapist/ViewInvoice";
import { TherapistSessionEdit } from "pages/dashboard/therapist-session/TherapistSessionEdit";
import UserMigrationPage from "pages/users/migration/UserMigrationPage";
import AddEditContent from "pages/dashboard/content/AddEditContent";
import AddEditContentTheme from "../pages/dashboard/content/themes/AddEditContentTheme";
import AddEditModulesContent from "../pages/dashboard/content/modules/AddEditModulesContent";
import { ContentUnitsProvider } from "../contexts/ContentUnitsContext";
import AddEditParentModulesContent from "pages/dashboard/content/modules/AddEditParentModulesContent";
import ChangelogExplorerPage from "pages/changelog/ChangelogExplorerPage";
import CreateReleaseNote from "../pages/dashboard/release-notes/CreateReleaseNote";
import ReleaseNotesList from "../pages/dashboard/release-notes/ReleaseNotesList";
//audio meditation generation pages
import AudioMeditationsList from "../pages/dashboard/audio-meditation-generations/AudioMeditationsList";
import CreateAudioMeditation from "../pages/dashboard/audio-meditation-generations/CreateAudioMeditation";
import FindUserInfo from "../pages/users/FindUserInfo";
import UpdateAudioMeditation from "../pages/dashboard/audio-meditation-generations/UpdateAudioMeditation";
import Notifications from "../pages/dashboard/notifications";
import MeditationVoicesList from "../pages/dashboard/audio-meditation-voices/MeditationVoicesList";
import CreateMeditationVoice from "../pages/dashboard/audio-meditation-voices/CreateMeditationVoice";
import UserGeneratedAudioMeditationsList
  from "../pages/dashboard/user-generated-audio-meditations/UserGeneratedAudioMeditationsList";
import ViewUserGeneratedAudioMeditation
  from "../pages/dashboard/user-generated-audio-meditations/ViewUserGeneratedAudioMeditation";
import CreateAppSideTranslation from "../pages/dashboard/app-side-translations/CreateAppSideTranslation";
import AddEditContentImage from "pages/dashboard/content/images/AddEditContentImage";
import AIPromptsList from "../pages/dashboard/ai-prompts/AIPromptsList";
import CreateAIPrompt from "../pages/dashboard/ai-prompts/CreateAIPrompt";
import MultilingualLocales from "pages/dashboard/content/multilingual-locales/MultilingualLocales";
import CreateMultilingualLocale from "pages/dashboard/content/multilingual-locales/CreateMultilingualLocale";
import CreatePrivacyPolicy from "../pages/dashboard/privacy-policy/CreatePrivacyPolicy";
import PrivacyPolicyList from "../pages/dashboard/privacy-policy/PrivacyPolicyList";
import CreateSuggestedTranslation from "pages/dashboard/content/suggested-translations/CreateSuggestedTranslation";
import { EnrollMFAPage } from "../pages/authentication/EnrollMFAPage";
import { EnrollTOTPPage } from "../pages/authentication/EnrollTOTPPage";
import { GroupProgress } from "../pages/dashboard/counsellor-dashboard/GroupProgress";
import QRCodePage from "pages/invitationCode/QRCodePage";
import SnippetsList from "../pages/dashboard/snippets/SnippetsList";
import CreateSnippet from "../pages/dashboard/snippets/CreateSnippet";
import CreateTemplate from "../pages/dashboard/templates/CreateTemplate";
import NotificationsHistory from "../pages/dashboard/notifications/NotificationsHistory";
import CallRatingList from "pages/dashboard/call-ratings-analytics/CallRatingList";
import ChineseUsersRelationships from "pages/dashboard/therapist/chinese-user-relationships/ChineseUsersRelationships";
import CreateChineseUserRelationship
  from "pages/dashboard/therapist/chinese-user-relationships/CreateChineseUserRelationship";
import CreateRelationshipSession from "pages/dashboard/therapist/chinese-user-relationships/CreateRelationshipSession";
import PaymentLinksList from "../pages/paymentLinks/PaymentLinksList";
import CreateNewPaymentLink from "../pages/paymentLinks/CreateNewPaymentLink";
import GetPaymentLinkForUser from "../pages/paymentLinks/GetPaymentLinkForUser";
import AddEditContentColourPalette from "pages/dashboard/content/colour-palettes/AddEditContentColourPalette";
import AddEditFilterContent from "../pages/dashboard/filter_content/AddEditFilterContent";
import TemplatesList from "../pages/dashboard/templates/TemplatesList";
import { EmailTemplates } from "pages/EmailTemplates";
import RolesList from "../pages/dashboard/iam/roles/RolesList";
import CreateRoles from "../pages/dashboard/iam/roles/CreateRoles";
import UserRoleManage from "../pages/dashboard/iam/assign_role/UserRoleManage";
import UserListing from "../pages/dashboard/iam/roles/UserListing";
import AllUsersWithCustomClaims from "../pages/dashboard/iam/AllUsersWithCustomClaims";
import AddSvgImage from "pages/dashboard/content/svg-images/AddSvgImage";
// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  const isDashboard = true;

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={ {
            // @ts-ignore
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          } }
        />
      }
    >
      <Component { ...props } />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login/>
            </GuestGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword/> },
      ],
    },

    // Dashboard Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout/>
        </AuthGuard>
      ),
      children: [
        {
          path: "changelog-explorer",
          element: <ChangelogExplorerPage/>,
        },
        {
          path: "invitation-code",
          children: [
            {
              path: "/invitation-code",
              element: (
                <RoleBasedGuard accessibleRoles={ INV_CODE_ROLES }>
                  <CodeList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [ROLES.SUPER_ADMIN, ROLES.INV_CODE_WRITE] }
                >
                  <CodeCreate/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":code/edit",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ROLES.SUPER_ADMIN,
                    ROLES.INV_CODE_WRITE,
                    ROLES.INV_CODE_MANAGE,
                  ] }
                >
                  <CodeCreate/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":code/view",
              element: (
                <RoleBasedGuard accessibleRoles={ INV_CODE_ROLES }>
                  <CodeCreate/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":code/credits",
              element: (
                <RoleBasedGuard accessibleRoles={ INV_CODE_ROLES }>
                  <CreditPeriodsProvider>
                    <CreditPeriodsPage/>
                  </CreditPeriodsProvider>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":code/renewal-challenge",
              children: [
                {
                  path: "",
                  element: (
                    <RoleBasedGuard
                      accessibleRoles={ [
                        ROLES.SUPER_ADMIN,
                        ROLES.INV_CODE_WRITE,
                        ROLES.INV_CODE_MANAGE,
                      ] }
                    >
                      <RenewalChallengePage/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":challengeId",
                  element: (
                    <RoleBasedGuard
                      accessibleRoles={ [
                        ROLES.SUPER_ADMIN,
                        ROLES.INV_CODE_WRITE,
                        ROLES.INV_CODE_MANAGE,
                      ] }
                    >
                      <RenewalChallengeUpdatePage/>
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: ":code/qr-code",
              element: (
                <RoleBasedGuard accessibleRoles={ INV_CODE_ROLES }>
                  <QRCodePage/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":code/duplicate",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [ROLES.SUPER_ADMIN, ROLES.INV_CODE_WRITE] }
                >
                  <CodeCreate/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "registration-code",
          children: [
            {
              path: "/registration-code",
              element: (
                <RoleBasedGuard accessibleRoles={ INV_CODE_ROLES }>
                  <RegistrationCodeList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [ROLES.SUPER_ADMIN, ROLES.INV_CODE_WRITE] }
                >
                  <CreateRegistrationCode/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":code/edit",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ROLES.SUPER_ADMIN,
                    ROLES.INV_CODE_WRITE,
                    ROLES.INV_CODE_MANAGE,
                  ] }
                >
                  <CreateRegistrationCode/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":code/view",
              element: (
                <RoleBasedGuard accessibleRoles={ INV_CODE_ROLES }>
                  <CreateRegistrationCode/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "payment-links",
          children: [
            {
              path: "/payment-links",
              element: (
                <RoleBasedGuard accessibleRoles={ INV_CODE_ROLES }>
                  <PaymentLinksList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [ROLES.SUPER_ADMIN, ROLES.INV_CODE_WRITE] }
                >
                  <CreateNewPaymentLink/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/get-link",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [ROLES.SUPER_ADMIN, ROLES.INV_CODE_WRITE] }
                >
                  <GetPaymentLinkForUser/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ROLES.SUPER_ADMIN,
                    ROLES.INV_CODE_WRITE,
                    ROLES.INV_CODE_MANAGE,
                  ] }
                >
                  <CreateNewPaymentLink/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ROLES.SUPER_ADMIN,
                    ROLES.INV_CODE_WRITE,
                    ROLES.INV_CODE_MANAGE,
                  ] }
                >
                  <CreateNewPaymentLink/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "therapist",
          children: [
            { element: <Navigate to="/dashboard/therapist/list" replace/> },
            {
              path: "scheduled-calls",
              element: (
                <RoleBasedGuard accessibleRoles={ THERAPIST_ROLES }>
                  <ScheduledCalls/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "scheduled-calls/:id",
              element: (
                <RoleBasedGuard accessibleRoles={ THERAPIST_ROLES }>
                  <ScheduledCalls/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/therapist/chinese-users",
              element: (
                <RoleBasedGuard accessibleRoles={ THERAPIST_ROLES }>
                  <ChineseUsersRelationships/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/therapist/chinese-users/new",
              element: (
                <RoleBasedGuard accessibleRoles={ THERAPIST_ROLES }>
                  <CreateChineseUserRelationship/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/therapist/chinese-users/:id/edit",
              element: (
                <RoleBasedGuard accessibleRoles={ THERAPIST_ROLES }>
                  <CreateChineseUserRelationship/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/therapist/chinese-users/:id/new-session",
              element: (
                <RoleBasedGuard accessibleRoles={ THERAPIST_ROLES }>
                  <CreateRelationshipSession/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/therapist/chinese-users/:id/edit-session/:sessionId",
              element: (
                <RoleBasedGuard accessibleRoles={ THERAPIST_ROLES }>
                  <CreateRelationshipSession/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "mfa",
              element: (
                  <RoleBasedGuard
                      accessibleRoles={ THERAPIST_ROLES }
                  >
                    <TherapistMfas />
                  </RoleBasedGuard>
              ),
            },
            {
              path: "invoices",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [...INVOICES_ROLES, ROLES.SUPER_ADMIN] }
                >
                  <Invoices/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "invoices/:id",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [...INVOICES_ROLES, ROLES.SUPER_ADMIN] }
                >
                  <Invoices/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "invoices/:id/view/:invoiceNumber",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [...INVOICES_ROLES, ROLES.SUPER_ADMIN] }
                >
                  <ViewInvoice/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={ THERAPIST_ROLES }>
                  <UserList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [ROLES.THERAPIST_WRITE, ROLES.SUPER_ADMIN] }
                >
                  <UserCreate/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ROLES.THERAPIST_WRITE,
                    ROLES.THERAPIST_MANAGE,
                    ROLES.SUPER_ADMIN,
                  ] }
                >
                  <UserCreate/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view-progress",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ROLES.THERAPIST_WRITE,
                    ROLES.THERAPIST_MANAGE,
                    ROLES.SUPER_ADMIN,
                  ] }
                >
                  <GroupProgress/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard accessibleRoles={ THERAPIST_ROLES }>
                  <UserCreate/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "filters",
              element: (
                // TODO: this page will contain filter forms, restrict save options if needed
                <RoleBasedGuard accessibleRoles={ THERAPIST_ROLES }>
                  <Filters/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "users",
          children: [
            {
              element: (
                <Navigate to="/dashboard/users/delete-requests" replace/>
              ),
            },
            {
              path: "delete-requests",
              element: (
                <RoleBasedGuard accessibleRoles={ SUPER_ADMIN_ROLES }>
                  <DeleteUserRequests/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "search",
              element: (
                <RoleBasedGuard accessibleRoles={ USERS_CREDITS_ROLES }>
                  <UserCreditsProvider>
                    <UserSearchPage/>
                  </UserCreditsProvider>
                </RoleBasedGuard>
              ),
            },
            {
              path: "search/:userId",
              element: (
                <RoleBasedGuard accessibleRoles={ USERS_CREDITS_ROLES }>
                  <UserCreditsProvider>
                    <UserSearchPage/>
                  </UserCreditsProvider>
                </RoleBasedGuard>
              ),
            },
            {
              path: "migrate",
              element: (
                <RoleBasedGuard accessibleRoles={ USER_MIGRATION_ROLES }>
                  <UserMigrationPage/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "migrate/:code",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ...SUPER_ADMIN_ROLES,
                    ROLES.INV_CODE_WRITE,
                    ROLES.INV_CODE_MANAGE,
                  ] }
                >
                  <UserMigrationPage/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "find",
              element: (
                <RoleBasedGuard accessibleRoles={ SEARCH_USER_ROLE }>
                  <FindUserInfo/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATH_DASHBOARD.blendedCare.root,
          element: (
            <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
              <BlendedCare/>
            </RoleBasedGuard>
          ),
        },
        {
          path: "analytics",
          children: [
            {
              path: "/analytics/chat-analytics",
              children: [
                {
                  element: (
                    <Navigate to="/analytics/chat-analytics/list" replace/>
                  ),
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard accessibleRoles={ ANALYTICS_ROLES }>
                      <ChatsAnalytics/>
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "/analytics/call-analytics",
              children: [
                {
                  element: (
                    <Navigate to="/analytics/call-analytics/list" replace/>
                  ),
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard accessibleRoles={ ANALYTICS_ROLES }>
                      <VideoCallsAnalytics/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "edit-call",
                  element: (
                    <RoleBasedGuard accessibleRoles={ ANALYTICS_ROLES }>
                      <EditCallDetails/>
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "/analytics/call-ratings",
              element: (
                <RoleBasedGuard accessibleRoles={ ANALYTICS_ROLES }>
                  <CallRatingList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/analytics/therapist-sessions",
              children: [
                {
                  element: (
                    <Navigate to="/analytics/therapist-sessions/list" replace/>
                  ),
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard accessibleRoles={ ANALYTICS_ROLES }>
                      <TherapistSession/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "edit/:sessionId/:therapistId",
                  element: (
                    <RoleBasedGuard accessibleRoles={ ANALYTICS_ROLES }>
                      <TherapistSessionEdit/>
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "content",
          children: [
            {
              path: "/content/bot-data",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <BotData/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/bots-rating",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <BotsRating/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/bot-dtable",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <BotDataTable/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/bot-dtable/new",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [...SUPER_ADMIN_ROLES, ROLES.BOT_DATA_WRITE] }
                >
                  <AddEditTableList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/bot-dtable/:journeyKey/:outerIndex/:innerIndex/edit",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ...SUPER_ADMIN_ROLES,
                    ROLES.BOT_DATA_WRITE,
                    ROLES.BOT_DATA_MANAGE,
                  ] }
                >
                  <AddEditTableList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/bot-dtable/:journeyKey/:outerIndex/:innerIndex/view",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <AddEditTableList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/bots-overview",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <BotDataPreview/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/app-translations",
              element: (
                <RoleBasedGuard accessibleRoles={ TRANSLATIONS_ROLES }>
                  <AppTranslations/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/missing-translations",
              element: (
                <RoleBasedGuard accessibleRoles={ TRANSLATIONS_ROLES }>
                  <MissingTranslations/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/app-side-translations/",
              children: [
                {
                  element: (
                    <Navigate
                      to="/content/app-side-translations/list"
                      replace
                    />
                  ),
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard accessibleRoles={ APP_TRANSLATIONS_ROLES }>
                      <AppSideTranslationsList/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "new",
                  element: (
                    <RoleBasedGuard
                      accessibleRoles={ [
                        ROLES.SUPER_ADMIN,
                        ROLES.APP_TRANSLATION_CRUD,
                      ] }
                    >
                      <CreateAppSideTranslation/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id/view",
                  element: (
                    <RoleBasedGuard accessibleRoles={ APP_TRANSLATIONS_ROLES }>
                      <CreateAppSideTranslation/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id/edit",
                  element: (
                    <RoleBasedGuard accessibleRoles={ APP_TRANSLATIONS_ROLES }>
                      <CreateAppSideTranslation/>
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "/content/app-side-translations/",
              children: [
                {
                  element: (
                    <Navigate
                      to="/content/app-side-translations/list"
                      replace
                    />
                  ),
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard accessibleRoles={ APP_TRANSLATIONS_ROLES }>
                      <AppSideTranslationsList/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "new",
                  element: (
                    <RoleBasedGuard
                      accessibleRoles={ [
                        ROLES.SUPER_ADMIN,
                        ROLES.APP_TRANSLATION_CRUD,
                      ] }
                    >
                      <CreateAppSideTranslation/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id/view",
                  element: (
                    <RoleBasedGuard accessibleRoles={ APP_TRANSLATIONS_ROLES }>
                      <CreateAppSideTranslation/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id/edit",
                  element: (
                    <RoleBasedGuard accessibleRoles={ APP_TRANSLATIONS_ROLES }>
                      <CreateAppSideTranslation/>
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "/content/suggested-translations/",
              children: [
                {
                  element: (
                    <Navigate
                      to="/content/suggested-translations/list"
                      replace
                    />
                  ),
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard accessibleRoles={ APP_TRANSLATIONS_ROLES }>
                      <SuggestedTranslations/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "new",
                  element: (
                    <RoleBasedGuard
                      accessibleRoles={ [
                        ROLES.SUPER_ADMIN,
                        ROLES.APP_TRANSLATION_CRUD,
                      ] }
                    >
                      <CreateSuggestedTranslation/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id/view",
                  element: (
                    <RoleBasedGuard accessibleRoles={ APP_TRANSLATIONS_ROLES }>
                      <CreateSuggestedTranslation/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id/edit",
                  element: (
                    <RoleBasedGuard accessibleRoles={ APP_TRANSLATIONS_ROLES }>
                      <CreateSuggestedTranslation/>
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "/content/settings",
              children: [
                { element: <Navigate to="/content/settings/save" replace/> },
                {
                  path: "save",
                  element: (
                    <RoleBasedGuard accessibleRoles={ SETTINGS_ROLES }>
                      <Settings/>
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "/content/content-units",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <ContentUnitsProvider>
                    <AddEditContent/>
                  </ContentUnitsProvider>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/content-units/:language/:contentId",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <ContentUnitsProvider>
                    <AddEditContent/>
                  </ContentUnitsProvider>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/content-themes",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <AddEditContentTheme/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/content-themes/:themeId",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <AddEditContentTheme/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/content-images",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <AddEditContentImage/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/content-images/:imageId",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <AddEditContentImage/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/svg-images",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <AddSvgImage/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/content-colour-palettes",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <AddEditContentColourPalette/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/content-colour-palettes/:colourPaletteId",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <AddEditContentColourPalette/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/content-modules",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <ContentUnitsProvider>
                    <AddEditModulesContent/>
                  </ContentUnitsProvider>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/content-modules/:language/:moduleId",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <ContentUnitsProvider>
                    <AddEditModulesContent/>
                  </ContentUnitsProvider>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/parent-content-modules",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <ContentUnitsProvider>
                    <AddEditParentModulesContent/>
                  </ContentUnitsProvider>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/parent-content-modules/:language/:moduleId",
              element: (
                <RoleBasedGuard accessibleRoles={ BOT_DATA_ROLES }>
                  <ContentUnitsProvider>
                    <AddEditParentModulesContent/>
                  </ContentUnitsProvider>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/content/multilingual-locales",
              children: [
                {
                  element: (
                    <Navigate to="/content/multilingual-locales/list" replace/>
                  ),
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard accessibleRoles={ APP_TRANSLATIONS_ROLES }>
                      <MultilingualLocales/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "new",
                  element: (
                    <RoleBasedGuard
                      accessibleRoles={ [
                        ROLES.SUPER_ADMIN,
                        ROLES.APP_TRANSLATION_CRUD,
                      ] }
                    >
                      <CreateMultilingualLocale/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id/view",
                  element: (
                    <RoleBasedGuard accessibleRoles={ APP_TRANSLATIONS_ROLES }>
                      <CreateMultilingualLocale/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id/edit",
                  element: (
                    <RoleBasedGuard accessibleRoles={ APP_TRANSLATIONS_ROLES }>
                      <CreateMultilingualLocale/>
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "/content/multilingual-locales",
              children: [
                {
                  element: (
                    <Navigate to="/content/multilingual-locales/list" replace/>
                  ),
                },
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard accessibleRoles={ MULTILINGUAL_ROLES }>
                      <MultilingualLocales/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "new",
                  element: (
                    <RoleBasedGuard
                      accessibleRoles={ [
                        ROLES.SUPER_ADMIN,
                        ROLES.APP_TRANSLATION_CRUD,
                      ] }
                    >
                      <CreateMultilingualLocale/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id/view",
                  element: (
                    <RoleBasedGuard accessibleRoles={ APP_TRANSLATIONS_ROLES }>
                      <CreateMultilingualLocale/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id/edit",
                  element: (
                    <RoleBasedGuard accessibleRoles={ APP_TRANSLATIONS_ROLES }>
                      <CreateMultilingualLocale/>
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "conference-call",
          children: [
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={ CONFERENCE_CALL_ROLES }>
                  <ConferenceCallList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard accessibleRoles={ CONFERENCE_CALL_ROLES }>
                  <ConferenceCallCreate/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":twilioRoomName/edit",
              element: (
                <RoleBasedGuard accessibleRoles={ CONFERENCE_CALL_ROLES }>
                  <ConferenceCallCreate/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "surveys",
          children: [
            { element: <Navigate to="/dashboard/surveys/list" replace/> },
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={ SURVEY_ROLES }>
                  <SurveysList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [...SUPER_ADMIN_ROLES, ROLES.SURVEY_WRITE] }
                >
                  <CreateSurvey/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/:language/edit",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ...SUPER_ADMIN_ROLES,
                    ROLES.SURVEY_WRITE,
                    ROLES.SURVEY_MANAGE,
                  ] }
                >
                  <CreateSurvey/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/:language/view",
              element: (
                <RoleBasedGuard accessibleRoles={ SURVEY_ROLES }>
                  <CreateSurvey/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "/app-stable-version",
          element: (
            <RoleBasedGuard accessibleRoles={ APP_STABLE_VERSION_ROLES }>
              <AppStableVersion/>
            </RoleBasedGuard>
          ),
        },
        {
          path: "/iam",
          children: [
            { element: <Navigate to="/iam/roles" replace/> },
            {
              path: "roles",
              children: [
                {
                  path: "list",
                  element: (
                    <RoleBasedGuard accessibleRoles={ SUPER_ADMIN_ROLES }>
                      <RolesList/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: "new",
                  element: (
                    <RoleBasedGuard
                      accessibleRoles={ SUPER_ADMIN_ROLES }
                    >
                      <CreateRoles/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id/edit",
                  element: (
                    <RoleBasedGuard
                      accessibleRoles={ SUPER_ADMIN_ROLES }
                    >
                      <CreateRoles/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id/view",
                  element: (
                    <RoleBasedGuard accessibleRoles={ SUPER_ADMIN_ROLES }>
                      <CreateRoles/>
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":id/users-list",
                  element: (
                    <RoleBasedGuard accessibleRoles={ SUPER_ADMIN_ROLES }>
                      <UserListing />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "/iam/user/role/manage",
              element: (
                <RoleBasedGuard accessibleRoles={ SUPER_ADMIN_ROLES }>
                  <UserRoleManage />
                </RoleBasedGuard>
              ),
            },
            {
              path: "/iam/user-with-custom-claims",
              element: (
                <RoleBasedGuard accessibleRoles={ SUPER_ADMIN_ROLES }>
                  <AllUsersWithCustomClaims />
                </RoleBasedGuard>
              ),
            },
            {
              path: "/iam/reset-mfa",
              element: (
                <RoleBasedGuard accessibleRoles={ RESET_MFA_ROLES }>
                  <ResetMFAPage/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "/iam/corporate-dashboard-permissions",
              element: (
                <RoleBasedGuard accessibleRoles={ SUPER_ADMIN_ROLES }>
                  <CorporateDashboardPermissions/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "/audit-logs",
          element: (
            <RoleBasedGuard accessibleRoles={ SUPER_ADMIN_ROLES }>
              <AuditLogsGuard>
                <AuditLogs/>
              </AuditLogsGuard>
            </RoleBasedGuard>
          ),
        },
        {
          path: "/json-keys-translations",
          element: (
            <RoleBasedGuard accessibleRoles={ JSON_KEYS_TRANSLATIONS_ROLES }>
              <JsonKeysTranslations/>
            </RoleBasedGuard>
          ),
        },
        {
          path: "organisation",
          children: [
            { element: <Navigate to="/dashboard/organisation/list" replace/> },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ...SUPER_ADMIN_ROLES,
                    ROLES.CORPORATE_DASHBOARD_MANAGE,
                  ] }
                >
                  <OrganisationList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ...SUPER_ADMIN_ROLES,
                    ROLES.CORPORATE_DASHBOARD_MANAGE,
                  ] }
                >
                  <CreateOrganisation/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ...SUPER_ADMIN_ROLES,
                    ROLES.CORPORATE_DASHBOARD_MANAGE,
                  ] }
                >
                  <CreateOrganisation/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ...SUPER_ADMIN_ROLES,
                    ROLES.CORPORATE_DASHBOARD_MANAGE,
                  ] }
                >
                  <CreateOrganisation/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "invitation-codes-group",
          children: [
            {
              element: (
                <Navigate to="/dashboard/invitation-codes-group/list" replace/>
              ),
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ...SUPER_ADMIN_ROLES,
                    ROLES.CORPORATE_DASHBOARD_MANAGE,
                  ] }
                >
                  <InvitationCodeGroupList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ...SUPER_ADMIN_ROLES,
                    ROLES.CORPORATE_DASHBOARD_MANAGE,
                  ] }
                >
                  <CreateInvitationCodeGroup/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ...SUPER_ADMIN_ROLES,
                    ROLES.CORPORATE_DASHBOARD_MANAGE,
                  ] }
                >
                  <CreateInvitationCodeGroup/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ...SUPER_ADMIN_ROLES,
                    ROLES.CORPORATE_DASHBOARD_MANAGE,
                  ] }
                >
                  <CreateInvitationCodeGroup/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "release-notes",
          children: [
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={ RELEASE_NOTES_ROLES }>
                  <ReleaseNotesList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard accessibleRoles={ RELEASE_NOTES_ROLES }>
                  <CreateReleaseNote/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":language/:id/edit",
              element: (
                <RoleBasedGuard accessibleRoles={ RELEASE_NOTES_ROLES }>
                  <CreateReleaseNote/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":language/:id/view",
              element: (
                <RoleBasedGuard accessibleRoles={ RELEASE_NOTES_ROLES }>
                  <CreateReleaseNote/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "privacy-policy",
          children: [
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={ PRIVACY_POLICY_ROLES }>
                  <PrivacyPolicyList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard accessibleRoles={ PRIVACY_POLICY_ROLES }>
                  <CreatePrivacyPolicy/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard accessibleRoles={ PRIVACY_POLICY_ROLES }>
                  <CreatePrivacyPolicy/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard accessibleRoles={ PRIVACY_POLICY_ROLES }>
                  <CreatePrivacyPolicy/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "ai_prompts",
          children: [
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={ AI_PROMPT_ROLES }>
                  <AIPromptsList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ROLES.SUPER_ADMIN,
                    ROLES.AI_PROMPTS_MANAGE,
                    ROLES.AI_PROMPTS_WRITE,
                  ] }
                >
                  <CreateAIPrompt/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ROLES.SUPER_ADMIN,
                    ROLES.AI_PROMPTS_MANAGE,
                    ROLES.AI_PROMPTS_WRITE,
                  ] }
                >
                  <CreateAIPrompt/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard accessibleRoles={ AI_PROMPT_ROLES }>
                  <CreateAIPrompt/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "snippets",
          children: [
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={ AI_PROMPT_ROLES }>
                  <SnippetsList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ROLES.SUPER_ADMIN,
                    ROLES.AI_PROMPTS_MANAGE,
                    ROLES.AI_PROMPTS_WRITE,
                  ] }
                >
                  <CreateSnippet/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ROLES.SUPER_ADMIN,
                    ROLES.AI_PROMPTS_MANAGE,
                    ROLES.AI_PROMPTS_WRITE,
                  ] }
                >
                  <CreateSnippet/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard accessibleRoles={ AI_PROMPT_ROLES }>
                  <CreateSnippet/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "audio-meditation-generations",
          children: [
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={ AUDIO_ROLES }>
                  <AudioMeditationsList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [ROLES.SUPER_ADMIN, ROLES.AUDIO_CRUD] }
                >
                  <CreateAudioMeditation/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [ROLES.SUPER_ADMIN, ROLES.AUDIO_CRUD] }
                >
                  <CreateAudioMeditation/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard accessibleRoles={ AUDIO_ROLES }>
                  <CreateAudioMeditation/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/update",
              element: (
                <RoleBasedGuard accessibleRoles={ AUDIO_ROLES }>
                  <UpdateAudioMeditation/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "user-generated-audio-meditations",
          children: [
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={ USER_GENERATED_AUDIO_ROLES }>
                  <UserGeneratedAudioMeditationsList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard accessibleRoles={ USER_GENERATED_AUDIO_ROLES }>
                  <ViewUserGeneratedAudioMeditation/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "audio-meditation-voices",
          children: [
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={ MEDITATION_VOICES_ROLES }>
                  <MeditationVoicesList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard accessibleRoles={ MEDITATION_VOICES_ROLES }>
                  <CreateMeditationVoice/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard accessibleRoles={ MEDITATION_VOICES_ROLES }>
                  <CreateMeditationVoice/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard accessibleRoles={ MEDITATION_VOICES_ROLES }>
                  <CreateMeditationVoice/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "notifications",
          children: [
            {
              path: "tool",
              element: (
                <RoleBasedGuard accessibleRoles={ SUPER_ADMIN_ROLES }>
                  <Notifications/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "tool/:id",
              element: (
                <RoleBasedGuard accessibleRoles={ SUPER_ADMIN_ROLES }>
                  <Notifications/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "history",
              element: (
                <RoleBasedGuard accessibleRoles={ SUPER_ADMIN_ROLES }>
                  <NotificationsHistory/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "templates",
          children: [
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={ AI_PROMPT_ROLES }>
                  <TemplatesList/>
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ROLES.SUPER_ADMIN,
                    ROLES.AI_PROMPTS_MANAGE,
                    ROLES.AI_PROMPTS_WRITE,
                  ] }
                >
                  <CreateTemplate/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/edit",
              element: (
                <RoleBasedGuard
                  accessibleRoles={ [
                    ROLES.SUPER_ADMIN,
                    ROLES.AI_PROMPTS_MANAGE,
                    ROLES.AI_PROMPTS_WRITE,
                  ] }
                >
                  <CreateTemplate/>
                </RoleBasedGuard>
              ),
            },
            {
              path: ":id/view",
              element: (
                <RoleBasedGuard accessibleRoles={ AI_PROMPT_ROLES }>
                  <CreateTemplate/>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATH_DASHBOARD.filterContent.root,
          element: (
            <RoleBasedGuard accessibleRoles={ AI_PROMPT_ROLES }>
              <ContentUnitsProvider>
                <AddEditFilterContent/>
              </ContentUnitsProvider>
            </RoleBasedGuard>
          ),
        },
        {
          path: PATH_DASHBOARD.emailTemplates,
          element: (
            <AuthGuard>
              <EmailTemplates/>
            </AuthGuard>
          ),
        },
        { path: "*", element: <Page404/> },
      ],
    },
    {
      path: PATH_DASHBOARD.accountMFA,
      element: (
        <AuthGuard>
          <EnrollMFAPage/>
        </AuthGuard>
      ),
    },
    {
      path: PATH_DASHBOARD.accountTOTP,
      element: (
        <AuthGuard>
          <EnrollTOTPPage/>
        </AuthGuard>
      ),
    },
    {
      path: "user",
      children: [{ path: "account", element: <UserAccount/> }],
    },

    { path: "*", element: <Navigate to="/404" replace/> },
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);

// Dashboard
const UserSearchPage = Loadable(
  lazy(() => import("../pages/users/UserSearchPage"))
);

const TherapistMfas = Loadable(
    lazy(() => import("../pages/dashboard/therapist/TherapistMFAs"))
);

const DeleteUserRequests = Loadable(
  lazy(() => import("../pages/users/DeleteUserRequestsPage"))
);
const UserList = Loadable(
  lazy(() => import("../pages/dashboard/therapist/TherapistList"))
);
const ScheduledCalls = Loadable(
  lazy(() => import("../pages/dashboard/therapist/ScheduledCalls"))
);
const Invoices = Loadable(
  lazy(() => import("../pages/dashboard/therapist/Invoices"))
);
const Filters = Loadable(
  lazy(() => import("pages/dashboard/therapist/CounsellorSearchFilterList"))
);
const BlendedCare = Loadable(
  lazy(() => import("../pages/dashboard/blended-care/index"))
);
const SurveysList = Loadable(
  lazy(() => import("../pages/dashboard/survey/Surveys"))
);
const CreateSurvey = Loadable(
  lazy(() => import("../pages/dashboard/survey/CreateSurvey"))
);
const AppStableVersion = Loadable(
  lazy(() => import("../pages/dashboard/app-stable-version/AppStableVersion"))
);
const BotData = Loadable(
  lazy(() => import("../pages/dashboard/bot-data/BotData"))
);
const BotDataPreview = Loadable(
  lazy(() => import("../pages/dashboard/bot-data-overview/BotDataOverview"))
);
const BotsRating = Loadable(
  lazy(() => import("../pages/dashboard/bots-rating/BotsRating"))
);
const BotDataTable = Loadable(
  lazy(() => import("../pages/dashboard/bot-dtable/tableList"))
);
const CodeList = Loadable(
  lazy(() => import("../pages/invitationCode/InvitationCodeList"))
);
const RegistrationCodeList = Loadable(
  lazy(() => import("../pages/registrationCode/RegistrationCodeList"))
);
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);
const UserCreate = Loadable(
  lazy(() => import("../pages/dashboard/therapist/CreateTherapist"))
);
const Settings = Loadable(
  lazy(() => import("../pages/dashboard/setting/Settings"))
);
const ChatsAnalytics = Loadable(
  lazy(() => import("../pages/dashboard/chat-analytic/ChatAnalytics"))
);
const ResetMFAPage = Loadable(
  lazy(() => import("../pages/dashboard/iam/MFAResetPage"))
);
const Role = Loadable(lazy(() => import("../pages/dashboard/iam/Role")));
const AuditLogs = Loadable(
  lazy(() => import("../pages/dashboard/audit-logs/AuditLogs"))
);
const JsonKeysTranslations = Loadable(
  lazy(
    () =>
      import("../pages/dashboard/json-keys-translations/JsonKeysTranslations")
  )
);
const ConferenceCallList = Loadable(
  lazy(() => import("../pages/dashboard/conference-call/ConferenceCallList"))
);
const ConferenceCallCreate = Loadable(
  lazy(() => import("../pages/dashboard/conference-call/ConferenceCallCreate"))
);
const AppTranslations = Loadable(
  lazy(() => import("../pages/dashboard/app-translation/AppTranslations"))
);
const MissingTranslations = Loadable(
  lazy(
    () => import("../pages/dashboard/missing-translations/MissingTranslations")
  )
);
const VideoCallsAnalytics = Loadable(
  lazy(() => import("../pages/dashboard/call-analytics/VideoCallsAnalytics"))
);
const TherapistSession = Loadable(
  lazy(() => import("../pages/dashboard/therapist-session/TherapistSession"))
);
const OrganisationList = Loadable(
  lazy(() => import("../pages/dashboard/organisation/Organisations"))
);
const CreateOrganisation = Loadable(
  lazy(() => import("../pages/dashboard/organisation/CreateOrganisation"))
);
const InvitationCodeGroupList = Loadable(
  lazy(
    () =>
      import("../pages/dashboard/invitation-code-group/InvitationCodeGroups")
  )
);
const CreateInvitationCodeGroup = Loadable(
  lazy(
    () =>
      import(
        "../pages/dashboard/invitation-code-group/CreateInvitationCodeGroup"
        )
  )
);

const CorporateDashboardPermissions = Loadable(
  lazy(
    () =>
      import("../pages/dashboard/iam/corporateDashboardPermission/Permissions")
  )
);

const AppSideTranslationsList = Loadable(
  lazy(
    () =>
      import("../pages/dashboard/app-side-translations/AppSideTranslationsList")
  )
);

const SuggestedTranslations = Loadable(
  lazy(
    () =>
      import(
        "../pages/dashboard/content/suggested-translations/SuggestedTranslations"
        )
  )
);
