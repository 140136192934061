import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// material
import { LoadingButton } from "@mui/lab";
import { Box, Card, Grid, Stack, TextField, } from "@mui/material";

import LoadingScreen from "../../LoadingScreen";
import { ROLES, } from "../../../utils/constants";
import { AccessRole } from "../../../types/role/CCRoles";
import { v4 as UUID } from "uuid";
import { ErrorException } from "../../../types/settings";
import { createOrUpdateRole } from "../../../utils/firebase/rolesUtil";
import { removeDuplicateStrings } from "../../../utils/helpers";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// ----------------------------------------------------------------------

type AudioMeditationGenerationFormProps = {
  isEdit: boolean;
  isView?: boolean;
  isNew?: boolean;
  loading: boolean;
  role: AccessRole;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function RolesForm({
  isView,
  isEdit,
  role,
  loading,
}: AudioMeditationGenerationFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false)

  const roleSchema = () => {
    return Yup.object().shape({
      title: Yup.string().required("Title is required"),
      customClaims: Yup.array().of(Yup.string().min(1, "Please select at least one Role").required()).required("Role is required"),
    })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: role?.id ?? "",
      title: role?.title ?? "",
      customClaims: role?.customClaims ?? []
    },

    validationSchema: roleSchema,
    onSubmit: async (values) => {
      try {
        setSaving(true);
        const roleRequest = {
          id: role?.id ?? UUID(),
          title: values.title,
          customClaims: values.customClaims ?? [],
        } as AccessRole

        await createOrUpdateRole(roleRequest)

        navigate(-1);

        let successMessage = "Role is created successfully.";
        if (isEdit) {
          successMessage = "Role is edit successfully"
        }

        enqueueSnackbar(successMessage, { variant: "success" });
      } catch (error: ErrorException) {
        console.error("FailedToCreateOrUpdateRole", error);
        enqueueSnackbar("Role creation/Updation failed: " + error?.message, { variant: "error" });
      } finally {
        setSaving(false)
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, values } = formik;

  let submitBtnTitle = "Create Role";
  if (isView) {
    submitBtnTitle = "View Only";
  } else if (isEdit) {
    submitBtnTitle = "Update Role";
}

  if (loading) {
    return <LoadingScreen/>;
  }

  return (
    <FormikProvider value={ formik }>
      <Form noValidate autoComplete="off" onSubmit={ handleSubmit }>
        <fieldset disabled={ isView } style={ { border: "none" } }>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 }>
              <Card sx={ { p: 3 } }>
                <Stack spacing={ 3 }>
                  <TextField
                    fullWidth
                    label="Title"
                    { ...getFieldProps("title") }
                    disabled={ isView }
                    error={ Boolean(touched.title && errors.title) }
                    helperText={ touched.title && errors.title }
                  />

                  <Autocomplete
                    fullWidth
                    multiple
                    id="checkboxes-tags-demo"
                    value={values?.customClaims}
                    onChange={(event, value, reason, details) => {
                      setFieldValue('customClaims', value);
                    }}
                    options={removeDuplicateStrings([
                      ...(values?.customClaims || []),
                      ...Object.values(ROLES)
                    ])}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Custom Claims"
                        placeholder="Select custom claims"
                      />
                    )}
                  />

                  <Box sx={ { mt: 3, display: "flex", justifyContent: "flex-end" } }>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={ saving || isSubmitting }>
                      { submitBtnTitle }
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </fieldset>
      </Form>
    </FormikProvider>
  );
}
