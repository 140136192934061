import { useState } from "react";

import { firebaseAuth } from "../../contexts/FirebaseContext";
import { getAlgoliaSearchKey } from "utils/firebase/cloudFunctions";
import { useInterval } from "../useInterval";

type SearchKeyResult = { searchKey: string; validUntil: number };
export function useAlgoliaSearchKey() {
  const [searchKey, setSearchKey] = useState<SearchKeyResult>();
  const [loading, setLoading] = useState<boolean>(false);

  useInterval(() => {
    updateAlgoliaSearchKey();
  }, 1000);

  const updateAlgoliaSearchKey = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      // dividing by 1000 convert milliseconds to seconds
      // it is because `validUntil` is in seconds.
      const now = Date.now() / 1000;

      // considering searchKey as expired, 300seconds(5min) earlier than the actual expiration.
      const sessionIsExpired = (searchKey?.validUntil || 0) - now <= 300;

      if (sessionIsExpired) {
        const searchKeyResult = await getAlgoliaSearchKey({
          userId: firebaseAuth().currentUser?.uid,
        });
        setSearchKey((oldState) => searchKeyResult.data as SearchKeyResult);
      }
    } finally {
      setLoading(false);
    }
  };

  return searchKey?.searchKey;
}
