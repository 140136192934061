import * as yup from 'yup';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
// utils
//
import {
  AddNewJourneyFormProps,
  BOT_DATA_ITEM_KEYS,
  JourneysBotPropsType
} from '../../../../types/bot-data/botDataType';
import React, { useEffect, useState } from 'react';
import BotDataModal from '../component/BotDataDialog';
import { useSnackbar } from 'notistack';
import { updateBotData } from '../../../../utils/firebase/botDataUtils';
import { FocusError } from 'focus-formik-error';
import { mergeDeep } from '../../../../utils/helpers';
import { useLocation, useParams } from 'react-router-dom';
import { useLastChange } from '../../../../hooks/useLastChange';
import { filterUndefinedBotData } from 'utils/filterUndefinedBotData';
import { ErrorException } from "../../../../types/settings";

// ----------------------------------------------------------------------

export default function JourneysBotData({ journeys, selectedLanguage }: JourneysBotPropsType) {
  const { pathname } = useLocation();
  const { journeyKey = '', outerIndex = '', innerIndex = '' } = useParams();
  const isEdit = pathname.includes('edit');
  const isNew = pathname.includes('new');
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);

  const lastChange = useLastChange();

  const toggleOpenModal = () => {
    setOpenModal((prevState) => !prevState);
  };

  const JourneyItemSchema = yup.object().shape({
    id: Yup.string(),
    key: yup.string(),
    title: Yup.string(),
    description: Yup.string(),
    sortPlace: yup.number(),
    progress: Yup.string(),
    header: Yup.string(),
    name: Yup.string(),
    emotion: Yup.string(),
    themeColor: Yup.string(),
  });

  const JourneyBotDataSchema = yup.lazy((Journeys) => {
    return yup.object(
      Object.fromEntries(
        Object.keys(Journeys).map((mainKey) => {
          return [
            mainKey,
            yup.lazy((journeyMainItem) => {
              return yup.object(
                Object.fromEntries(
                  Object.keys(journeyMainItem).map((innerIndKey) => [
                    innerIndKey,
                    JourneyItemSchema
                  ])
                )
              );
            })
          ];
        })
      )
    );
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...(journeys || {})
    },
    validationSchema: JourneyBotDataSchema,
    onSubmit: async (values) => {
      const filteredValues = filterUndefinedBotData(values);

      try {
        await updateBotData(selectedLanguage, { journeys: filteredValues, lastChange });
        enqueueSnackbar('Saved successfully!', { variant: 'success' });
      } catch (e: ErrorException) {
        enqueueSnackbar('Failed to save', { variant: 'error' });
      }
    }
  });

  const { getFieldProps, setFieldValue, errors, touched, handleSubmit, isSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <FocusError formik={formik} />
        <Stack spacing={3}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            sx={{ justifyContent: 'space-between' }}
          >
            <Typography mt={1} variant="subtitle2">
              <h2>Journeys</h2>
            </Typography>
          </Stack>

          <hr />
          <Typography mt={2} variant="subtitle2">
            <h3>Add/Edit Journey</h3>
          </Typography>
          <AddEditJourneyField
            journeyKey={journeyKey}
            outerIndex={outerIndex}
            innerIndex={innerIndex}
            isEdit={isEdit}
            isNew={isNew}
            journeys={journeys}
            isSubmitting={isSubmitting}
            getFieldProps={getFieldProps}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
          <BotDataModal
            data={journeys}
            open={openModal}
            close={toggleOpenModal}
            title={'Journeys'}
            isJourney={true}
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
}

function AddEditJourneyField({
  journeyKey,
  outerIndex,
  innerIndex,
  isEdit,
  isNew,
  journeys,
  isSubmitting,
  getFieldProps,
  errors,
  touched,
  setFieldValue
}: AddNewJourneyFormProps) {
  const addNewJourney = 'Add New journey';
  let initialSelectedValue = '';
  if (isEdit || isNew) {
    initialSelectedValue = journeyKey || addNewJourney;
  }
  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);
  let initialAddingNew = false;
  if (isNew) {
    initialAddingNew = true;
  }
  const [addingNew, setAddingNew] = useState(initialAddingNew);
  const [newFields, setNewFields] = useState<Array<string>>([]);
  const [newKeyValue, setNewKey] = useState<{
    keyName: string;
    error: boolean;
    helperText: string;
  }>({
    keyName: '',
    error: false,
    helperText: ''
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const journeysData = journeys || {};
  const journeyKeys = [...Object?.keys?.(journeysData), ...newFields, addNewJourney];
  let allInputKeys: Array<string> = BOT_DATA_ITEM_KEYS;

  const [selectedIndices, setSelectedIndices] = useState({
    outer: outerIndex || 0,
    inner: innerIndex || 0
  });

  const [innerIndicesCount, setInnerIndicesCount] = useState(innerIndex || 0);
  const [outerIndicesCount, setOuterIndicesCount] = useState(outerIndex || 0);

  useEffect(() => {
    if (
      (selectedIndices.outer === outerIndicesCount ||
        selectedIndices.inner === innerIndicesCount) &&
      selectedValue !== addNewJourney
    ) {
      const currentValue = getFieldProps(selectedValue).value || {};
      let updatedValue = {
        [selectedIndices.outer]: {
          [selectedIndices.inner]: {}
        }
      };
      updatedValue = mergeDeep(updatedValue, currentValue);
      setFieldValue(selectedValue, updatedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndices]);

  useEffect(() => {
    // @ts-ignore
    setOuterIndicesCount(Object.keys(journeysData?.[selectedValue] || {})?.length || 0)
  }, [selectedValue, journeysData]);

  useEffect(() => {
    // @ts-ignore
    setInnerIndicesCount(Object.keys(journeysData?.[selectedValue]?.[selectedIndices.outer] || {})?.length || 0)
  }, [journeysData, selectedIndices.outer, selectedValue]);

  let InputKeysToRender: any = allInputKeys?.map?.((keyName: string) => {
    const helperText =
      // @ts-ignore
      Boolean(touched?.[selectedValue]?.[keyName] && errors?.[selectedValue]?.[keyName])
        ? // @ts-ignore
          errors?.[selectedValue]?.[keyName]
        : null;

    const error = Boolean(
      // @ts-ignore
      touched?.[selectedValue]?.[keyName] && errors?.[selectedValue]?.[keyName]
    );

    const nameOrOption = `${selectedValue}.${selectedIndices.outer}.${selectedIndices.inner}.${keyName}`;
    return (
      <Stack
        key={selectedValue + keyName}
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
        sx={{ justifyContent: 'space-between' }}
      >
        <Typography key={String(selectedValue + keyName + 'label')} mt={2} variant="subtitle1">
          {keyName}
        </Typography>
        <TextField
          key={selectedValue + selectedIndices.outer + selectedIndices.inner + keyName}
          sx={{ m: 1, width: '80%' }}
          {...getFieldProps(nameOrOption)}
          placeholder={`Enter ${keyName}`}
          defaultValue={keyName === 'key' ? selectedValue : ''}
          type={keyName === 'sortPlace' ? 'number' : 'text'}
          helperText={helperText}
          error={error}
        />
      </Stack>
    );
  });

  if (addingNew) {
    const keyNo = newFields.length;
    InputKeysToRender = (
      <Stack
        key={keyNo}
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
        sx={{ justifyContent: 'space-between' }}
      >
        <Typography key={String(keyNo + 'label')} mt={2} variant="subtitle1">
          new key to add
        </Typography>
        <TextField
          key={String(keyNo) + addingNew}
          value={newKeyValue?.keyName}
          onChange={(e) => setNewKey({ error: false, helperText: '', keyName: e.target.value })}
          sx={{ m: 1, width: '65%' }}
          placeholder={`Enter name for key to be added.`}
          type="text"
          error={newKeyValue?.error}
          helperText={newKeyValue?.helperText}
        />
        <Button
          variant={'contained'}
          onClick={() => {
            if (!newKeyValue.keyName.trim()) {
              setNewKey({
                keyName: newKeyValue.keyName,
                error: true,
                helperText: 'key name must not be empty'
              });
            } else {
              if (
                newFields.includes(newKeyValue.keyName) ||
                journeyKeys.includes(newKeyValue.keyName)
              ) {
                setNewKey({
                  ...newKeyValue,
                  error: true,
                  helperText: 'key already exists.'
                });
              } else {
                setNewFields([newKeyValue.keyName, ...newFields]);
                setSelectedValue(newKeyValue.keyName);
                setFieldValue(newKeyValue.keyName, {
                  '0': {
                    '0': {
                      key: newKeyValue.keyName
                    }
                  }
                });
                setAddingNew(false);
                setNewKey({
                  keyName: '',
                  error: false,
                  helperText: ''
                });
              }
            }
          }}
        >
          Ok
        </Button>
      </Stack>
    );
  }

  const outerIndices = [];
  for (let oI = 0; oI <= Number(outerIndicesCount); oI++) {
    outerIndices.push(oI);
  }

  const innerIndices = [];
  for (let iI = 0; iI <= Number(innerIndicesCount); iI++) {
    innerIndices.push(iI);
  }

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
        sx={{ justifyContent: 'space-between' }}
      >
        <Typography mt={2} variant="subtitle1">
          key
        </Typography>
        <TextField
          key={selectedValue + selectedIndices.outer + selectedIndices.inner}
          sx={{ m: 1, width: '80%' }}
          select
          label="select key"
          placeholder="key"
          onChange={(e) => {
            setSelectedValue(e.target.value);
            setAddingNew(e.target.value === addNewJourney);
            // @ts-ignore
            setOuterIndicesCount(Object.keys(journeysData?.[selectedValue] || {})?.length || 0)
          }}
          value={selectedValue}
          SelectProps={{ native: true }}
        >
          <option value="" disabled selected>
            select key
          </option>
          {journeyKeys?.map?.((botKey: any) => {
            return <option key={String(botKey)}>{botKey}</option>;
          })}
        </TextField>
      </Stack>
      {
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          sx={{ justifyContent: 'space-between' }}
        >
          <Typography mt={2} variant="subtitle1">
            outer index
          </Typography>
          <TextField
            disabled={!selectedValue}
            sx={{ m: 1, width: '80%' }}
            select
            name="outerIndex"
            label="select key"
            placeholder="key"
            onChange={(e) => {
              setSelectedIndices({
                inner: 0,
                outer: Number(e?.target?.value || 0)
              });
            }}
            value={selectedIndices?.outer}
            SelectProps={{ native: true }}
          >
            <option value="" disabled selected>
              select outer index
            </option>
            {
              // eslint-disable-next-line @typescript-eslint/no-shadow
              outerIndices?.map?.((outerIndex: any) => {
                return <option key={String(outerIndex + 'outer')}>{outerIndex}</option>;
              })
            }
          </TextField>
        </Stack>
      }

      {
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          sx={{ justifyContent: 'space-between' }}
        >
          <Typography mt={2} variant="subtitle1">
            inner index
          </Typography>
          <TextField
            disabled={!selectedValue && !String(selectedIndices?.outer)}
            sx={{ m: 1, width: '80%' }}
            select
            name="innerIndex"
            label="select key"
            placeholder="key"
            onChange={(e) => {
              setSelectedIndices({
                ...selectedIndices,
                inner: Number(e?.target?.value || 0)
              });
            }}
            value={selectedIndices?.inner}
            SelectProps={{ native: true }}
          >
            <option value="" disabled selected>
              select inner index
            </option>
            {
              // eslint-disable-next-line @typescript-eslint/no-shadow
              innerIndices?.map?.((innerIndex: any) => {
                return <option key={String(innerIndex + 'inner')}>{innerIndex}</option>;
              })
            }
          </TextField>
        </Stack>
      }
      {InputKeysToRender}

      {selectedValue !== addNewJourney && (
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Save
          </LoadingButton>
        </Box>
      )}
    </>
  );
}
