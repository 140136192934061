import { Container } from "@mui/material";
import HeaderBreadcrumbs from "components/HeaderBreadcrumbs";
import Page from "components/Page";
import useSettings from "hooks/useSettings";
import SvgImageInnerForm from "./SvgImageInnerForm";
import useGetColourPalettes from "hooks/useGetColourPalettes";

export default function AddEditContentImage() {
  const { themeStretch } = useSettings();
  const colourPalettes = useGetColourPalettes();

  const colourPalettesOptions = colourPalettes?.map((palette) => {
    return palette.id;
  });

  return (
    <Page title="Add Content SVG Images">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs heading={"Content SVG Images"} links={[]} />
        <SvgImageInnerForm
          colourPalettesOptions={colourPalettesOptions ?? []}
        />
      </Container>
    </Page>
  );
}
