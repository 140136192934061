import { Typography } from "@mui/material";
import { PaperPage } from "components/PaperPage";
import { FormikHelpers } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MultiFactorResolver, TotpMultiFactorGenerator } from "firebase/auth";
import {
  TwoFactorVerificationForm,
  VerificationCodeFormValues,
} from "./TwoFactorVerificationForm";
import { MFAStep } from "../../../types/authentication";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { isGoogleApiError } from "../../../utils/isGoogleApiError";
import { ErrorException } from "../../../types/settings";

export function TOTPLogin({
  mfaResolver,
  selectedIndex,
}: {
  mfaResolver: MultiFactorResolver;
  selectedIndex: number;
}) {
  const [step, setStep] = useState<MFAStep>(MFAStep.VERIFY);

  const navigate = useNavigate();

  const onSubmit = async (
    values: VerificationCodeFormValues,
    helpers: FormikHelpers<VerificationCodeFormValues>
  ) => {
    const { verificationCode: otpFromAuthenticator } = values;

    try {
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
        mfaResolver.hints[selectedIndex].uid,
        otpFromAuthenticator
      );

      await mfaResolver.resolveSignIn(multiFactorAssertion);
      setStep(MFAStep.LOGGING_IN);
      navigate(PATH_DASHBOARD.root);
    } catch (e: ErrorException) {
      if (isGoogleApiError(e)) {
        if (e.code === "auth/invalid-verification-code") {
          helpers.setFieldError("verificationCode", "invalid");
          return;
        }
      }

      console.error(e);
      setStep(MFAStep.ERROR);
    }
  };

  if ([MFAStep.LOGGING_IN, MFAStep.ERROR].includes(step)) {
    let title = "Success";
    let message = "Logging you in";
    if (step === MFAStep.ERROR) {
      title = "An error occurred";
      message = "An error occurred. Try again or contact support if the issue persists";
    }

    return (
      <>
        <PaperPage headTitle={"Verify it's you"} title={title}>
          <Typography textAlign="center">{message}</Typography>
        </PaperPage>
      </>
    );
  }

  return (
    <>
      <PaperPage
        headTitle={"Complete Sign-in"}
        title={"Verify it's you"}
      >
        <TwoFactorVerificationForm
          hideResendCodeButton={true}
          hint={"Please enter the code displayed on your authenticator app"}
          onSubmit={onSubmit}
          onResendRequested={() => {}}
        />
      </PaperPage>
    </>
  );
}
