import {Icon} from "@iconify/react";
import React, {useState} from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import {Link as RouterLink, useNavigate} from "react-router-dom";
// material
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {useSnackbar} from "notistack";

import useSettings from "../../hooks/useSettings";
import {deleteSpecificPaymentLink, useGetPaymentLinks} from "../../utils/firebase/paymentLinksUtils";
import {PATH_DASHBOARD} from "../../routes/paths";
import {PaymentLink} from "../../types/invitation/code";
import Page from "../../components/Page";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import TableToolbar from "../../components/common/TableToolbar";
import Scrollbar from "../../components/Scrollbar";
import TableHeader from "../../components/common/TableHeader";
import MoreMenu from "../../components/common/MoreMenu";
import SearchNotFound from "../../components/SearchNotFound";
import DeleteConfirmationDialog from "../../components/_dashboard/content/DeleteConfirmationDialog";
import {filter} from "lodash";
import {Anonymous} from "../../types/list";
import { ErrorException } from "../../types/settings";


const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "quantity", label: "Quantity", alignRight: false },
  { id: "unitAmount", label: "Unit amount", alignRight: false },
  { id: "currency", label: "Currency", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

// ----------------------------------------------------------------------

function applySortFilter(
    array: PaymentLink[],
    comparator: (a: any, b: any) => number,
    query: string
) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
        array,
        (group) => group.name.en.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === "desc"
      ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
      : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}


export default function PaymentLinksList() {
  const { themeStretch } = useSettings();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState("name.en");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [linkToDelete, setLinkToDelete] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const paymentLinks = useGetPaymentLinks();


  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelectedIds = paymentLinks?.map((n) => n.id);
      setSelected(newSelectedIds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleFilterByName = (SelectedFilterName: string) => {
    setFilterName(SelectedFilterName);
  };

  const handleOpenDeleteDialog = (linkId: string) => {
    setLinkToDelete(linkId);
    setOpenDeleteDialog(true);
  };

  const handleDelete = async () => {
    if(!linkToDelete){
      return;
    }

    try {
      setLoadingDelete(true)
      await deleteSpecificPaymentLink(linkToDelete)
      setLoadingDelete(false)
      setOpenDeleteDialog(false)
    }catch (e: ErrorException) {
      console.error(e)
    }
  };

  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - paymentLinks.length) : 0;

  const filteredGroup = applySortFilter(
      paymentLinks,
      getComparator(order, orderBy),
      filterName
  );


  const isGroupNotFound = filteredGroup.length === 0;

  const onGetLinkForUser = (linkId: string, paymentLinkUrl?: string) => {
    if(!paymentLinkUrl){
      enqueueSnackbar("Link not found", {variant: "error"})
      return;
    }

    navigate(`${PATH_DASHBOARD.paymentLinks.root}/${linkId}/get-link`, {
      state: {paymentLinkUrl},
    });
  }

  const editPaymentLink = (paymentLink: PaymentLink, isViewing?: boolean) => {
    if(!paymentLink || !paymentLink.id){
      enqueueSnackbar("Link not found", {variant: "error"})
      return;
    }

    const path = isViewing ? "view" : "edit";

    navigate(`${PATH_DASHBOARD.paymentLinks.root}/${paymentLink.id}/${path}`, {
      state: paymentLink,
    });
  }


  return (
      <Page title="Payment Links">
        <Container maxWidth={themeStretch ? false : "lg"}>
          <HeaderBreadcrumbs
              heading=""
              links={[]}
              action={
                <>
                  <Button
                      variant="contained"
                      component={RouterLink}
                      to={PATH_DASHBOARD.paymentLinks.newLink}
                      startIcon={<Icon icon={plusFill} />}
                  >
                    Add New Payment Link
                  </Button>
                </>
              }
          />

          <Card>
            <TableToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                searchPlaceholder={"Search"}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHeader
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={paymentLinks.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                      hideCheckbox
                  />
                  <TableBody>
                    {filteredGroup
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row: PaymentLink) => {
                          const { id, name, currency, unitAmount, quantity, paymentLinkUrl } = row;
                          const isItemSelected = selected.indexOf(id) !== -1;

                          return (
                              <TableRow
                                  hover
                                  key={id}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                              >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    padding="normal"
                                >
                                  <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                  >
                                    <Typography variant="subtitle2" noWrap>
                                      {name.en}
                                    </Typography>
                                  </Stack>
                                </TableCell>

                                <TableCell
                                    component="th"
                                    scope="row"
                                    padding="normal"
                                >
                                  <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                  >
                                    <Typography variant="body1" noWrap>
                                      {quantity}
                                    </Typography>
                                  </Stack>
                                </TableCell>

                                <TableCell
                                    component="th"
                                    scope="row"
                                    padding="normal"
                                >
                                  <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                  >
                                    <Typography variant="body1" noWrap>
                                      {unitAmount}
                                    </Typography>
                                  </Stack>
                                </TableCell>

                                <TableCell
                                    component="th"
                                    scope="row"
                                    padding="normal"
                                >
                                  <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                  >
                                    <Typography variant="body1" noWrap>
                                      {currency}
                                    </Typography>
                                  </Stack>
                                </TableCell>

                                <TableCell align="right">
                                  <MoreMenu
                                      onView={() => {
                                        editPaymentLink(row, true)
                                      }}
                                      onEdit={() => editPaymentLink(row)}
                                      onDelete={() => handleOpenDeleteDialog(id)}
                                      copyText={"Get link for user"}
                                      onCopy={() => onGetLinkForUser(id, paymentLinkUrl)}
                                  />
                                </TableCell>
                              </TableRow>
                          );
                        })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                    )}
                  </TableBody>
                  {isGroupNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={paymentLinks.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, _page) => setPage(_page)}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <DeleteConfirmationDialog
                open={openDeleteDialog}
                loading={loadingDelete}
                dialogTitle={"Delete"}
                dialogDescription={"Are you sure you want to proceed?"}
                deleteButtonText={"Delete"}
                onClose={() => {
                  setLinkToDelete(null);
                  setOpenDeleteDialog(false);
                }}
                handleDelete={handleDelete}
            />
          </Card>
        </Container>
      </Page>
  );
}
