import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

// material
import {
    Grid,
    Card,
    Container,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
    Link,
    Chip, TextField, CircularProgress
} from "@mui/material";

// hooks
import useSettings from "../../../hooks/useSettings";

// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import TableToolbar from "../../../components/common/TableToolbar";
import TableHeader from "../../../components/common/TableHeader";
import LoadingScreen from "../../../components/LoadingScreen";
import {getComparator} from "../../../utils/list";
import {filter} from "lodash";
import * as CloudFunctions from "utils/firebase/cloudFunctions";
import {PATH_DASHBOARD} from "../../../routes/paths";
import Autocomplete from "@mui/material/Autocomplete";
import {removeDuplicateStrings} from "../../../utils/helpers";
import {ROLES} from "../../../utils/constants";
import {format} from "date-fns";
import {dateTimeDisplayFormatWithoutZone} from "../../../utils/analytics/analyticsDefaults";
import MoreMenu from "../../../components/common/MoreMenu";
import {deleteAppTranslation} from "../../../utils/firebase/translationUtils";
import {incrementContentVersion} from "utils/firebase/cloudFunctions";
import {ContentVersionsField} from "../../../types/translations";
import {ErrorException} from "../../../types/settings";
import {useSnackbar} from "notistack";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {id: "displayName", label: "Display Name", alignRight: false},
    {id: "email", label: "Email", alignRight: false},
    {id: "claims", label: "Claims", alignRight: false},
    {id: "creationTime", label: "Created At", alignRight: false},
    {id: "lastLoginTime", label: "Last Login At", alignRight: false},
    {id: "action", label: "Action", alignRight: true},
];

// ----------------------------------------------------------------------


let UserState = {
    pageNo: 0,
    pageSize: 5,
    orderBy: "",
    searchText: "",
    order: "desc"
}

type UserRecord = {
    uid: string;
    displayName: string;
    email: string;
    creationTime?: Date;
    lastLoginTime?: Date;
    userRoles: Array<string>;
}

export default function AllUsersWithCustomClaims() {
    const {themeStretch} = useSettings();

    const [page, setPage] = useState(UserState.pageNo || 0);
    const [selectedClaim, setSelectedClaim] = useState("");
    const [order, setOrder] = useState<"asc" | "desc">(UserState.order as "asc" | "desc");
    const [selected, setSelected] = useState<string[]>([]);
    const [orderBy, setOrderBy] = useState(UserState.orderBy || "email");
    const [filterName, setFilterName] = useState(UserState.searchText);
    const [rowsPerPage, setRowsPerPage] = useState(UserState.pageSize || 5);
    const [usersRecords, setUsersRecords] = useState<Array<UserRecord>>([]);
    const [loading, setLoading] = useState(false);
    const [deletingId, setDeletingId] = useState<string>("");

    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        const fetchAllUserWithCustomClaims = async () => {
            setLoading(true);

            try {
                const response = await CloudFunctions.listAllUsersWithClaims({})
                const formattedUsers = response?.data?.map((user: UserRecord) => {
                    if (user.creationTime) {
                        user.creationTime = new Date(user.creationTime)
                    }
                    if (user.lastLoginTime) {
                        user.lastLoginTime = new Date(user.lastLoginTime)
                    }

                    return user;
                })
                setUsersRecords(formattedUsers);
            } catch (err) {
                console.error('Failed to fetch users');
            } finally {
                setLoading(false);
            }
        }

        fetchAllUserWithCustomClaims()
    }, []);

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (checked: boolean) => {
        setSelected(checked ? usersRecords?.map((user) => user?.displayName) : [])
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event?.target?.value ?? 0, 10));
        setPage(0);
    };

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, _page: number) => {
        setPage(_page);
    };

    const handleFilterByName = (newFilter: string) => {
        setFilterName(newFilter);
    };

    const applySortFilter = (
        array: UserRecord[],
        comparator: (a: any, b: any) => number,
        query: string,
    ) => {
        const stabilizedThis = array.map((el, index) => [el, index] as const);
        stabilizedThis.sort((a, b) => {
            const orderVal = comparator(a[0], b[0]);
            if (orderVal !== 0) return orderVal;
            return a[1] - b[1];
        });

        if (query || selectedClaim) {
            return filter(
                array,
                (_user) => ((((_user?.displayName && _user?.displayName?.toLowerCase?.()?.indexOf(query.toLowerCase()) !== -1) || _user?.email?.toLowerCase()?.indexOf(query.toLowerCase()) !== -1) || query?.length === 0)
                    && (selectedClaim?.length === 0 || _user?.userRoles?.includes?.(selectedClaim)))
            );
        }
        return stabilizedThis.map((el) => el[0]);
    }

    const filteredUsersRecords = applySortFilter(
        usersRecords,
        getComparator(order, orderBy),
        filterName,
    );
    const isUserNotFound = !Boolean(filteredUsersRecords.length) && !loading;

    const handleOnAllCustomClaimsDelete = async (deletingEmail: string) => {
        try {
            setDeletingId(deletingEmail);

            if (
                window.confirm(
                    `Do you really want to delete all Custom Claims for ${deletingEmail}?`
                )
            ) {

                await CloudFunctions.updateRole({
                    email: deletingEmail,
                    isUsingApiV2: true,
                    isRemoveAllRoles: true
                });

                setUsersRecords(usersRecords.filter(userRecord => userRecord.email !== deletingEmail))
                enqueueSnackbar("Custom Claims Deleted Successfully", {
                    variant: "success",
                });
            } else {
                enqueueSnackbar("Delete Custom Claims cancel by user", {
                    variant: "error",
                });
            }
        } catch (e: ErrorException) {
            console.error("FailedToDeleteCustomClaims", e);
            enqueueSnackbar("Failed to delete Custom Claims", {
                variant: "error",
            });
        } finally {
            setDeletingId("");
        }
    };

    if (loading) {
        return <LoadingScreen/>;
    }

    const renderRight = () => {
        return <Autocomplete
            id="checkboxes-tags-demo"
            value={selectedClaim}
            onChange={(event, value, reason, details) => {
                setSelectedClaim(value ?? "");
            }}
            options={removeDuplicateStrings([
                selectedClaim,
                ...Object.values(ROLES)
            ])}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(props, option) => (
                <li {...props}>
                    {option}
                </li>
            )}
            style={{width: 270}}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Claim"
                    placeholder="Select the Claim to Filter"
                />
            )}
        />
    }

    return (
        <Page title="Users">
            <Container maxWidth={themeStretch ? false : "lg"}>
                <Typography variant="h5" gutterBottom>
                    Manage Users' Custom Claims
                </Typography>

                <Card>
                    <TableToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        rightIcon={renderRight}
                        onFilterName={handleFilterByName}
                        searchPlaceholder={"Search User by Name/email"}
                    />

                    <Scrollbar>
                        <TableContainer sx={{minWidth: 800}}>
                            <Table>
                                <TableHeader
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={usersRecords.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                    hideCheckbox
                                />
                                <TableBody>
                                    {filteredUsersRecords
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((userRecord: UserRecord) => {
                                            const {
                                                uid,
                                                displayName,
                                                email,
                                                userRoles = [],
                                                creationTime,
                                                lastLoginTime
                                            } = userRecord;
                                            const isDeleting = deletingId === email;
                                            const isItemSelected = selected.indexOf(displayName) !== -1;
                                            const encodedEmail = encodeURIComponent(email ?? "");

                                            return (
                                                <TableRow
                                                    hover
                                                    key={`${displayName}-${uid}`}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="normal"
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            spacing={2}
                                                        >
                                                            <Typography variant="subtitle2" noWrap>
                                                                {displayName}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="normal"
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            spacing={2}
                                                        >
                                                            <Typography variant="body1" noWrap>
                                                                {email}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="normal">
                                                        <Grid container spacing={1}>
                                                            {userRoles.map((chip, index) => (
                                                                <Chip
                                                                    sx={{mt: 1}}
                                                                    key={index}
                                                                    label={chip}
                                                                    color={'info'}
                                                                />
                                                            ))}
                                                        </Grid>
                                                    </TableCell>

                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="normal"
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            spacing={2}
                                                        >
                                                            <Typography variant="body1" noWrap>
                                                                {creationTime ? format(creationTime, dateTimeDisplayFormatWithoutZone) : "---"}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        padding="normal"
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            spacing={2}
                                                        >
                                                            <Typography variant="body1" noWrap>
                                                                {lastLoginTime ? format(lastLoginTime, dateTimeDisplayFormatWithoutZone) : "---"}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            spacing={2}>

                                                            {isDeleting && (
                                                                <CircularProgress
                                                                    sx={{
                                                                        position: "absolute",
                                                                        alignSelf: "center",
                                                                        right: 10,
                                                                    }}
                                                                    size={25}
                                                                />
                                                            )}

                                                            {!isDeleting && <MoreMenu
                                                                onDelete={() => handleOnAllCustomClaimsDelete(email)}
                                                                editButtonText="Edit Claims"
                                                                deleteButtonText="Delete All Claims"
                                                                onEdit={() => {
                                                                    navigate(`${PATH_DASHBOARD.iam.userRoleManage}?userEmail=${encodedEmail}`)
                                                                }}
                                                            />
                                                            }
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                <SearchNotFound searchQuery={filterName}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                        component="div"
                        count={filteredUsersRecords.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    );
}
