import { ReactNode, useEffect, useReducer, useState } from 'react';
import { getGetTherapist, validateAdmin } from '../utils/firebase';

import { hasValidPermissions } from '../utils/helpers';
import { ROLES } from '../utils/constants';
import { forceRefreshClaims } from '../utils/firebase/permissionsUtils';
import * as CloudFunctions from 'utils/firebase/cloudFunctions';
import firebase from 'firebase/compat/app';

// @types
import { ActionMap, AuthState, AuthUser } from '../types/authentication';
import {
  AuthContext,
  firebaseAuth,
  IS_FIREBASE_AUTHORIZATION_EMULATOR_ENABLED
} from './FirebaseContext';
import { ProviderAccessToken } from '../types/audit-logs/log';
import { TokenResponse } from "@react-oauth/google";
import { ErrorException } from "../types/settings";

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  registeredMFA: true, // We start on true to avoid showing dialogs
  user: null,
  providerAccessToken: { uid: '', accessToken: '' }
};

enum Types {
  Initial = 'INITIALISE'
}
type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    registeredMFA: boolean;
    user: AuthUser;
    therapistId?: string;
    providerAccessToken?: ProviderAccessToken;
  };
};

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { therapistId, isAuthenticated, user, providerAccessToken, registeredMFA } = action.payload;

    const updatedAccToken = {
      uid: '',
      accessToken: ''
    };
    if (providerAccessToken?.uid && providerAccessToken?.accessToken) {
      Object.assign(updatedAccToken, providerAccessToken);
    } else if (
      !providerAccessToken?.accessToken &&
      state.providerAccessToken?.accessToken &&
      (user?.id === state.providerAccessToken?.uid ||
        user?.uid === state?.providerAccessToken?.uid ||
        (!user?.id && !user?.uid))
    ) {
      Object.assign(updatedAccToken, state.providerAccessToken);
    }

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      therapistId,
      providerAccessToken: updatedAccToken,
      registeredMFA: registeredMFA ?? false
    };
  }

  return state;
};
export function AuthProvider({ children }: { children: ReactNode }) {
  const [profile, setProfile] = useState<firebase.firestore.DocumentData | undefined>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showLoginSuccessMessage, setShowLoginSuccessMessage] = useState<boolean>(false);
  const [attemptingLogin, setAttemptingLogin] = useState<boolean>(false);

  useEffect(
    () =>
      firebaseAuth().onAuthStateChanged(async (user) => {
        try {
          if (user?.uid) {
            const { data: roles } = await validateAdmin(user?.uid)
              .then((res) => res)
              .catch((err) => {
                throw Error(err?.message || 'something went wrong while validting admin');
              });
            const therapist = await getGetTherapist(user.uid)
              .get()
              .then((snap) => snap?.docs?.[0]?.data());

            if (hasValidPermissions(roles?.usrRoles, Object.values(ROLES))) {
              // @ts-expect-error
              const userInfo = {...(user?.multiFactor?.user || user), enrolledFactors: user?.multiFactor?.enrolledFactors ?? []}
              setProfile(userInfo);

              dispatch({
                type: Types.Initial,
                payload: {
                  isAuthenticated: true,
                  user: userInfo,
                  therapistId: therapist?.id,
                  registeredMFA: userInfo?.enrolledFactors?.length > 0,
                }
              });
            } else {
              throw Error('No access');
            }
          } else {
            throw Error('unable to authorize.');
          }
        } catch (e: ErrorException) {
          console.error(e);
          setProfile(undefined);
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              registeredMFA: false,
            }
          });
        }
      }), // eslint-disable-next-line
    [dispatch, attemptingLogin, setShowLoginSuccessMessage, setAttemptingLogin]
  );

  useEffect(() => {
    let unListen = () => {};
    if (profile?.uid) {
      unListen = forceRefreshClaims(profile?.uid);
    }
    return unListen;
  }, [profile]);

  const login = (email: string, password: string) =>
    firebaseAuth().signInWithEmailAndPassword(email, password);

  const loginWithGoogle = async (tokenResponse: TokenResponse) => {
    try {
      setAttemptingLogin(true);
      /*
       * Before we trying to sign into google, check to see the user exists in
       * our DB. If we don't do this, google will create a new user and sign them
       * in automatically. They won't have permission to do anything, but still not
       * ideal.
       */
      let userExists = false;
      if (!IS_FIREBASE_AUTHORIZATION_EMULATOR_ENABLED) {
        const response = await CloudFunctions.validateGoogleUser({
          googleAccessToken: tokenResponse.access_token,
        });
        userExists = response.data.userExists || false;
      } else {
        userExists = true;
      }

      if (!userExists) {
        setAttemptingLogin(false);

        return {
          additionalUserInfo: null,
          credential: null,
          operationType: null,
          user: null
        };
      }

      let credential = null;
      if (IS_FIREBASE_AUTHORIZATION_EMULATOR_ENABLED) {
        credential = firebase.auth.GoogleAuthProvider.credential(
          '{"sub": "abc123", "email": "foo@example.com", "email_verified": true}'
        );
      } else {
        credential = firebase.auth.GoogleAuthProvider.credential(
          null,
          tokenResponse.access_token
        );
      }

      await firebaseAuth().setPersistence(
          firebase.auth.Auth.Persistence.SESSION
      );

      const loginRes = await firebaseAuth().signInWithCredential(credential);

      const accToken: string | undefined =
        // @ts-ignore
        loginRes?.credential?.accessToken ??
        // @ts-ignore
        loginRes.credential?.toJSON()?.oauthAccessToken ??
        undefined;

      dispatch({
        type: Types.Initial,
        payload: {
          ...(state || {}),
          providerAccessToken: {
            uid: loginRes?.user?.uid ?? '',
            accessToken: accToken ?? '',
            //@ts-ignore
            apiKey: loginRes.user?.toJSON()?.apiKey
          }
        }
      });

      return loginRes;
    } catch (e: ErrorException) {
      console.error(e);

      setAttemptingLogin(false);

      let payload: FirebaseAuthPayload[Types.Initial] = {
        ...(state || {})
      };

      dispatch({
        type: Types.Initial,
        payload: payload
      });

      throw e;
    }
  };

  const loginWithFaceBook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebaseAuth().signInWithPopup(provider);
  };

  const loginWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebaseAuth().signInWithPopup(provider);
  };

  const register = (email: string, password: string, firstName: string, lastName: string) =>
    firebaseAuth().createUserWithEmailAndPassword(email, password).then((res) => {
      firebase
        .firestore()
        .collection('users')
        .doc(res.user?.uid)
        .set({
          uid: res.user?.uid,
          email,
          displayName: `${firstName} ${lastName}`
        });
    });

  const logout = async () => {
    await firebaseAuth().signOut();
  };

  const resetPassword = async (email: string) => {
    await firebaseAuth().sendPasswordResetEmail(email);
  };

  // @ts-ignore
  const updateProfile = async (user: AuthUser) => {
    // await firebaseAuth().updateCurrentUser(user);
    // return true;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        registeredMFA: state.registeredMFA,
        method: 'firebase',
        user: { ...(profile || {}) },
        login,
        register,
        loginWithGoogle,
        loginWithFaceBook,
        loginWithTwitter,
        logout,
        resetPassword,
        updateProfile,
        showLoginSuccessMessage,
        attemptingLogin,
        setAttemptingLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
