import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";

// material
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Card,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import firebase from 'firebase/compat/app'; 
import InputLabel from "@mui/material/InputLabel";
import { ChineseUserRelationship, addOrUpdateRelationship } from "utils/firebase/chineseUsersRelationshipsUtils";
import { PATH_DASHBOARD } from "routes/paths";
import { useDispatch } from "react-redux";
import { getUserList } from "../../../../redux/slices/user";
import { useGetAllTherapists } from "hooks/useGetAllTherapists";
import { ErrorException } from "../../../../types/settings";

// ----------------------------------------------------------------------

type RelationshipsFormProps = {
  isEdit: boolean;
  isNew?: boolean;
  relationshipData?: ChineseUserRelationship;
};

enum RelationshipStatus {
    STARTED = "Started",
    FINISHED = "Finished",
}

const RELATIONSHIPS_STATUS_OPTIONS = [
    RelationshipStatus.STARTED,
    RelationshipStatus.FINISHED,
];

export default function RelationshipsForm({
  isNew,
  isEdit,
  relationshipData,
}: RelationshipsFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { therapists } = useGetAllTherapists();

  useEffect(() => {
    // @ts-ignore Something is wrong with creating the thunk here...
    dispatch(getUserList());
  }, [dispatch]);


  const RelationshipSchema = Yup.object().shape({
    userEmail: Yup.string().required("The User Email is required"),
    counsellor: Yup.object().shape({
      name: Yup.string(),
      id: Yup.string(),
    }),
    status: Yup.string().optional(),
    invitationCode: Yup.string().required(),
    id: Yup.string().optional(),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      userEmail: relationshipData?.userEmail ?? "",
      counsellor: relationshipData?.counsellor ?? { name: "", id: ""},
      status: relationshipData?.status ?? "",
      invitationCode: relationshipData?.invitationCode ?? "",
      id: relationshipData?.id ?? "",
    },

    validationSchema: RelationshipSchema,
    onSubmit: async (values) => {
      try {
        const { userEmail, counsellor, status, invitationCode, id } = values;
        const newRelationshipPayload: Partial<ChineseUserRelationship> = {
            userEmail,
            counsellor,
            status,
            invitationCode,
            id,
        };

        if (isNew) {
            newRelationshipPayload.createdAt =
              firebase.firestore.Timestamp.fromDate(new Date());
        }

        await addOrUpdateRelationship(newRelationshipPayload as ChineseUserRelationship);

        enqueueSnackbar("Saved successfully!", { variant: "success" });

        navigate(PATH_DASHBOARD.therapist.chineseUserRelationships.list);
            
      } catch (e: ErrorException) {
        console.error(e);
        enqueueSnackbar("Failed to save", { variant: "error" });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  const submitBtnTitle = isEdit ? "Save Changes in the Relationship" : "Create Relationship";
  
  const autocompleteOptions = therapists.map((therapist) => {
    return {
      value: therapist.id,
      label: therapist.name ?? "",
    };
  });

const handleCounsellorChange = (value: Record<string, string> | null) => {
    if (value) {
        setFieldValue("counsellor.id", value.value);
        setFieldValue("counsellor.name", value.label);
    } else {
        setFieldValue("counsellor.id", "");
        setFieldValue("counsellor.name", "");
    }
}

  return (
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <fieldset style={{ border: "none" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                        <TextField
                            fullWidth
                            label="User Email"
                            {...getFieldProps("userEmail")}
                            error={Boolean(touched.userEmail && errors.userEmail)}
                            helperText={touched.userEmail && errors.userEmail}
                        />

                        <Autocomplete 
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            autoSelect
                            value={{
                                value: getFieldProps("counsellor.id").value,
                                label: getFieldProps("counsellor.name").value,
                            }}
                            disablePortal
                            options={autocompleteOptions}
                            sx={{ width: 500, marginBottom: 5 }}
                            renderInput={(params) => <TextField {...params} label="Counsellor" />}
                            onChange={(e, value) => handleCounsellorChange(value)}
                            loading={!therapists || therapists.length === 0}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props} key={option.value}>
                                    {option.label}
                                </Box>
                            )
                            }
                            
                        />

                        <FormControl fullWidth>
                            <InputLabel id="status-label">
                                Status
                            </InputLabel>
                            <Select
                            labelId="status-label"
                            id="status"
                            label={"Status"}
                            defaultValue={RelationshipStatus.STARTED}
                            {...getFieldProps("status")}
                            >
                            {RELATIONSHIPS_STATUS_OPTIONS?.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            label="Invitation Code"
                            {...getFieldProps("invitationCode")}
                            error={Boolean(touched.invitationCode && errors.invitationCode)}
                            helperText={touched.invitationCode && errors.invitationCode}
                        />

                        <Box
                            sx={{
                            mt: 3,
                            display: "flex",
                            justifyContent: "flex-end",
                            }}
                        >
                            <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            >
                            {submitBtnTitle}
                            </LoadingButton>
                        </Box>
                    </Stack>
                </Card>
              </Grid>
            </Grid>
          </fieldset>
        </Form>
      </FormikProvider>
  );
}
