import { Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { downloadPdfInvoice } from "utils/firebase/cloudFunctions";
import { ErrorException } from "../../types/settings";

type Props = {
  counsellorId: string;
  invoiceId: string;
};

function DownloadPdfInvoiceButton({ counsellorId, invoiceId }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const handleDownload = async () => {
    try {
      const invoice = await downloadPdfInvoice({
        counsellorId: counsellorId,
        invoiceId: invoiceId,
      });
      const {data: {filename, content, type, encoding}} = invoice;
      const url = `data:${type};${encoding},${content}`
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      enqueueSnackbar("Downloading invoice", { variant: "success" });
    } catch (error: ErrorException) {
      console.error(error);
      enqueueSnackbar("Download failed", { variant: "error" });
    }
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        variant="contained"
        onClick={handleDownload}
        style={{
          marginBottom: 20,
        }}
      >
        Download pdf
      </Button>
    </Box>
  );
}

export default DownloadPdfInvoiceButton;
