import { createContext } from 'react';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";


// @types
import { FirebaseContextType } from '../types/authentication';
//
import { firebaseConfig } from '../config';
import { parsePort } from 'utils/firebase/emulatorUtils';

// ----------------------------------------------------------------------

// const ADMIN_EMAILS = ['admin@kyanhealth.com'];

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  const app = firebase.initializeApp(firebaseConfig);

  if (process.env.REACT_APP_RECAPTCHA_SITE_KEY) {
    // eslint-disable-next-line no-restricted-globals
    self.FIREBASE_APPCHECK_DEBUG_TOKEN =
      process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
    try {
      initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider(
          process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""
        ),
        isTokenAutoRefreshEnabled: true,
      });
    } catch (error) {
      console.log(error);
    }
  } else {
    console.error(
      "🛑 Unable to initialize App Check: ReCAPTCHA site key not set."
    );
  }

  firebase.firestore().settings({ experimentalForceLongPolling: true });
  firebase.firestore();
}

export let IS_FIREBASE_AUTHORIZATION_EMULATOR_ENABLED = false;

/*
 * Be careful with this. There's a difference between auth and auth(). auth is
 * a namespace with various properties and methods, auth() is an instance of
 * the auth service.
 *
 * Check the docs if you're not sure which to use:
 * - auth https://firebase.google.com/docs/reference/js/v8#firebase.auth
 * - auth() https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth
 */
export const firebaseAuth = firebase.auth;
const emulators = process.env.REACT_APP_EMULATORS || '';
if (emulators.indexOf('all') > -1 || emulators.indexOf('auth') > -1) {
  IS_FIREBASE_AUTHORIZATION_EMULATOR_ENABLED = true;
  const emulatorPort = parsePort(process.env.REACT_APP_AUTH_EMULATOR_PORT || '9099', 9099);
  firebaseAuth().useEmulator(`http://localhost:${emulatorPort}`);
}

export const db = firebase.firestore();
if (emulators.indexOf('all') > -1 || emulators.indexOf('firestore') > -1) {
  const emulatorPort = parsePort(process.env.REACT_APP_FIRESTORE_EMULATOR_PORT || '8080', 8080);
  db.useEmulator('localhost', emulatorPort);
}
export const storage = firebase.storage();

const [firebaseApp] = firebase.apps;
export const firebaseFunctions = firebaseApp.functions('europe-west3');
if (emulators.indexOf('all') > -1 || emulators.indexOf('functions') > -1) {
  const emulatorPort = parsePort(process.env.REACT_APP_FUNCTIONS_EMULATOR_PORT || '5001', 5001);
  firebaseFunctions.useEmulator('localhost', emulatorPort);
}

export const AuthContext = createContext<FirebaseContextType | null>(null);
