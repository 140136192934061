import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import closeFill from "@iconify/icons-eva/close-fill";
import { MIconButton } from "./@material-extend";
import useAuth from "../hooks/useAuth";
import { Alert, AlertTitle, Button, Box, Typography } from "@mui/material";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import googleFill from "@iconify/icons-eva/google-fill";
import firebase from "firebase/compat/app";
import { ErrorException } from "../types/settings";

// ----------------------------------------------------------------------

function errorHandler(error: unknown) {
  console.error("An error occurred using Google Sign-In", error);
}

interface Props {
  auditLogsError?: any;
  auditLogs?: boolean;
  onMfa?: (resolver: firebase.auth.MultiFactorResolver) => void;
}

const AuditLogsScopes = [
  "https://www.googleapis.com/auth/cloud-platform.read-only",
  "https://www.googleapis.com/auth/logging.read",
  "https://www.googleapis.com/auth/logging.admin",
  "https://www.googleapis.com/auth/cloud-platform",
];

export default function GoogleSignInButton(props: Props) {
  const { loginWithGoogle, attemptingLogin } = useAuth();

  const { auditLogsError, auditLogs, onMfa } = props ?? {};
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Regular users can use this login
  const regularLogin = useGoogleLogin({
    flow: "implicit",
    onSuccess: onGoogleSignInSuccess,
    onError: errorHandler,
  });

  // If a user wants to check Audit logs then they need to provide additional
  // permissions.
  const loginWithAuditLogPermission = useGoogleLogin({
    flow: "implicit",
    onSuccess: onGoogleSignInSuccess,
    onError: errorHandler,
    scope: AuditLogsScopes.join(" "),
  });

  async function onGoogleSignInSuccess(responseGoogle: TokenResponse) {
    enqueueSnackbar("Logging in", {
      variant: "success",
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      ),
    });

    try {
      const loginRes = await loginWithGoogle(responseGoogle);

      if (!loginRes?.user?.uid) {
        enqueueSnackbar("Login failed.", {
          variant: "error",
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        });
      }
    } catch (error: ErrorException) {
      const isMFARequired = error.code === "auth/multi-factor-auth-required";
      if (isMFARequired) {
        onMfa?.(error.resolver);
        return;
      }
    }
  }

  if (auditLogs) {
    return (
      <Alert severity="error" onClick={() => loginWithAuditLogPermission()}>
        <AlertTitle>
          {auditLogsError?.message ?? "Viewing logs requires access token"}
        </AlertTitle>
        <Typography>
          <br />
          Click here to login using google & fetch the required token.
          <br />
          <br />
          Note: Kindly make sure to use the same google account, you've used to
          login. otherwise, both profiles will be merged.
        </Typography>
      </Alert>
    );
  }

  return (
    <Button
      sx={{ flexDirection: "row", my: 2 }}
      variant="contained"
      size={"large"}
      onClick={() => regularLogin()}
      disabled={attemptingLogin}
    >
      Login with google
      <Box
        sx={{
          padding: "8px 16px",
          display: "flex",
        }}
      >
        <Icon icon={googleFill} width={25} height={25} color="#fff" />
      </Box>
    </Button>
  );
}
