import React from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import firebase from 'firebase/compat/app'; 
import { ChineseUserRelationship, RelationshipSession, addOrUpdateSession } from "utils/firebase/chineseUsersRelationshipsUtils";
import { PATH_DASHBOARD } from "routes/paths";
import {DateTimePicker} from '@mui/lab';
import { VideoCallStatus } from "types/chat";
import { getAttendStatus } from "utils/videoCallsUtils";
import { ErrorException } from "../../../../types/settings";

// ----------------------------------------------------------------------

type SessionsFormProps = {
  isEdit: boolean;
  relationshipData?: ChineseUserRelationship;
  sessionData?: RelationshipSession;
};

const SESSION_STATUS_OPTIONS = Object.values(VideoCallStatus)


export default function SessionsForm({
  isEdit,
  relationshipData,
  sessionData,
}: SessionsFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const SessionSchema = Yup.object().shape({
    date: Yup.string().required("Date is required"),
    status: Yup.string().optional(),
    id: Yup.string().optional(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: sessionData?.date.toDate() ?? "",
      status: sessionData?.status ?? "",
      id: sessionData?.id ?? "",
    },

    validationSchema: SessionSchema,
    onSubmit: async (values) => {
      try {
        const { date, status = VideoCallStatus.SCHEDULED, id } = values;
        const newSessionPayload: RelationshipSession = {
            date: firebase.firestore.Timestamp.fromDate(new Date(date.toString())),
            status,
            id,
        };

        if(!relationshipData?.id){
            enqueueSnackbar("Failed to save", { variant: "error" });
            return;
        }

        await  addOrUpdateSession(newSessionPayload, relationshipData?.id);

        enqueueSnackbar("Saved successfully!", { variant: "success" });

        navigate(PATH_DASHBOARD.therapist.chineseUserRelationships.list);
            
      } catch (e: ErrorException) {
        console.error(e);
        enqueueSnackbar("Failed to save", { variant: "error" });
      }
    },
  });

  const {
    handleSubmit,
    isSubmitting,
    getFieldProps,
  } = formik;

  const submitBtnTitle = isEdit ? "Save Changes in the Session" : "Create Session";
  return (
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <fieldset style={{ border: "none" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                        <Box>
                            <Typography >{`User Email: ${relationshipData?.userEmail}`}</Typography>
                            <Typography >{`Counsellor: ${relationshipData?.counsellor.name}`}</Typography>
                            <Typography >{`Relationship Status: ${relationshipData?.status}`}</Typography>
                        </Box>

                        <DateTimePicker
                            label="Select Date"
                            renderInput={(params) => <TextField {...params} />}
                            inputFormat={'hh:mm aa dd.MM.yyyy'}
                            mask="__:__ __ __.__.____"
                            value={getFieldProps("date").value}
                            onChange={(date) => formik.setFieldValue("date", date)}
                        />

                        <FormControl fullWidth>
                            <InputLabel id="status-label">
                                Status
                            </InputLabel>
                            <Select
                            labelId="status-label"
                            id="status"
                            label={"Status"}
                            defaultValue={VideoCallStatus.SCHEDULED}
                            {...getFieldProps("status")}
                            >
                            {SESSION_STATUS_OPTIONS?.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {getAttendStatus(option)}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        <Box
                            sx={{
                            mt: 3,
                            display: "flex",
                            justifyContent: "flex-end",
                            }}
                        >
                            <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            >
                            {submitBtnTitle}
                            </LoadingButton>
                        </Box>
                    </Stack>
                </Card>
              </Grid>
            </Grid>
          </fieldset>
        </Form>
      </FormikProvider>
  );
}
