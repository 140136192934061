import firebase from "firebase/compat/app";
import { storage } from "contexts/FirebaseContext";

export async function uploadSVGImage(
    file: File,
    name: string,
    colorPalette: string
  ): Promise<firebase.storage.UploadTaskSnapshot> {
    const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
    const fileName = `${formattedName}-${colorPalette}.svg`;
    return storage
      .refFromURL("gs://kyan-svg-images")
      .child(fileName)
      .put(file)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.error(error);
        throw new Error("Image upload failed");
      });
  }
