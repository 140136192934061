// material
import {
  Container,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

// hooks
import useSettings from "../../../hooks/useSettings";

// components
import Page from "../../../components/Page";
import * as CloudFunctions from 'utils/firebase/cloudFunctions';

// ----------------------------------------------------------------------
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import React, { useEffect, useMemo, useState } from "react";
import TableHeader from "../../../components/common/TableHeader";
import {
  useTranslationsKeyWithDescriptionByLocale
} from "../../../utils/firebase/translationUtils";
import { useCurrentRole } from "../../../hooks/useCurrentRole";
import { useTheme } from "@mui/material/styles";
import LoadingScreen from "../../../components/LoadingScreen";
import useAuth from "../../../hooks/useAuth";
import SearchNotFound from "../../../components/SearchNotFound";
import { RootState, useSelector } from "../../../redux/store";
import { useGetAllMultilingualLocales } from "utils/firebase/multilingualLocaleUtils";
import { TranslationsFormQuickEdit } from "../../../components/_dashboard/app-side-translations/TranslationsFormQuickEdit";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { LANGUAGE_CODE_TO_NAMES } from "../../../utils/constants";
import { Hits, InstantSearch } from "react-instantsearch-dom";
import { SearchClient } from "algoliasearch/dist/algoliasearch";
import algoliasearch from "algoliasearch";
import { algoliaConfig } from "../../../config";
import { useAlgoliaSearchKey } from "../../../hooks/algolia/useAlgoliaSearchKey";
import QuickEditTranslationToolbar from "./QuickEditTranslationToolbar";
import { AppSideTranslations } from "../../../types/translations";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { ErrorException } from "../../../types/settings";

// ----------------------------------------------------------------------

export enum TranslationSearchType {
  VALUE = "value",
  APPROVED_BY = "approvedBy",
  PRIORITY = "priority",
}
// preventing the undesirable behavior of returning to the first page of a list
// when a user navigates back from a details page or any other update page.
// The code accomplishes this by persisting the state information related to the page number, page size, snapshot, order by, and order of the audio meditations.
// By doing so, it ensures that the user remains on the same page within the list, maintaining a seamless and consistent
// navigation experience even after interacting with details or update pages.
let AppSideTranslationPersistState = {
  pageNo: 0,
  pageSize: 5,
  snapShot: {},
};

const TABLE_HEAD = [
  { id: "key", label: "Key", alignRight: false },
  { id: "value", label: "Value", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "priority", label: "Priority", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "updatedAt", label: "Updated At", alignRight: false },
  { id: "approvedBy", label: "Approved", alignRight: false },
  { id: "actions", label: "Actions", alignRight: false },
];

export enum TranslationStatus {
  APPROVED = "Approved",
  UNAPPROVED = "Unapproved",
  ALL = "All",
  EMPTY_DESCRIPTION = "Empty Description",
  CRITICAL = "Critical",
  HIGH = "High",
  MEDIUM = "Medium",
  LOW = "Low",
}

export const PRIORITY_OPTIONS = [
  TranslationStatus.CRITICAL,
  TranslationStatus.HIGH,
  TranslationStatus.MEDIUM,
  TranslationStatus.LOW,
];

type AppSideTranslationQuickEditProps = {
  locale: string;
};

export default function AppSideTranslationQuickEdit(
  props: AppSideTranslationQuickEditProps
) {
  const [searchClient, setSearchClient] = useState<SearchClient>();

  const algoliaSearchApiKey = useAlgoliaSearchKey();
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const { locale } = props;

  const { user: loggedInUser } = useAuth();

  const isKyanUser = loggedInUser && loggedInUser.email.includes("@kyanhealth.com");

  
  useEffect(() => {
    if (algoliaSearchApiKey) {
      setSearchClient(
        algoliasearch(algoliaConfig?.appId || "", algoliaSearchApiKey)
      );
    }
  }, [algoliaSearchApiKey]);

  const [approvalStatus, setApprovalStatus] = React.useState(TranslationStatus.ALL);
  const [isEmptyDescription, setIsEmptyDescription] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [translationPriority, setTranslationPriority] = React.useState(TranslationStatus.ALL);
  const [filterName, setFilterName] = useState("");
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [page, setPage] = useState(AppSideTranslationPersistState.pageNo);
  const [searchText, setSearchText] = useState("");
  const [searchBy, setSearchBy] = useState(TranslationSearchType.VALUE);
  const [rowsPerPage, setRowsPerPage] = useState(
    AppSideTranslationPersistState.pageSize
  );
  const [translationStatus, setTranslationStatus] = useState<TranslationStatus>(
    TranslationStatus.ALL
  );

  const currentRoles = useCurrentRole();
  const { enqueueSnackbar } = useSnackbar();

  const {
    translationsKeyWithDesc,
    loading,
    isMoreTranslationsAvailable,
    lastSnapShot,
  } = useTranslationsKeyWithDescriptionByLocale(
    locale,
    page,
    rowsPerPage,
    searchText,
    searchBy,
    AppSideTranslationPersistState.snapShot,
    order,
    orderBy,
    approvalStatus,
    translationPriority,
    isEmptyDescription,
    translationStatus,
  );
  const translationsVersion = useSelector(
    (state: RootState) => state.contentVersions.versions.translationsVersion
  );
  const { multilingualLocalesCodeToName } = useGetAllMultilingualLocales();
  const TRANSLATION_LANGUAGE_CODE_TO_NAME: Record<string, string> = useMemo(
    () => ({
      en: "English",
      es: "Spanish",
      fr: "French",
      de: "German",
      ...multilingualLocalesCodeToName,
    }),
    [multilingualLocalesCodeToName]
  );

  useEffect(() => {
    return () => {
      AppSideTranslationPersistState = {
        pageNo: page,
        pageSize: rowsPerPage,
        snapShot: lastSnapShot,
      };
    };
  }, [page, rowsPerPage, lastSnapShot]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onPriorityStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTranslationPriority((event?.target?.value ?? "") as TranslationStatus);
    setPage(0);
  };

  const onApprovalStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setApprovalStatus((event?.target?.value ?? "") as TranslationStatus);
    setPage(0);
  };

  const handleFilterByName = (newFilter: string) => {
    setFilterName(newFilter);
    if (!newFilter.length) {
      setPage(0);
      setSearchText(newFilter);
    }
  };

  const handleRequestSort = (property: string) => {
    if (property !== "createdAt" && property !== "key") {
      return;
    }
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterKeyDown =
    (hits: Hits<AppSideTranslations> | any) =>
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        setSearchBy(TranslationSearchType.VALUE);
        setPage(0);
        setSearchText(
          hits.map((hit: AppSideTranslations) => hit.key).join("|") ||
            filterName
        );
      }
    };

  const isGroupNotFound = !Boolean(translationsKeyWithDesc.length) && !loading;
  const heading = `Translations in ${
    LANGUAGE_CODE_TO_NAMES?.[locale.toUpperCase()] || locale.toUpperCase()
  } `;

  if (loading || !searchClient) {
    return <LoadingScreen />;
  }

  function handleStatusChange(value: string) {
    setSearchBy(TranslationSearchType.APPROVED_BY);
    setTranslationStatus(value as TranslationStatus);
    setPage(0);
  }

  const renderQuickEditTranslationToolbar = () => {
    return (
      <QuickEditTranslationToolbar
        selectedOptionValue={translationStatus || TranslationStatus.ALL}
        numSelected={0}
        showSearchBar
        filterName={filterName}
        translationPriority={translationPriority}
        approvalStatus={approvalStatus}
        setIsEmptyDescription={setIsEmptyDescription}
        isEmptyDescription={isEmptyDescription}
        handleKeyDown={handleFilterKeyDown}
        onFilterName={handleFilterByName}
        onApprovalStatusChange={onApprovalStatusChange}
        onPriorityStatusChange={onPriorityStatusChange}
        searchPlaceholder={"Search Translation By Value"}
        options={[
                TranslationStatus.ALL,
                TranslationStatus.APPROVED,
                TranslationStatus.UNAPPROVED,
                TranslationStatus.EMPTY_DESCRIPTION,
                TranslationStatus.CRITICAL,
                TranslationStatus.HIGH,
                TranslationStatus.MEDIUM,
                TranslationStatus.LOW,
              ]
        }
        onSelectOptionValue={handleStatusChange}
      />
    );
  };

  const onDownloadTranslatedCSVFile = (translationPublicUrl: string) => {
    // Create an <a> element with necessary attributes
    const a = document.createElement('a');
    a.href = translationPublicUrl;
    a.target = "_blank"
    a.download = `${ locale ?? 'translate' }.csv`;

    // Dispatch a click event on the <a> element
    a.click();

    // Clean up by revoking the object URL
    URL.revokeObjectURL(a.href);
  }

  async function generateCSVAndSendEmail() {
    try {
      setIsDownloading(true);
      enqueueSnackbar("Please Wait! The translation will be completed and sent to your email shortly")
      const response = await CloudFunctions.getAllLanguageTranslationsInSpecificLocale({
        locale,
      })
      if (!response?.data) {
        throw new Error("Failed to generate CSV and send email");
      }
      onDownloadTranslatedCSVFile(response?.data)
      enqueueSnackbar("CSV has been generated and the email has been sent successfully.", { variant: "success" })
    } catch (e: ErrorException) {
      console.error("Error in Processing CSV file", e);
      enqueueSnackbar("Failed to generate CSV and send email", { variant: "error" })
    } finally {
      setIsDownloading(false);
    }
  }


  return (
    <Page title="App side translations">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <InstantSearch indexName="app_translations" searchClient={searchClient}>

          {
            isKyanUser &&
              <HeaderBreadcrumbs
                heading={heading}
                links={[]}
                action={
                  <LoadingButton
                    variant="contained"
                    sx={{ ml: 3, color: "white" }}
                    loading={isDownloading}
                    disabled={isDownloading}
                    onClick={generateCSVAndSendEmail}
                  >
                  Export CSV
                  </LoadingButton>
                }
              />
          }
          
          {renderQuickEditTranslationToolbar()}

          <TableContainer component={Paper}>
            <Table
              aria-label="collapsible table"
              key={"appSideTranslationsCollapsibleTable"}
            >
              <TableHeader
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={translationsKeyWithDesc.length}
                numSelected={0}
                onRequestSort={handleRequestSort}
                hideCheckbox
              />

              <TableBody>
                {translationsKeyWithDesc.map((row) => (
                  <TranslationsFormQuickEdit
                    key={
                      String(row.createdAt ?? "") +
                      String(row.translationId ?? "")
                    }
                    row={row}
                    theme={theme}
                    currentRoles={currentRoles}
                    uid={loggedInUser?.uid ?? ""}
                    translationsCodeToName={TRANSLATION_LANGUAGE_CODE_TO_NAME}
                  />
                ))}

                {isGroupNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {!Boolean(searchText) && (
            <TablePagination
              rowsPerPageOptions={[2, 5, 10]}
              component="div"
              rowsPerPage={rowsPerPage}
              count={
                isMoreTranslationsAvailable
                  ? -1
                  : rowsPerPage * page + translationsKeyWithDesc.length
              }
              page={page}
              onPageChange={(e, newPage) => setPage(newPage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}

          <Typography textAlign={"center"} mt={1}>
            Version {translationsVersion}
          </Typography>
        </InstantSearch>
      </Container>
    </Page>
  );
}
