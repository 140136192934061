import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import { SelectMFAType } from "../../components/SelectMFAType";
import { UnEnrollMfaAuthenticationDialog } from "../../components/UnenrollMfaAuthenticationDialog";
import { MfaType } from "../../utils/constants";
import { ErrorException } from "../../types/settings";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // {
  //   label: 'Profile',
  //   icon: personFill,
  //   linkTo: PATH_DASHBOARD.user.profile
  // },
  {
    label: "Settings",
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.user.account,
  },
  {
    label: "Enable 2FA",
    icon: settings2Fill,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [mfaDialogOpen, setMfaDialogOpen] = useState<boolean>(false);
  const [showUnEnrollMfaDialog, setShowUnEnrollMfaDialog] =
    useState<boolean>(false);
  const [mfaType, setMfaType] = useState<MfaType>(MfaType.PHONE);
  const [open, setOpen] = useState(false);

  const handleMfaDialog = () => {
    setMfaDialogOpen((prev) => !prev);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onClickMenuItem = () => {
    handleClose();
    handleMfaDialog();
  }

  const closeMfaModel = () => {
    setMfaDialogOpen(false);
    setShowUnEnrollMfaDialog(false);
  }

  const handleMFaEnrollOrUnEnroll = (selectedMFAType: MfaType) => {
    setShowUnEnrollMfaDialog(true);
    setMfaType(selectedMFAType);
  }

  const handleLogout = async () => {
    try {
      await logout?.();
      if (isMountedRef.current) {
        navigate('/');
        handleClose();
      }
    } catch (error: ErrorException) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <MIconButton
        ref={ anchorRef }
        onClick={ handleOpen }
        sx={ {
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        } }
      >
        <MyAvatar/>
      </MIconButton>

      <MenuPopover
        open={ open }
        onClose={ handleClose }
        anchorEl={ anchorRef.current }
        sx={ { width: 220 } }
      >
        <Box sx={ { my: 1.5, px: 2.5 } }>
          <Typography variant="subtitle1" noWrap>
            { user?.displayName }
          </Typography>
          <Typography variant="body2" sx={ { color: 'text.secondary' } } noWrap>
            { user?.email }
          </Typography>
        </Box>

        <Divider sx={ { my: 1 } }/>

        { MENU_OPTIONS.map((option) => {
          if (!option.linkTo) {
            return <Box sx={ { p: 1, alignSelf: "center" } }>
              <Button color="inherit" variant="text" onClick={ onClickMenuItem } sx={ { fontWeight: "normal" } }>
                <Box
                  component={ Icon }
                  icon={ option.icon }
                  sx={ {
                    mr: 2,
                    pl: 0.7,
                    width: 28,
                    height: 28
                  } }
                />

                { option.label }
              </Button>
            </Box>
          }

          return (
            <MenuItem
              key={ option.label }
              to={ option.linkTo }
              component={ RouterLink }
              onClick={ handleClose }
              sx={ { typography: 'body2', py: 1, px: 2.5 } }
            >
              <Box
                component={ Icon }
                icon={ option.icon }
                sx={ {
                  mr: 2,
                  width: 24,
                  height: 24
                } }
              />

              { option.label }
            </MenuItem>
          );
        }) }

        <Box sx={ { p: 2, pt: 1.5 } }>
          <Button fullWidth color="inherit" variant="outlined" onClick={ handleLogout }>
            Logout
          </Button>
        </Box>
      </MenuPopover>

      { mfaDialogOpen && (
        <SelectMFAType
          open={ mfaDialogOpen }
          onClose={ handleMfaDialog }
          setShowUnEnrollMfa={ handleMFaEnrollOrUnEnroll }
        />
      ) }

      <UnEnrollMfaAuthenticationDialog
        open={ showUnEnrollMfaDialog }
        onClose={ () => setShowUnEnrollMfaDialog(false) }
        mfaType={ mfaType }
        hideMfaModel={ closeMfaModel }
      />
    </>
  );
}
