import React, {useEffect, useRef, useState} from 'react';
import firebase from "firebase/compat/app";
import {
  TotpMultiFactorGenerator,
  PhoneMultiFactorGenerator,
  MultiFactorResolver,
} from "firebase/auth";

// material
import { styled } from '@mui/material/styles';
import { Box, Card, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import GoogleSignInButton from '../../components/GoogleSignInButton';
import LoadingScreen from '../../components/LoadingScreen';
import useAuth from '../../hooks/useAuth';
import { useSnackbar } from 'notistack';
import { MIconButton } from '../../components/@material-extend';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import LogoText from 'components/LogoText';
import LogoWithSpeechBubble from 'components/_dashboard/LogoWithSpeechBubble';
import {MFALogin} from "../../components/authentication/login/MFALogin";
import { TOTPLogin } from "../../components/authentication/login/TOTPLogin";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 380,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'visible',
  margin: theme.spacing(2, 0, 2, 2),
  background: theme.palette.background.neutral
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const LoadingIconContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  background: 'rgba(255,255,255,0.4)',
  zIndex: 2
}));

const LogoContainer = styled('div')(({ theme }) => ({
  padding: '42px 42px 0 42px'
}));

// ----------------------------------------------------------------------

enum Mode {
  MFA = "mfa",
  LOG_IN = "login",
}

export default function Login() {
  const { attemptingLogin, showLoginSuccessMessage } = useAuth();
  const [mode, setMode] = useState<Mode>(Mode.LOG_IN);
  const mfaResolver = useRef<MultiFactorResolver | firebase.auth.MultiFactorResolver | undefined>();
  const [selectedMFA, setSelectedMFA] = useState<string | null>(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (mode === "mfa") {
      setSelectedMFA(mfaResolver.current?.hints?.[0]?.factorId ?? null);
    }
  }, [mode]);

  useEffect(() => {
    if (showLoginSuccessMessage) {
      enqueueSnackbar('Login succesful!', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  }, [showLoginSuccessMessage, enqueueSnackbar, closeSnackbar]);

  const getSelectedMFAIndex = () => {
    return mfaResolver.current?.hints?.findIndex(
      (mfaVal) => mfaVal.factorId === selectedMFA
    );
  };

  if (mode === "mfa" && selectedMFA === PhoneMultiFactorGenerator.FACTOR_ID) {
    return (
      <MFALogin
        resolver={mfaResolver.current as firebase.auth.MultiFactorResolver}
        selectedIndex={getSelectedMFAIndex() ?? 0}
      />
    );
  }

  if (mode === "mfa" && selectedMFA === TotpMultiFactorGenerator.FACTOR_ID) {
    return (
      <TOTPLogin
        mfaResolver={mfaResolver.current as MultiFactorResolver}
        selectedIndex={getSelectedMFAIndex() ?? 0}
      />
    );
  }

  return (
    <RootStyle title="Kyan Login">
      {attemptingLogin && (
        <LoadingIconContainer>
          <LoadingScreen sx={{ backgroundColor: 'transparent' }} />
        </LoadingIconContainer>
      )}

      <MHidden width="mdDown">
        <SectionStyle>
          <LogoContainer>
            <LogoText />
          </LogoContainer>

          <LogoWithSpeechBubble text={'Welcome back!'} />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Sign in
              </Typography>
            </Box>
          </Stack>

          <GoogleSignInButton onMfa={(resolver: firebase.auth.MultiFactorResolver) => {
            mfaResolver.current = resolver;
            setMode(Mode.MFA);
          }} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
