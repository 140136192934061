import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  PhoneMultiFactorGenerator,
  TotpMultiFactorGenerator,
} from "firebase/auth";

import { useNavigate } from "react-router-dom";
import { MfaType } from "../utils/constants";
import LoadingScreen from "./LoadingScreen";
import useAuth from "../hooks/useAuth";
import { PATH_DASHBOARD } from "../routes/paths";

type SelectMFATypeProps = {
  open: boolean;
  onClose: () => void;
  setShowUnEnrollMfa: (mfaType: MfaType) => void;
};

export const SelectMFAType = ({
                                open,
                                onClose,
                                setShowUnEnrollMfa,
                              }: SelectMFATypeProps) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const enrolledFactors = useMemo(
    () => user?.enrolledFactors ?? [],
    [user?.enrolledFactors]
  );
  const [loading, setLoading] = useState(true);
  const [isTotpMfaEnrolled, setIsTotpMfaEnrolled] = useState(false);
  const [isPhoneMfaEnrolled, setIsPhoneMfaEnrolled] = useState(false);

  const totpButtonText = isTotpMfaEnrolled ? "UnEnroll" : "Enroll";

  useEffect(() => {
    async function getUserData() {
      const currentEnrolledFactorIds = [];
      for (const enrolledFactor of enrolledFactors) {
        currentEnrolledFactorIds.push(enrolledFactor?.factorId);
      }

      setIsTotpMfaEnrolled(
        currentEnrolledFactorIds.includes(TotpMultiFactorGenerator.FACTOR_ID)
      );
      setIsPhoneMfaEnrolled(
        currentEnrolledFactorIds.includes(PhoneMultiFactorGenerator.FACTOR_ID)
      );
      setLoading(false);
    }

    getUserData();
  }, [enrolledFactors]);

  const onTotpMfa = async () => {
    if (totpButtonText === "Enroll") {
      navigate(PATH_DASHBOARD.accountTOTP);
      return;
    }

    setShowUnEnrollMfa(MfaType.TOTP);
  };

  const renderLoading = () => {
    return (
      <Box sx={ { mt: 5, mb: 5 } }>
        <LoadingScreen/>
      </Box>
    );
  };
  const renderContent = () => {
    return (
      <Box alignItems={ "flex-end" } justifyContent={ "flex-end" }>
        <Box
          display={ "flex" }
          alignItems={ "center" }
          justifyContent={ "space-between" }
          sx={ { mt: 2 } }
        >
          <Typography>TOTP Authentication (Authenticator app)</Typography>
          <Button
            disabled={ isPhoneMfaEnrolled }
            color={ isTotpMfaEnrolled ? "error" : "inherit" }
            variant="outlined"
            onClick={ onTotpMfa }
          >
            { totpButtonText }
          </Button>
        </Box>

      </Box>
    );
  };
  return (
    <Dialog fullWidth maxWidth={ "sm" } open={ open } onClose={ onClose }>
      <DialogTitle style={ { fontWeight: 700, fontSize: 24 } }>
        Enroll/UnEnroll Multi-Factor Authentication
      </DialogTitle>
      <DialogContent>
        { loading && renderLoading() }
        { !loading && renderContent() }
      </DialogContent>
    </Dialog>
  );
};
