import {
  Box,
  Card,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { fData } from "utils/formatNumber";
import { Form, useFormik } from "formik";
import { FormikProvider } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import UploadAvatar from "components/upload/UploadAvatar";
import { LoadingButton } from "@mui/lab";
import { FocusError } from "focus-formik-error";
import { uploadSVGImage } from "utils/svgImagesUtils";
import DropDownSelector from "components/DropdownSelector";

export default function SvgImageInnerForm({
  colourPalettesOptions,
}: {
  colourPalettesOptions: string[];
}) {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    svgImage: null,
    name: "",
    colorPalette: "",
  };

  const SvgImageSchema = Yup.object().shape({
    svgImage: Yup.string().required("SVG Image is required"),
    name: Yup.string().required("Name is required"),
    colorPalette: Yup.string().required("Color Palette is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: SvgImageSchema,
    onSubmit: async (values: any) => {
      if (!values.svgImage) {
        enqueueSnackbar("Please upload an SVG image", { variant: "error" });
        return;
      }
      try {
        await uploadSVGImage(values.svgImage, values.name, values.colorPalette);
        enqueueSnackbar("SVG Image saved correctly", {
          variant: "success",
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar(
          "Failed to upload the SVG image, please try again or contact the dev team",
          { variant: "error" }
        );
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];

      setFieldValue("svgImage", file);
    },
    [setFieldValue]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit}>
              <fieldset style={{ border: "none" }}>
                <FocusError formik={formik} />
                <Stack spacing={3}>
                  <Stack
                    direction={{ xs: "column" }}
                    spacing={{ xs: 3, sm: 2 }}
                  >
                    <Stack spacing={3}>
                      <Box sx={{ mb: 5 }}>
                        <UploadAvatar
                          accept="image/svg+xml"
                          file={values.svgImage}
                          maxSize={3145728}
                          onDrop={handleDrop}
                          error={Boolean(touched.svgImage && errors.svgImage)}
                          caption={
                            <Typography
                              variant="caption"
                              sx={{
                                mt: 2,
                                mx: "auto",
                                display: "block",
                                textAlign: "center",
                                color: "text.secondary",
                              }}
                            >
                              Only allowed *.svg
                              <br /> max size of {fData(3145728)}
                            </Typography>
                          }
                        />
                        <FormHelperText
                          error
                          sx={{ px: 2, textAlign: "center" }}
                        >
                          {touched.svgImage && errors.svgImage}
                        </FormHelperText>
                      </Box>

                      <TextField
                        fullWidth
                        label="Name of the Svg image"
                        {...getFieldProps("name")}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                      <DropDownSelector
                        label={"Select a colour Palette"}
                        disabled={false}
                        options={colourPalettesOptions}
                        {...getFieldProps("colorPalette")}
                      />
                    </Stack>
                  </Stack>
                </Stack>

                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Save Changes
                  </LoadingButton>
                </Box>
              </fieldset>
            </Form>
          </FormikProvider>
        </Card>
      </Grid>
    </Grid>
  );
}
