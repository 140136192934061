import React, { useCallback, useReducer } from "react";
import { useSnackbar } from "notistack";

import UserCreditsContext from "./UserCreditsContext";
import {
  getUserCreditsInfo,
  updateExtraCredits,
} from "utils/firebase/cloudFunctions";
import UserSearchReducer from "./reducer";
import { searchUserById } from "../Utils";
import {
  ActionTypes,
  initialState,
  UserCreditsContextValue,
} from "../../../types/user";
import { ErrorException } from "../../../types/settings";

const UserCreditsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(UserSearchReducer, initialState);

  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = useCallback((selectedUserId: string) => {
    dispatch({ type: ActionTypes.OPEN_FORM, payload: selectedUserId });
    fetchUserCreditsInfo(selectedUserId);
  }, []);

  const handleClose = useCallback(() => {
    dispatch({ type: ActionTypes.CLOSE_FORM });
  }, []);

  const handleSubmit = useCallback(
    async (formData, { setSubmitting, resetForm, setErrors }) => {
      try {
        await updateExtraCredits({
          extraCredits: formData?.extraCredits,
          userId: state.formDialog.selectedUserId,
        });

        setSubmitting(false);
        dispatch({ type: ActionTypes.CLOSE_FORM });
        resetForm();

        enqueueSnackbar("Update success ", {
          variant: "success",
        });
      } catch (error: ErrorException) {
        setSubmitting(false);
        enqueueSnackbar("Something went wrong :(", {
          variant: "error",
        });
        setErrors(error);
      }
    },
    [enqueueSnackbar, state.formDialog.selectedUserId]
  );

  const handleSearchInputChange = useCallback(
    async (value: string): Promise<void> => {
      const userInfo = await searchUserById(value);

      dispatch({
        type: ActionTypes.USERS_REQUEST,
        payload: userInfo,
      });
    },
    []
  );

  const fetchUserCreditsInfo = async (userId: string) => {
    try {
      const userInfo = await getUserCreditsInfo({
        userId,
      });

      dispatch({ type: ActionTypes.FETCH_CREDITS_INFO, payload: userInfo });
      dispatch({ type: ActionTypes.SET_FORM_INPUT_VALUES });
    } catch (error: ErrorException) {
      console.log(error);
    }
  };

  const value: UserCreditsContextValue = {
    ...state,
    formDialogIsOpen: state.formDialog.isOpen,
    handleClickOpen,
    handleClose,
    handleSubmit,
    handleSearchInputChange,
  };

  return (
    <UserCreditsContext.Provider value={value}>
      {children}
    </UserCreditsContext.Provider>
  );
};

export default UserCreditsProvider;
