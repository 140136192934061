import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from "@mui/material";

// routes
import { PATH_DASHBOARD } from "../../../routes/paths";

// hooks
import useSettings from "../../../hooks/useSettings";

// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { useSnackbar } from "notistack";
import TableToolbar from "../../../components/common/TableToolbar";
import TableHeader from "../../../components/common/TableHeader";
import MoreMenu from "../../../components/common/MoreMenu";

import { format } from "date-fns";
import { AudioMeditationGenerationType } from "../../../types/audioMeditationGenerations";
import {
  deleteAudioMeditationByIdFromDB,
  hasPermissionToAudioMeditation,
  useAudioMeditations
} from "../../../utils/firebase/audioMeditationGenerationsUtil";
import { dateTimeDisplayFormat } from "../../../utils/analytics/analyticsDefaults";
import LoadingScreen from "../../../components/LoadingScreen";
import { LANGUAGE_CODE_TO_NAMES } from "../../../utils/constants";
import { useCopyToClipBoard } from "../../../hooks/useCopyToClipBoard";
import { useCurrentRole } from "../../../hooks/useCurrentRole";
import { ErrorException } from "../../../types/settings";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  { id: "voiceIdOrName", label: "Voice Name", alignRight: false },
  { id: "sourceLang", label: "Source Language", alignRight: false },
  { id: "targetLang", label: "Target Language", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "audioUrl", label: "Audio Meditation", alignRight: false },
  { id: "isActive", label: "Is Active", alignRight: false },
  { id: "approvedBy", label: "Approved", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

// ----------------------------------------------------------------------

// preventing the undesirable behavior of returning to the first page of a list
// when a user navigates back from a details page or any other update page.
// The code accomplishes this by persisting the state information related to the page number, page size, snapshot, order by, and order of the audio meditations.
// By doing so, it ensures that the user remains on the same page within the list, maintaining a seamless and consistent
// navigation experience even after interacting with details or update pages.
let AudioMeditationPersistState = {
  pageNo: 0,
  pageSize: 5,
  snapShot: {},
  orderBy: "",
  searchText: "",
  order: "desc"
}

export default function AudioMeditationsList() {
  const { themeStretch } = useSettings();
  const currentRoles = useCurrentRole();
  const [page, setPage] = useState(AudioMeditationPersistState.pageNo || 0);
  const [order, setOrder] = useState<"asc" | "desc">(AudioMeditationPersistState.order as "asc" | "desc");
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState(AudioMeditationPersistState.orderBy || "createdAt");
  const [filterName, setFilterName] = useState(AudioMeditationPersistState.searchText);
  const [searchText, setSearchText] = useState(AudioMeditationPersistState.searchText);
  const [rowsPerPage, setRowsPerPage] = useState(AudioMeditationPersistState.pageSize || 5);
  const [deletingId, setDeletingId] = useState<string>();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { copyToClipBoard } = useCopyToClipBoard();

  const {
    audioMeditations = [],
    loading,
    isMoreMeditationsAvailable, lastSnapShot
  } = useAudioMeditations(currentRoles, page, rowsPerPage, orderBy, order, searchText, AudioMeditationPersistState.snapShot)
  const [showRootActions, setShowRootActions] = useState(false)

  useEffect(() => {
    return ()=>{
      AudioMeditationPersistState = {
        pageNo: page,
        pageSize: rowsPerPage,
        snapShot: lastSnapShot,
        orderBy,
        order,
        searchText
      }
    }
  }, [page, rowsPerPage, lastSnapShot, order, orderBy, searchText]);

  useEffect(() => {
    if (!currentRoles?.length) {
      return
    }

    if (hasPermissionToAudioMeditation(currentRoles).accessToAllLanguages) {
      setShowRootActions(true);
    }
  }, [currentRoles]);

  const headerActionNode = <Box sx={ { mt: 3, display: "flex", justifyContent: "flex-end" } }>
    <Button
      variant="contained"
      sx={ { mr: 4 } }
      component={ RouterLink }
      state={ { isManualCreated: true } }
      to={ PATH_DASHBOARD.audioMeditationGenerations.new }
      startIcon={ <Icon icon={ plusFill }/> }
    >
      Create Audio Meditation
    </Button>

    <Button
      variant="contained"
      component={ RouterLink }
      to={ PATH_DASHBOARD.audioMeditationGenerations.new }
      startIcon={ <Icon icon={ plusFill }/> }
    >
      Generate AI Audio Meditation
    </Button>
  </Box>

  const handleRequestSort = (property: string) => {
    if (searchText.length || (property !== "isActive" && property !== "approvedBy" && property !== "createdAt" && property !== "title")) {
      return
    }

    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    setSelected(checked ? audioMeditations?.map(meditation => meditation.title) : [])
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event?.target?.value ?? 0, 10));
    setPage(0);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, _page: number) => {
    setPage(_page);
  };

  const handleFilterByName = (newFilter: string) => {
    setFilterName(newFilter);
    if (!newFilter.length) {
      setPage(0);
      setOrderBy("createdAt")
      setOrder("desc")
      setSearchText(newFilter)

    }
  };

  const handleFilterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setPage(0);
      if (!filterName.length) {
        setOrderBy("createdAt")
        setOrder("desc")
      } else {
        setOrderBy("")
      }
      setSearchText(filterName)
    }
  };

  const handleDeleteAudioMeditation = async (selectedMeditation: AudioMeditationGenerationType) => {
    try {
      const selectedMeditationId = selectedMeditation.id

      if (!selectedMeditationId) return;

      setDeletingId(selectedMeditationId);

      if (window.confirm(`Do you really want to delete this audio meditation (${ selectedMeditation.title })?`)) {
        await deleteAudioMeditationByIdFromDB(selectedMeditationId);
        enqueueSnackbar("Audio Meditation Deleted Successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Delete Audio meditation cancel by user", {
          variant: "error",
        });
      }
    } catch (e: ErrorException) {
      console.error("FailedToDeleteAudioMeditation", e);
      enqueueSnackbar("Failed to delete audio meditation", {
        variant: "error",
      });
    } finally {
      setDeletingId("");
    }
  };

  const isGroupNotFound = !Boolean(audioMeditations.length) && !loading;

  const onDeleteAction = (audioMeditationItem: AudioMeditationGenerationType) => {
    if (!showRootActions) return

    return () => handleDeleteAudioMeditation(audioMeditationItem)
  }

  const onEditAction = (isManualCreated?: boolean, id?: string) => {
    if (!showRootActions) return

    return () => {
      navigate(
        `${ PATH_DASHBOARD.audioMeditationGenerations.root }/${ id }/edit`,
        {
          state: { isManualCreated },
        }
      );
    }
  }

  const onUpdateAction = (isManualCreated?: boolean, id?: string) => {
    if (isManualCreated) return

    return () => navigate(
      `${ PATH_DASHBOARD.audioMeditationGenerations.root }/${ id }/update`,
      {
        state: { isManualCreated },
      }
    );
  }

  const onCopy = (id: string) => () => {
    copyToClipBoard(id)
  }

  if (loading) {
    return <LoadingScreen/>;
  }

  return (
    <Page title="Audio Meditation Generations">
      <Container maxWidth={ themeStretch ? false : "lg" }>
        <HeaderBreadcrumbs
          heading=""
          links={ [] }
          action={ showRootActions ? headerActionNode : null }
        />

        <Card>
          <TableToolbar
            numSelected={ selected.length }
            filterName={ filterName }
            handleKeyDown={ handleFilterKeyDown }
            onFilterName={ handleFilterByName }
            searchPlaceholder={ "Search Audio Meditation" }
          />

          <Scrollbar>
            <TableContainer sx={ { minWidth: 800 } }>
              <Table>
                <TableHeader
                  order={ order }
                  orderBy={ orderBy }
                  headLabel={ TABLE_HEAD }
                  rowCount={ audioMeditations.length }
                  numSelected={ selected.length }
                  onRequestSort={ handleRequestSort }
                  onSelectAllClick={ handleSelectAllClick }
                  hideCheckbox
                />
                <TableBody>
                  { audioMeditations
                    .map((audioMeditationItem: AudioMeditationGenerationType) => {
                      const {
                        id,
                        title,
                        sourceLang,
                        targetLang,
                        status,
                        isManualCreated,
                        targetLanguageVoiceUrl,
                        createdAt,
                        isActive,
                        approvedBy,
                        voiceName
                      } = audioMeditationItem;
                      const isItemSelected = selected.indexOf(title) !== -1;

                      return (
                        <TableRow
                          hover
                          key={ `${ title }-${ id }` }
                          tabIndex={ -1 }
                          role="checkbox"
                          selected={ isItemSelected }
                          aria-checked={ isItemSelected }
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="subtitle2" noWrap>
                                { title }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="subtitle2" noWrap>
                                { voiceName ?? "Unknown" }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="subtitle2" noWrap>
                                { LANGUAGE_CODE_TO_NAMES[sourceLang?.toUpperCase?.()] ?? sourceLang }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="subtitle2" noWrap>
                                { LANGUAGE_CODE_TO_NAMES[targetLang?.toUpperCase?.()] ?? targetLang ?? "" }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }>
                              <Chip label={ status }
                                    color="primary"
                                    variant="outlined"/>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              { targetLanguageVoiceUrl ?
                                <Link href={ targetLanguageVoiceUrl } target="_blank">Download Audio File</Link> :
                                <Typography variant="subtitle2" noWrap>---</Typography> }
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }>
                              <Chip label={ isActive ? 'Active' : 'Inactive' }
                                    color={ isActive ? 'success' : 'error' }/>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal">

                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }>
                              { approvedBy ? <Chip label='Approved' color='success'/> :
                                <Typography variant="body1" noWrap ml={ 3 }>---</Typography>
                              }
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="body1" noWrap>
                                { createdAt ? format(createdAt, dateTimeDisplayFormat) : "---" }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="right">
                            <MoreMenu
                              deleting={ deletingId === id }
                              onDelete={ onDeleteAction(audioMeditationItem) }
                              onEdit={ onEditAction(isManualCreated, id) }
                              onUpdate={ onUpdateAction(isManualCreated, id) }
                              onView={ () => {
                                navigate(
                                  `${ PATH_DASHBOARD.audioMeditationGenerations.root }/${ id }/view`,
                                  {
                                    state: { isManualCreated },
                                  }
                                );
                              } }
                              updateButtonText={ "Refine Text Chunks" }
                              copyText={ "Copy Meditation ID" }
                              onCopy={ onCopy(id) }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }) }
                </TableBody>
                { isGroupNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={ 6 } sx={ { py: 3 } }>
                        <SearchNotFound searchQuery={ filterName }/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) }
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={ [5, 10, 25, 50, 100, 125, 150] }
            component="div"
            count={ isMoreMeditationsAvailable ? -1 : ((rowsPerPage * page) + audioMeditations.length) }
            rowsPerPage={ rowsPerPage }
            page={ page }
            onPageChange={ handlePageChange }
            onRowsPerPageChange={ handleChangeRowsPerPage }
          />
        </Card>
      </Container>
    </Page>
  );
}
