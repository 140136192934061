import React from "react";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";

// material
import {Box, Card, Grid,} from "@mui/material";
import {PaymentLink} from "../../../types/invitation/code";
import PaymentLinkInputForm from "../invitation/code-configs/PaymentLinkInputForm";
import {LoadingButton} from "@mui/lab";
import {createNewPaymentLink} from "../../../utils/firebase/cloudFunctions";
import { ErrorException } from "../../../types/settings";

// ----------------------------------------------------------------------

type Props = {
  isEdit: boolean;
  isView?: boolean;
  isNew?: boolean;
  paymentLink?: PaymentLink;
};

export default function NewPaymentLinkForm({
                                       isNew,
                                       isView,
                                       isEdit,
                                             paymentLink,
                                     }: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();



  const PaymentLinkSchema = Yup.object().shape({
    linkData: Yup.object().shape({
      name: Yup.object(),
      description: Yup.object(),
      unitAmount: Yup.number().required(),
      quantity: Yup.number().required(),
      currency: Yup.string().required(),
      reTranslateData: Yup.string().required(),
    })
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      linkData: {
        name: paymentLink?.name ?? {en: ""},
        description: paymentLink?.description ?? {en: ""},
        quantity: paymentLink?.quantity ?? 1,
        unitAmount: paymentLink?.unitAmount ?? 0,
        currency: paymentLink?.currency ?? "",
        reTranslateData: false,
        paymentLinkId: paymentLink?.paymentLinkId,
        paymentLinkUrl: paymentLink?.paymentLinkUrl,
      }
    },

    validationSchema: PaymentLinkSchema,
    onSubmit: async (values) => {
      let successMessage = "Successfully saved changes";
      let errorMessage = "Failed to save changes";
      if (isNew) {
        successMessage = "Link Created Successfully";
        errorMessage = "Failed to create link";
      }
      try {
        const paymentLinkId = isEdit && paymentLink?.id ?  paymentLink?.id : null
        await createNewPaymentLink({...values.linkData, id: paymentLinkId })
        navigate(-1);
        enqueueSnackbar(successMessage, { variant: "success" });
      } catch (e: ErrorException) {
        console.error("FailedToCreateLink", e);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    },
  });


  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
      setFieldValue
  } = formik;


  let submitBtnTitle = "Create Payment link";
  if (isView) {
    submitBtnTitle = "View Only";
  } else if (isEdit) {
    submitBtnTitle = "Save Changes";
  }



  return (
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <fieldset disabled={isView} style={{ border: "none" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card sx={{ p: 3 }}>
                 <PaymentLinkInputForm getFieldProps={getFieldProps} editing={isEdit} setFieldValue={setFieldValue} errors={errors} touched={touched} fieldsName={"linkData"}/>
                  <Box display="flex" justifyContent="flex-end">
                  <LoadingButton
                      sx={{
                        mt: 2
                      }}
                      disabled={isView}
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                  >
                    {submitBtnTitle}
                  </LoadingButton>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </fieldset>
        </Form>
      </FormikProvider>
  );
}
