import React from "react";
import { useSnackbar } from "notistack";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// material
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { Snippet } from "../../../types/snippet";
import { createOrUpdateSnippets } from "../../../utils/snippetsUtils";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import { ErrorException } from "../../../types/settings";

// ----------------------------------------------------------------------

type SnippetFormFormProps = {
  isEdit: boolean;
  isView?: boolean;
  isNew?: boolean;
  snippet: Snippet;
};

export default function SnippetForm({
  isNew,
  isView,
  isEdit,
  snippet,
}: SnippetFormFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const SnippetSchema = Yup.object().shape({
    id: Yup.string().required(),
    value: Yup.string().required(),
    isAIFunction: Yup.boolean(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: snippet?.id,
      value: snippet?.value ?? "",
      isAIFunction: snippet?.isAIFunction ?? false,
    },
    validationSchema: SnippetSchema,
    onSubmit: async (values) => {
      let successMessage = "Successfully saved changes";
      let errorMessage = "Failed to save changes";
      if (isNew) {
        successMessage = "Snippet Created Successfully";
        errorMessage = "Failed to create snippet";
      }
      try {
        const requestedSnippet = {
          ...values,
          createdOrUpdatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        };

        await createOrUpdateSnippets(values.id, requestedSnippet);
        enqueueSnackbar(successMessage, { variant: "success" });
        navigate(-1);
      } catch (e: ErrorException) {
        console.error("FailedToCreateSnippet", e);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  let submitBtnTitle = "Create Snippet";
  if (isView) {
    submitBtnTitle = "View Only";
  } else if (isEdit) {
    submitBtnTitle = "Save Changes";
  }

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <fieldset disabled={isView} style={{ border: "none" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label="ID"
                    disabled={isEdit}
                    {...getFieldProps("id")}
                    error={Boolean(touched.id && errors.id)}
                    helperText={touched.id && errors.id}
                  />

                  <TextField
                    id={"value"}
                    fullWidth
                    multiline={true}
                    minRows={4}
                    label="value"
                    {...getFieldProps("value")}
                    error={Boolean(touched.value && errors.value)}
                    helperText={touched.value && errors.value}
                  />

                  <FormControlLabel
                    label="Is OpenAI Function"
                    disabled={isView}
                    control={
                      <Checkbox
                        {...getFieldProps("isAIFunction")}
                        defaultChecked={snippet?.isAIFunction ?? false}
                      />
                    }
                  />

                  <Box
                    sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                  >
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {submitBtnTitle}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </fieldset>
      </Form>
    </FormikProvider>
  );
}
