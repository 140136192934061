import React, {useCallback, useEffect, useState} from 'react';

// material
import {Box, Button, Card, Container, Grid, Stack, TextField, Typography} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {LoadingButton} from '@mui/lab';
import {useSnackbar} from 'notistack';
import * as yup from 'yup';
import {Form, FormikProvider, useFormik} from 'formik';
import * as CloudFunctions from 'utils/firebase/cloudFunctions';

// hooks
import useSettings from '../../../../hooks/useSettings';

// components
import Page from '../../../../components/Page';
import {getUserRoles} from '../../../../utils/firebase';
import {removeDuplicateStrings} from '../../../../utils/helpers';
import {ErrorException} from "../../../../types/settings";
import {useRoles} from "../../../../utils/firebase/rolesUtil";
import CustomClaimsByRoles from "../../../../components/_dashboard/roles/CustomClaimsByRoles";
import {useLocation} from "react-router";
import {Link as RouterLink} from "react-router-dom";
import {PATH_DASHBOARD} from "../../../../routes/paths";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";

// ----------------------------------------------------------------------
const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

type UserRoles = {
    roles: Array<string>;
    customClaims: Array<string>;
    customClaimsWitoutAnyRole: Array<string>;
}
export default function UserRoleManage() {
    const {themeStretch} = useSettings();

    return (
        <Page title="Role">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <Grid container>
                    <Grid item xs={12} sx={{marginBottom: 10}}>
                        <AddEditRolesForm/>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}

type RolesByEmail = {
    [key: string]: Array<string>;
};

function AddEditRolesForm() {
    const [loading, setLoading] = useState<boolean>(false);
    const [userRolesByEmail, setUserRolesByEmail] = useState<RolesByEmail>();
    const [emptyTries, setEmptyTries] = useState<number>(0);
    const [showCustomClaims, setShowCustomClaims] = useState<boolean>(false); // Track visibility of CustomClaimsByRoles
    const [removedClaims, setRemovedClaims] = useState<string[]>([]);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userEmail: string = searchParams.get("userEmail") ?? "";

    const {enqueueSnackbar} = useSnackbar();
    const {rolesTitle, getCustomClaimsByRoleTitle} = useRoles()

    const NewRoleSchema = yup.object().shape({
        email: yup.string().email().required(),
        roles: yup.array().of(yup.string()).required(),
        customClaims: yup.array().of(yup.string()).required(),
        existingCustomClaims: yup.array().of(yup.string()).required()
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            roles: [],
            customClaims: [],
            existingCustomClaims: []
        },
        validationSchema: NewRoleSchema,
        onSubmit: async (values: { email: string; roles: Array<string> }) => {
            if (!values.email) {
                enqueueSnackbar('email cannot be empty.');
            } else if ((!Array.isArray(values.roles) || !values?.roles?.length) && emptyTries === 0) {
                enqueueSnackbar(
                    'Be careful, you are clearning all the roles. If it is intended try again.',
                    {
                        variant: 'warning'
                    }
                );
                setEmptyTries(1);
            } else {
                setEmptyTries(0);
                await updateRolesOnBE(values.email, values.roles as Array<string>);
            }
        }
    });

    const {
        errors,
        touched,
        values,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        getFieldProps,
        validateField,
    } = formik;

    useEffect(() => {
        setFieldValue('roles', userRolesByEmail?.[values.email] || []);
        setEmptyTries(0);
        //eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [values.email]);

    const onFetchRoles = useCallback(async (_userEmail?: string) => {
            try {
                setLoading(true);
                await validateField('email');
                const userRolesWithCustomClaims: UserRoles = await getUserRoles({
                    email: values.email || _userEmail,
                    isUsingApiV2: true,
                });
                await setFieldValue('roles', userRolesWithCustomClaims?.roles ?? []);
                await setFieldValue('customClaims', userRolesWithCustomClaims?.customClaims ?? [])
                await setFieldValue('existingCustomClaims', userRolesWithCustomClaims?.customClaimsWitoutAnyRole ?? [])
                setUserRolesByEmail({
                    ...userRolesByEmail,
                    [values.email]: userRolesWithCustomClaims?.roles ?? []
                });

                setShowCustomClaims(true)
                setLoading(false);
            } catch (e: ErrorException) {
                console.log('error: ', e);
                setLoading(false);
            }
        }, [setFieldValue, userRolesByEmail, validateField, values.email]);

    useEffect(() => {
        if (userEmail) {
            setFieldValue('email', userEmail);
            onFetchRoles(userEmail);
        }
        //eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [userEmail]);

    const updateRolesOnBE = async (email: string, roles: Array<string>) => {
        try {
            // performing this operation to filter invalid roles values,
            // it is helpful to avoid situation where a role is removed from the system
            // but is still assigned to the user, at some point of time.
            const validRoles = roles.filter((role) => rolesTitle.includes(role));
            const validRemovedRoles = removedClaims.filter((role) => rolesTitle.includes(role));

            await CloudFunctions.updateRole({
                email: email,
                roles: validRoles,
                isUsingApiV2: true,
                removeRoles: validRemovedRoles
            })
                .then((response) => {
                    enqueueSnackbar('successfully updated roles of the admin.', {variant: 'success'});

                    setUserRolesByEmail({
                        ...(userRolesByEmail || {}),
                        [`${values.email}`]: values.roles
                    });

                    setFieldValue('customClaims', response?.data ?? []);
                    setFieldValue('existingCustomClaims', []);
                    setShowCustomClaims(true);
                })
                .catch((err: any) => {
                    throw err;
                });
        } catch (e: ErrorException) {
            enqueueSnackbar(e?.message || 'something went wrong.', {variant: 'error'});
        }
    };

    const headerActionNode = <Box sx={ { mt: 3, display: "flex", justifyContent: "flex-end" } }>
        <Button
            variant="contained"
            sx={ { mr: 4 } }
            component={ RouterLink }
            state={ { isManualCreated: true } }
            to={ PATH_DASHBOARD.iam.usersWithCustomClaims }
        >
            View all users with custom claims
        </Button>
    </Box>

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <HeaderBreadcrumbs
                    heading=""
                    links={ [] }
                    action={headerActionNode}
                />
                <Card sx={{p: 4, overflow: 'initial'}}>
                    <Stack spacing={4}>
                        <Stack>
                            <Typography sx={{my: 3}} variant="subtitle2">
                                Specific User Update
                            </Typography>
                            <Stack spacing={3}>
                                <Stack
                                    direction={{xs: 'column', sm: 'row'}}
                                    spacing={1}
                                    sx={{justifyContent: 'space-between'}}
                                >
                                    <TextField
                                        disabled={loading || isSubmitting}
                                        fullWidth
                                        label="Email Address"
                                        {...getFieldProps('email')}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                    <LoadingButton
                                        disabled={loading || isSubmitting}
                                        variant="contained"
                                        loading={loading}
                                        onClick={()=>onFetchRoles()}
                                    >
                                        Fetch Roles
                                    </LoadingButton>
                                </Stack>
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    value={values?.roles}
                                    onChange={(event, newValue) => {
                                        const removedVales = values.roles.filter(role => !newValue.includes(role));
                                        setFieldValue('roles', newValue)

                                        setShowCustomClaims(false)
                                        setRemovedClaims(prevRemovedRoles => [
                                            ...prevRemovedRoles,
                                            ...removedVales.filter((role) => !prevRemovedRoles.includes(role))
                                        ]);
                                    }}
                                    options={removeDuplicateStrings([
                                        ...(values?.roles || []),
                                        ...rolesTitle
                                    ])}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option}
                                    renderOption={(props, option, {selected}) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{marginRight: 8}}
                                                checked={selected}
                                            />
                                            {option}
                                            <strong> <Typography variant="caption"
                                                                 sx={{ml: 1}}>{getCustomClaimsByRoleTitle(option)} </Typography></strong>
                                        </li>
                                    )}
                                    style={{width: 720}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Roles"
                                            placeholder="Select the roles to be assigned"
                                        />
                                    )}
                                />

                                {showCustomClaims && Boolean(values?.roles?.length) && (
                                    <CustomClaimsByRoles
                                        customClaims={values.customClaims || []}
                                        title={"Custom Claims Assigned to Roles"}
                                    />
                                )}

                                {Boolean(values?.existingCustomClaims?.length) && (
                                    <CustomClaimsByRoles
                                        style={{color: 'red'}}
                                        customClaims={values.existingCustomClaims || []}
                                        title={"**Warning: Below Custom Claims Assigned Without a Role – Saving Will Overwrite Existing Claims**"}
                                    />
                                )}

                                <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                        disabled={isSubmitting || loading}
                                    >
                                        Save
                                    </LoadingButton>
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                </Card>
            </Form>
        </FormikProvider>
    );
}
