import { isNil, omitBy } from "lodash";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { db } from "../../contexts/FirebaseContext";

export interface CallRating {
  overall: number;
  recommend?: number;
  // mind the typo here, this is how we save it in the database
  counselor?: number;
  feedback?: [string];
  comment?: string;
  ratingCreateTime?: firebase.firestore.Timestamp;
  ratingUpdateTime?: firebase.firestore.Timestamp;
  ratingUpdateBy?: string;
  updateReason?: string;
}

interface Profile {
  avatar?: string;
  name: string;
  timezone: string;
  uid: string;
}

export interface CallRatingDetail {
  messageId: string;
  chatId: string;
  rating: CallRating;
  userProfile: Profile;
  therapistProfile: Profile;
}

interface CallRatingChangeOperation {
  messageId: string;
  before: CallRating;
  operation: "update" | "delete";
  performedBy: string;
  time: firebase.firestore.Timestamp;
  reason?: string;
}

interface UpdateCallRatingArgs {
  chatId: string | undefined;
  messageId: string | undefined;
  rating: Partial<CallRating> | undefined;
  ratingUpdateBy: string | undefined;
  ratingBefore: CallRating;
}

export function getMessagesWithCallRatings(chatId: string) {
  return db
    .collection(`messages/${chatId}/conversation`)
    .where("rating", "!=", null);
}

export function updateCallRating({
  chatId,
  messageId,
  ratingUpdateBy,
  rating,
  ratingBefore,
}: UpdateCallRatingArgs) {
  if (!chatId || !messageId || !rating) {
    throw new Error("chatId, messageId and rating are required");
  }
  const ratingUpdateTime = firebase.firestore.Timestamp.fromDate(new Date());
  const { overall, recommend, counselor, comment, updateReason } = rating;
  const updatedRating = omitBy(
    {
      overall,
      recommend,
      counselor,
      comment,
      ratingUpdateBy,
      updateReason,
      ratingUpdateTime,
    },
    isNil
  );

  return db.runTransaction(async (transaction) => {
    const changeTime = firebase.firestore.Timestamp.fromDate(new Date());
    const messageRef = db
      .collection(`messages/${chatId}/conversation`)
      .doc(messageId);
    transaction.set(messageRef, { rating: updatedRating }, { merge: true });
    if (ratingBefore) {
      await recordRatingChange(
        chatId,
        messageId,
        ratingBefore,
        "update",
        ratingUpdateBy,
        changeTime
      );
    }
  });
}

export function deleteCallRating(
  chatId: string,
  messageId: string,
  ratingBefore: CallRating,
  ratingUpdateBy: string | undefined,
) {
  const changeTime = firebase.firestore.Timestamp.fromDate(new Date());
  return db.runTransaction(async (transaction) => {
    const messageRef = db
      .collection(`messages/${chatId}/conversation`)
      .doc(messageId);
    transaction.set(messageRef, { rating: null }, { merge: true });
    if (ratingBefore) {
      await recordRatingChange(
        chatId,
        messageId,
        ratingBefore,
        "delete",
        ratingUpdateBy,
        changeTime
      );
    }
  });
}

export function recordRatingChange(
  chatId: string,
  messageId: string,
  ratingBefore: CallRating,
  operation: "update" | "delete",
  ratingUpdateBy: string | undefined,
  changeTimestamp: firebase.firestore.Timestamp
) {
  const changeOperation: CallRatingChangeOperation = {
    messageId,
    before: ratingBefore,
    operation,
    performedBy: ratingUpdateBy || "",
    time: changeTimestamp,
  };

  return db
    .collection(`call_rating_changelog/${chatId}/changelog`)
    .doc(changeTimestamp.toDate().toISOString())
    .set(changeOperation, { merge: true });
}
