import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import searchFill from "@iconify/icons-eva/search-fill";
import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  InputAdornment,
} from "@mui/material";
import { useSnackbar } from "notistack";

// components
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { Icon } from "@iconify/react";
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import useAuth from "hooks/useAuth";
import { UserListHead } from "../../../components/_dashboard/user/list";
import { useLocation } from "react-router";
import {
  CallRatingDetail,
  deleteCallRating,
  getMessagesWithCallRatings,
} from "utils/firebase/callRatingUtils";
import {
  RootStyle,
  SearchStyle,
} from "components/_dashboard/user/list/UserListToolbar";
import MoreMenu from "components/common/MoreMenu";
import DeleteConfirmationDialog from "components/_dashboard/content/DeleteConfirmationDialog";
import EditCallRatingDialog from "./EditCallRatingDialog";
import { ErrorException } from "../../../types/settings";

// ----------------------------------------------------------------------
const CALL_RATINGS_TABLE_HEAD = [
  { id: "therapistProfile.name", label: "Counsellor", alignRight: false },
  { id: "userProfile.name", label: "User", alignRight: false },
  { id: "ratingCreateTime", label: "Date", alignRight: false },
  { id: "messageId", label: "MessageId", alignRight: false },
  { id: "overall", label: "Overall Rating", alignRight: false },
  { id: "counsellor", label: "Counsellor Rating", alignRight: false },
  { id: "recommend", label: "Recommend Rating", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];
// ----------------------------------------------------------------------

function CallRatingList() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const initialChatId = query.get("chatId") || "";
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("therapistName");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [callRatings, setCallRatings] = useState<CallRatingDetail[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [chatId, setChatId] = useState(initialChatId);
  const [selectedRating, setSelectedRating] = useState<CallRatingDetail>();
  const [loading, setLoading] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const { user: loggedInUser } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const fetchCallRatings = useCallback(
    (chat: string) => {
      const unsubscribe = getMessagesWithCallRatings(chat).onSnapshot(
        (snapshot: firebase.firestore.QuerySnapshot) => {
          const ratings: CallRatingDetail[] = [];
          snapshot.forEach((doc) => {
            ratings.push({
              messageId: doc.id,
              chatId,
              rating: doc.data().rating,
              userProfile: doc.data().userProfile,
              therapistProfile: doc.data().therapistProfile,
            });
          });
          setCallRatings(ratings);
          setPage(0);
        },
        (error) => {
          console.error("Failed to fetch call ratings:", error);
        }
      );
      return unsubscribe;
    },
    [chatId]
  );

  useEffect(() => {
    if (initialChatId) {
      const unsubscribe = fetchCallRatings(initialChatId);
      return () => unsubscribe();
    }
  }, [initialChatId, fetchCallRatings]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    fetchCallRatings(chatId);
  };

  const onDeleteAction = (rating: CallRatingDetail) => () => {
    setSelectedRating(rating);
    setOpenDeleteDialog(true);
  };
  const onEditAction = (rating: CallRatingDetail) => () => {
    setSelectedRating(rating);
    setOpenEditDialog(true);
  };

  const handleDelete = (rating: CallRatingDetail) => async () => {
    setLoading(true);
    try {
      await deleteCallRating(
        rating?.chatId,
        rating?.messageId,
        rating.rating,
        loggedInUser?.uid
      );
      enqueueSnackbar("Rating successfully deleted", {
        variant: "success",
      });
    } catch (error: ErrorException) {
      const errorMessage = (error as Error).message || "Unknown error";
      enqueueSnackbar(`Error while deleting rating: ${errorMessage}`, {
        variant: "error",
      });
    }
    setLoading(false);
    setSelectedRating(undefined);
    setOpenDeleteDialog(false);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - callRatings.length) : 0;

  return (
    <Page title="Call Ratings">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading="Call Ratings" links={[]} />

        <Card>
          <RootStyle>
            <SearchStyle
              value={chatId}
              onChange={(e) => setChatId(e.target.value)}
              placeholder="Enter ChatId and press Enter"
              inputProps={{ "aria-label": "Enter ChatId and press Enter" }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              sx={{ width: 300 }}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              }
            />
          </RootStyle>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={CALL_RATINGS_TABLE_HEAD}
                  rowCount={callRatings.length}
                  numSelected={0}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={() => {}}
                />
                <TableBody>
                  {callRatings
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((rating, index) => {
                      const {
                        rating: {
                          overall,
                          counselor,
                          recommend,
                          ratingCreateTime,
                        },
                        messageId,
                        userProfile: { name: userName },
                        therapistProfile: { name: therapistName },
                      } = rating;

                      return (
                        <TableRow hover key={index} tabIndex={-1}>
                          <TableCell align="left">{therapistName}</TableCell>
                          <TableCell align="left">{userName}</TableCell>
                          <TableCell align="left">
                            {ratingCreateTime
                              ? ratingCreateTime.toDate().toLocaleDateString()
                              : "---"}
                          </TableCell>
                          <TableCell align="left">{messageId}</TableCell>
                          <TableCell align="left">{overall}</TableCell>
                          <TableCell align="left">{counselor}</TableCell>
                          <TableCell align="left">{recommend}</TableCell>
                          <TableCell align="right">
                            <MoreMenu
                              onDelete={onDeleteAction(rating)}
                              onEdit={onEditAction(rating)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {callRatings.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        {chatId
                          ? "No ratings found for this ChatId"
                          : "Please enter a ChatId to view ratings"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={callRatings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <DeleteConfirmationDialog
            open={openDeleteDialog}
            loading={loading}
            dialogTitle={"You are about to permanently delete this rating"}
            dialogDescription={
              "Are you sure that you want to delete this rating? This action cannot be reversed."
            }
            deleteButtonText={"Delete Rating"}
            onClose={() => {
              setOpenDeleteDialog(false);
            }}
            handleDelete={
              selectedRating ? handleDelete(selectedRating) : () => {}
            }
            confirmationInputRequired={true}
          />
          <EditCallRatingDialog
            rating={selectedRating}
            open={openEditDialog}
            onClose={() => {
              setOpenEditDialog(false);
            }}
            currendUserUid={loggedInUser?.uid}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default CallRatingList;
