import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { array, object, string } from "yup";
import FormControl from "@mui/material/FormControl";

// material
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { ReleaseNotes } from "../../../types/releaseNotes";
import {
  AppLanguageTranslationType,
  AppTranslationKeyDescriptionType,
} from "../../../types/translations";
import {
  addOrUpdateLanguageTranslationInBulk,
  addOrUpdateTranslationKeyAndDescription,
  checkTranslationKeyIsAlreadyExist,
  metaInformationToLanguageField,
} from "../../../utils/firebase/translationUtils";
import { find } from "lodash";
import firebase from "firebase/compat/app";
import { generateAndSaveAIBasedTranslations } from "../../../utils/firebase/cloudFunctions";
import {
  AIModelType,
  AIModelTypes,
  SupportedLanguagesEnum,
} from "../../../utils/constants";
import { firebaseAuth } from "../../../contexts/FirebaseContext";
import AlgoliaTextField from "../../algolia/AlgoliaTextField";
import { SearchClient } from "algoliasearch/dist/algoliasearch";
import { InstantSearch, HitsPerPage } from "react-instantsearch-dom";
import { PATH_DASHBOARD } from "../../../routes/paths";
import LoadingScreen from "../../LoadingScreen";
import { Theme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import {
  PRIORITY_OPTIONS,
  TranslationStatus,
} from "../../../pages/dashboard/app-side-translations/AppSideTranslationQuickEdit";
import { useGetAIPrompts } from "../../../hooks/useGetAIPrompts";
import { ErrorException } from "../../../types/settings";

// ----------------------------------------------------------------------

type AppSideTranslationNewFormProps = {
  isEdit: boolean;
  isView?: boolean;
  isNew?: boolean;
  theme: Theme;
  translationKeyWithDescription?: AppTranslationKeyDescriptionType;
  translationsValueAndApprovedBy?: Array<AppLanguageTranslationType>;
  accessToAllLanguagesTranslation: boolean;
  showEnglishTranslationViewOnly: boolean;
  accessLanguagesTranslationList: string[];
  releaseNote?: ReleaseNotes;
  translationsCodeToName: Record<string, string>;
  searchClient?: SearchClient;
};

function AppSideTranslationForm({
  isNew,
  isView,
  isEdit,
  translationKeyWithDescription,
  translationsValueAndApprovedBy,
  accessToAllLanguagesTranslation,
  accessLanguagesTranslationList,
  theme,
  showEnglishTranslationViewOnly,
  translationsCodeToName,
  searchClient,
}: AppSideTranslationNewFormProps) {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { activeAIPrompts } = useGetAIPrompts();

  const [isSaveWithOutGenerating, setIsSaveWithoutGenerating] = useState(false);

  const activeAIPromptIDs = activeAIPrompts
    ?.filter(({ aiModelType }) => aiModelType === AIModelType.ANTHROPIC)
    .map(({ id }) => id);

  const AppSideTranslationSchema = Yup.object().shape({
    id: Yup.string().optional(),
    key: Yup.string().required().required("Translated Key is required"),
    description: Yup.string().required().required("Description is required"),
    priority: Yup.string().optional(),
    languages: array(
      object().shape({
        locale: string(),
        value: string(),
      })
    ),
    aiModelType: Yup.string().required(),
    aiPrompt: Yup.array().of(
      Yup.string()
    ),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      id: translationKeyWithDescription?.id,
      key: translationKeyWithDescription?.key ?? "",
      description: translationKeyWithDescription?.description ?? "",
      priority: translationKeyWithDescription?.priority ?? "",
      languages: [],
      aiModelType:
        translationKeyWithDescription?.aiModelType ?? AIModelType.OPEN_AI,
      aiPrompt: translationKeyWithDescription?.aiPrompt ?? [],
    },

    validationSchema: AppSideTranslationSchema,
    onSubmit: async (values) => {
      try {
        let id = values.id || values.key;
        const enValue =
          (
            values.languages.find(
              ({ locale }) => locale === SupportedLanguagesEnum.ENGLISH
            ) as
              | {
                  value?: string;
                }
              | undefined
          )?.value ?? "";
        if (isNew && !enValue) return;

        if (
          values.key !== translationKeyWithDescription?.key ||
          values.description !== translationKeyWithDescription?.description ||
          values.priority !== translationKeyWithDescription?.priority ||
          values.aiPrompt !== translationKeyWithDescription?.aiPrompt
        ) {
          const newTranslationPayload: AppTranslationKeyDescriptionType = {
            id,
            key: values.key,
            description: values.description,
            priority: values.priority,
            aiModelType: values.aiModelType,
            aiPrompt: values.aiPrompt
          };

          if (isNew) {
            newTranslationPayload.createdAt =
              firebase.firestore.Timestamp.fromDate(new Date());
          }

          await addOrUpdateTranslationKeyAndDescription(newTranslationPayload);
        }

        let translationValuesInLanguages: Array<AppLanguageTranslationType> =
          values.languages;
        if (isNew) {
          translationValuesInLanguages = translationValuesInLanguages.map(
            (translationValue) => {
              if (translationValue.locale === SupportedLanguagesEnum.ENGLISH) {
                translationValue.approvedBy = firebaseAuth().currentUser?.uid;
              }
              return translationValue;
            }
          );
        }

        if (showEnglishTranslationViewOnly) {
          translationValuesInLanguages = translationValuesInLanguages.filter(
            ({ locale }) => locale !== SupportedLanguagesEnum.ENGLISH
          );
        }

        if (isNew) {
          translationValuesInLanguages = translationValuesInLanguages.map(
            (translationValuesInLanguage) => ({
              ...translationValuesInLanguage,
              approvedBy: null,
            })
          );
        }

        await addOrUpdateLanguageTranslationInBulk(
          id,
          translationValuesInLanguages
        );

        const filterLanguagesWithEmptyValue = values.languages.filter(
          (item: { locale: string; value: string }) => !item.value.length
        );

        if (filterLanguagesWithEmptyValue.length && !isSaveWithOutGenerating) {
          await generateAndSaveAIBasedTranslations({
            translationId: id,
            targetLanguages: filterLanguagesWithEmptyValue?.map(
              ({ locale }) => locale
            ),
            translateKey: values.key,
            valueInEN: enValue,
            description: values.description,
            isAnthropicAiModel: values.aiModelType === AIModelType.ANTHROPIC,
            aiPrompt: values.aiPrompt.join(', '),
          });
        }

        enqueueSnackbar("Saved successfully!", { variant: "success" });
        navigate(-1);
      } catch (e: ErrorException) {
        console.error(e);
        enqueueSnackbar("Failed to save", { variant: "error" });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;
  const languagesKey = "languages";
  const languagesFieldValues = getFieldProps(languagesKey).value || [];
  const isAnthropicAiModel = values.aiModelType === AIModelType.ANTHROPIC

  useEffect(() => {
    let langKeys = Object.keys(translationsCodeToName);
    if (!accessToAllLanguagesTranslation && !isNew) {
      langKeys = langKeys.filter((key) =>
        accessLanguagesTranslationList.includes(key)
      );
    }

    if (!langKeys.includes(SupportedLanguagesEnum.ENGLISH)) {
      langKeys = [SupportedLanguagesEnum.ENGLISH, ...langKeys];
    }

    const langFields = [];
    for (const locale of langKeys) {
      langFields.push({
        locale,
        value: find(translationsValueAndApprovedBy, { locale })?.value ?? "",
      });
    }
    setFieldValue(languagesKey, langFields);
  }, [
    setFieldValue,
    accessLanguagesTranslationList,
    accessToAllLanguagesTranslation,
    isNew,
    translationsValueAndApprovedBy,
    translationsCodeToName,
  ]);

  let submitBtnTitle = "Create app side translation";
  if (isView) {
    submitBtnTitle = "View Only";
  } else if (isEdit) {
    submitBtnTitle = "Save Changes with Translations";
  }

  const onSaveWithoutTranslations = () => {
    setIsSaveWithoutGenerating(true);
    formik.submitForm();
  };

  const onSaveWithTranslations = () => {
    setIsSaveWithoutGenerating(false);
    formik.submitForm();
  };

  const onNavigateToExistingTranslation = (key?: string) => {
    if (!key || key === translationKeyWithDescription?.key) {
      return;
    }

    navigate(`${PATH_DASHBOARD.appSideTranslations.root}`);
    navigate(`${PATH_DASHBOARD.appSideTranslations.root}/${key}/edit`);
  };

  const renderAlgoliaTextField = (
    label: string,
    key: string,
    disabled: boolean,
    searchByValue?: boolean,
    helperText?: any
  ) => {
    return (
      <AlgoliaTextField
        multiline={searchByValue}
        key={key}
        onSelectValue={(value) => {
          onNavigateToExistingTranslation(value);
        }}
        showEnglishValueInSearch={searchByValue}
        onChangeValue={(hits, value) => {
          setFieldValue(key, value);
          if (isNew) {
            setShowAlert(
              checkTranslationKeyIsAlreadyExist(
                hits,
                value ?? "",
                searchByValue
              )
            );
          }
        }}
        label={label}
        disabled={disabled}
        helperText={helperText}
        {...getFieldProps(key)}
      />
    );
  };

  if (!searchClient) {
    return <LoadingScreen />;
  }

  return (
    <InstantSearch indexName="app_translations" searchClient={searchClient}>
      {showAlert && (
        <Alert severity="warning">
          This Translation key/value is already exist. Please check on &nbsp;
          <Typography
            component="span"
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() =>
              onNavigateToExistingTranslation(getFieldProps("key").value)
            }
          >
            this link.
          </Typography>
        </Alert>
      )}

      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <fieldset disabled={isView} style={{ border: "none" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    {renderAlgoliaTextField(
                      "Translated Key",
                      "key",
                      isView || !accessToAllLanguagesTranslation,
                      false,
                      touched.key && errors.key
                    )}
                    <TextField
                      fullWidth
                      label="Description"
                      disabled={isView || !accessToAllLanguagesTranslation}
                      {...getFieldProps("description")}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />

                    <FormControl fullWidth>
                      <InputLabel id="open-ai-select-label">
                        Select AI Model
                      </InputLabel>
                      <Select
                        label="Select AI Model"
                        disabled={isView}
                        {...getFieldProps("aiModelType")}
                      >
                        {AIModelTypes.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {isAnthropicAiModel && (
                      <FormControl fullWidth>
                        <InputLabel id="open-ai-prompt-select-label">
                          Select AI Prompt
                        </InputLabel>
                        <Select
                          multiple={true}
                          label="Select AI Prompts"
                          disabled={isView}
                          {...getFieldProps("aiPrompt")}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <em>Select AI Prompts</em>;
                            }

                            return selected.join(', ');
                          }}
                        >
                          {activeAIPromptIDs.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    <FormControl fullWidth>
                      <InputLabel id="custom-language-priority-label">
                        Language Priority
                      </InputLabel>
                      <Select
                        labelId="custom-language-priority-label"
                        id="custom-language-priority"
                        label={"Language Priority"}
                        disabled={isView}
                        defaultValue={TranslationStatus.LOW}
                        {...getFieldProps("priority")}
                      >
                        {PRIORITY_OPTIONS?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Card
                      sx={{
                        ml: 3,
                        mr: 3,
                        border: `1px dashed ${theme.palette.grey}`,
                      }}
                    >
                      <CardContent>
                        {languagesFieldValues?.map(
                          (
                            item: { locale: string; value: string },
                            index: number
                          ) => {
                            if (
                              item.locale === SupportedLanguagesEnum.ENGLISH
                            ) {
                              return (
                                <Box
                                  key={item.locale || index}
                                  sx={{ width: "100%", mb: 5 }}
                                >
                                  {renderAlgoliaTextField(
                                    `Enter Translated Value in ${
                                      translationsCodeToName.en ?? ""
                                    }`,
                                    `${languagesKey}.${index}.value`,
                                    isView || showEnglishTranslationViewOnly,
                                    true
                                  )}
                                </Box>
                              );
                            }

                            return (
                              <TextField
                                key={item.locale || index}
                                InputLabelProps={{ shrink: true }}
                                multiline
                                disabled={
                                  isView ||
                                  (item.locale ===
                                    SupportedLanguagesEnum.ENGLISH &&
                                    showEnglishTranslationViewOnly)
                                }
                                sx={{ width: "100%", mb: 5 }}
                                placeholder={`${metaInformationToLanguageField(
                                  item.locale,
                                  translationsCodeToName,
                                  isNew
                                )}`}
                                label={`Enter Translated Value in ${
                                  translationsCodeToName[item.locale] ?? ""
                                }`}
                                defaultValue={""}
                                {...getFieldProps(
                                  `${languagesKey}.${index}.value`
                                )}
                                type={"text"}
                              />
                            );
                          }
                        )}
                      </CardContent>
                    </Card>

                    <Box
                      sx={{
                        mt: 3,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <LoadingButton
                        type="button"
                        variant="contained"
                        onClick={onSaveWithoutTranslations}
                        loading={isSubmitting}
                        sx={{ mr: 4 }}
                      >
                        Save Without Translations
                      </LoadingButton>

                      <LoadingButton
                        type="button"
                        variant="contained"
                        onClick={onSaveWithTranslations}
                        loading={isSubmitting}
                      >
                        {submitBtnTitle}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </fieldset>
        </Form>
      </FormikProvider>

      <HitsPerPage defaultRefinement={5} items={[]} />
    </InstantSearch>
  );
}

export default AppSideTranslationForm;
