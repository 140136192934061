import { Typography } from "@mui/material";
import { PaperPage } from "components/PaperPage";
import firebase from "firebase/compat/app";
import { FormikHelpers } from "formik";
import { useMultiFactorLogin } from "hooks/useMultiFactorLogin";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isGoogleApiError } from "utils/isGoogleApiError";
import {
  TwoFactorVerificationForm,
  VerificationCodeFormValues,
} from "./TwoFactorVerificationForm";
import { MFAStep } from "../../../types/authentication";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { ErrorException } from "../../../types/settings";

export function MFALogin({
                           resolver,
                           selectedIndex = 0,
                         }: {
  resolver: firebase.auth.MultiFactorResolver;
  selectedIndex: number;
}) {
  const navigate = useNavigate();
  const { requestAuthenticationCode, resolveSignIn } =
    useMultiFactorLogin(resolver);
  const selectedHint = resolver.hints[selectedIndex];
  const [step, setStep] = useState<MFAStep>(MFAStep.VERIFY);
  const reCaptchaVerifier = useRef<firebase.auth.RecaptchaVerifier | null>(
    null
  );

  const onSubmit = async (
    values: VerificationCodeFormValues,
    helpers: FormikHelpers<VerificationCodeFormValues>
  ) => {
    const { verificationCode } = values;
    try {
      await resolveSignIn(verificationCode);
      setStep(MFAStep.LOGGING_IN);
      navigate(PATH_DASHBOARD.root);
    } catch (e: ErrorException) {
      if (isGoogleApiError(e)) {
        if (e.code === "auth/invalid-verification-code") {
          helpers.setFieldError("verificationCode", "invalid");
          return;
        }
      }

      console.error(e);
      setStep(MFAStep.ERROR);
    }
  };

  useEffect(() => {
    if (reCaptchaVerifier.current !== null) {
      return;
    }

    setTimeout(() => {
      reCaptchaVerifier.current = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container-id",
        {
          size: "invisible",
        }
      );
      reCaptchaVerifier.current.render().then(() => {
        if (reCaptchaVerifier.current) {
          // we are just making TS happy here
          requestAuthenticationCode(reCaptchaVerifier.current, selectedHint);
        }
      });
    }, 1000);
  }, [selectedHint, requestAuthenticationCode]);

  if (step === MFAStep.LOGGING_IN || step === MFAStep.ERROR) {
    let title = "Success";
    if (step === MFAStep.ERROR) {
      title = "An error occurred";
    }

    let message = "Logging you in";
    if (step === MFAStep.ERROR) {
      message =
        "An error occurred. Try again or contact support if the issue persists";
    }
    return (
      <>
        <PaperPage headTitle={"Complete Sign-in"} title={title}>
          <Typography textAlign="center">{message}</Typography>
        </PaperPage>
      </>
    );
  }

  return (
    <>
      <PaperPage
        headTitle={"Complete Sign-in"}
        title={"Verify it's you"}
      >
        <TwoFactorVerificationForm
          hint={`Please enter the code we sent to ${selectedHint?.displayName ?? ""}`}
          onSubmit={onSubmit}
          onResendRequested={() => {
            if (reCaptchaVerifier.current) {
              requestAuthenticationCode(
                reCaptchaVerifier.current,
                selectedHint
              );
            } else {
              const error = new Error(
                "Authentication code was requested, but the reCaptcha is not initialized"
              );
              console.warn(error);
            }
          }}
        />
      </PaperPage>
    </>
  );
}
