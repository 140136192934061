import firebase from "firebase/compat/app";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { InvitationCode } from "types/invitation/code";
import { Collections } from "../types";

const EMPTY_RESULT: InvitationCode[] = [];

export default function useGetAllInvitationCodes(): [
  InvitationCode[],
  boolean
] {
  const [data, loading] = useCollectionDataOnce<InvitationCode>(
    firebase.firestore().collection(Collections.INVITATION_CODES)
  );

  return [data ?? EMPTY_RESULT, loading];
}
