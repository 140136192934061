import { useEffect, useRef, useState } from "react";
import firebase from "firebase/compat/app";

import {
  AudioContentAccessType,
  AudioMeditationGenerationStatus,
  AudioMeditationGenerationType, AudioMeditationWithoutGenerationType,
  ManualAudioMeditationCreationRequestType, MeditationVoiceType
} from "../../types/audioMeditationGenerations";
import { db } from "../../contexts/FirebaseContext";
import { ROLES } from "../constants";
import { CCRolesPossibleVals } from "../../types/role/CCRoles";
import useAuth from "../../hooks/useAuth";
import { searchUserById } from "../../pages/users/Utils";
import { ErrorException } from "../../types/settings";

export function useAudioMeditations(currentRoles: CCRolesPossibleVals[], pageNo: number, pageSize: number, sortingField: string, sortingOrder: string, searchText: string, initialSnapShot = {}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [isMoreMeditationsAvailable, setIsMoreMeditationsAvailable] = useState<boolean>(true);
  const lastSnapShot = useRef<any>(initialSnapShot);
  const [audioMeditations = [], setAudioMeditations] = useState<Array<AudioMeditationGenerationType>>([]);

  useEffect(() => {
    if (!currentRoles?.length) {
      setLoading(false)
      return
    }

    let dbRef: firebase.firestore.CollectionReference | firebase.firestore.Query = db
      .collection("audio_meditations")
    const {
      accessToAllLanguages,
      accessLanguages
    }: AudioContentAccessType = hasPermissionToAudioMeditation(currentRoles)

    if (!accessToAllLanguages && !accessLanguages?.length) {
      setLoading(false)
      return
    }

    if (accessLanguages?.length) {
      dbRef = dbRef.where("targetLang", "in", accessLanguages)
    }

    if (searchText?.trim()?.length) {
      dbRef = dbRef.where("title", ">=", searchText)
    } else {
      dbRef = dbRef
        .orderBy(sortingField, sortingOrder as firebase.firestore.OrderByDirection)
    }


    if (lastSnapShot?.current[pageNo]) {
      dbRef = dbRef.startAfter(lastSnapShot?.current[pageNo] ?? 0)
    }

    setLoading(true);
    let unsub = dbRef
      .limit(pageSize)
      .onSnapshot(async (snapshot) => {
        const snapShotKey = pageNo + 1
        lastSnapShot.current[snapShotKey] = snapshot.docs[snapshot.docs.length - 1]
        const dbAudioMeditations = []
        for await(const doc of snapshot.docs) {
          const meditationItem = doc.data();
          const voiceId = meditationItem.voiceConfig.id
          const voiceName = await getMeditationVoiceByIdFromDB(voiceId) ?? voiceId
          dbAudioMeditations.push({
            ...(meditationItem as AudioMeditationGenerationType), id: doc.id, createdAt:
              (meditationItem.createdAt as firebase.firestore.Timestamp)?.toDate(),
            voiceName
          });
        }

        setAudioMeditations(dbAudioMeditations ?? []);

        setIsMoreMeditationsAvailable(dbAudioMeditations.length === pageSize)

        setLoading(false);
      });
    return () => unsub();
  }, [currentRoles, pageNo, pageSize, sortingField, sortingOrder, searchText]);


  return { audioMeditations, loading, isMoreMeditationsAvailable, lastSnapShot: lastSnapShot.current };
}

export function useAudioMeditation(id: string) {
  const [loading, setLoading] = useState<boolean>(false);
  const [audioMeditation, setAudioMeditation] = useState<AudioMeditationGenerationType>()

  useEffect(() => {
    if (!id?.length) {
      return
    }

    setLoading(true)
    const unsub = db
      .collection("audio_meditations")
      .doc(id)
      .onSnapshot(
        (doc) => {
          if (doc?.exists) {
            setAudioMeditation({ ...doc.data(), id: doc.id } as AudioMeditationGenerationType)
          }
          setLoading(false)
        }
      );

    return () => unsub();
  }, [id]);

  return { audioMeditation, loading }
}

export const deleteAudioMeditationByIdFromDB = async (
  mediationId: string
): Promise<void> => {
  return db
    .collection("audio_meditations")
    .doc(mediationId)
    .delete()
};

export const getMeditationVoiceByIdFromDB = async (
  voiceId: string
): Promise<string | null> => {
  return db
    .collection("meditation_voices")
    .where("voiceID", "==", voiceId)
    .get()
    .then((snapshot) => {
      if (snapshot.docs?.length) {
        const { name } = snapshot.docs?.[0]?.data() as MeditationVoiceType;
        return name
      }

      return null
    });
};

export const createOrUpdateManualAudioMeditation = async (
  audioMeditationCreationRequest: ManualAudioMeditationCreationRequestType
): Promise<void> => {
  return db
    .collection("audio_meditations")
    .doc(audioMeditationCreationRequest.documentId)
    .set({
        title: audioMeditationCreationRequest.title,
        sourceText: audioMeditationCreationRequest.sourceText ?? "",
        sourceLang: audioMeditationCreationRequest.sourceLang,
        isActive: audioMeditationCreationRequest.isActive,
        voiceConfig: {
          id: audioMeditationCreationRequest.voiceId
        },
        isManualCreated: audioMeditationCreationRequest.isManualCreated,
        meditationId: audioMeditationCreationRequest.meditationId,
        targetLang: audioMeditationCreationRequest.targetLang,
        targetLanguageVoiceUrl: audioMeditationCreationRequest.audioUrl,
        createdAt: audioMeditationCreationRequest.createdAt,
        status: AudioMeditationGenerationStatus.SUCCEEDED
      },
      { merge: true }
    );
};

export function useApprovedBy(audioMeditation?: AudioMeditationGenerationType) {
  const { user: loggedInUser } = useAuth();

  const [approving, setApproving] = useState<boolean>(false);
  const [approvedByName, setApprovedByName] = useState<string>("");

  const handleApproveToggle = async () => {
    try {
      setApproving(true);

      const newApprovedBy = audioMeditation?.approvedBy ? "" : (loggedInUser?.uid ?? "")
      await approveAudioMeditation(
        audioMeditation?.id ?? "",
        newApprovedBy
      )
    } catch (e: ErrorException) {
      console.error("fromUseAudioApproval: error while approving: ", e?.message)
    } finally {
      setApproving(false)
    }
  }

  useEffect(() => {
    async function getUserById() {
      const approvedBy = audioMeditation?.approvedBy
      if (!approvedBy) {
        setApprovedByName("")

        return
      }

      const userById = await searchUserById(approvedBy)
      setApprovedByName(userById?.fullname ?? "")
    }

    getUserById();
  }, [audioMeditation?.approvedBy])

  return {
    handleApproveToggle,
    approving,
    approvedByName,
  }
}

export async function approveAudioMeditation(documentId: string, approvedBy: string) {
  if (!documentId) {
    return
  }

  return db
    .collection("audio_meditations")
    .doc(documentId)
    .update({ approvedBy })
}

export async function updateAudioMeditationWithoutGeneratingAudio(documentId: string, updatedFields: AudioMeditationWithoutGenerationType) {
  if (!documentId) {
    return
  }

  return db
    .collection("audio_meditations")
    .doc(documentId)
    .update(updatedFields)
}

export const hasPermissionToAudioMeditation = (roles: Array<string> = []): AudioContentAccessType => {
  if (roles.includes(ROLES.SUPER_ADMIN) || roles.includes(ROLES.AUDIO_CRUD)) {
    return {
      accessToAllLanguages: true,
      accessLanguages: []
    }
  }

  const audioRoles = roles.filter(role => role.startsWith(ROLES.AUDIO_CRUD))
  if (!audioRoles.length) {
    return {
      accessToAllLanguages: false,
      accessLanguages: []
    }
  }

  const languageCodes = audioRoles.map(extractLanguageCodeFromAudioRole)

  return {
    accessToAllLanguages: false,
    accessLanguages: languageCodes
  }
}

const extractLanguageCodeFromAudioRole = (languageRoleString: string) => {
  // Get the substring after "AUDIO_CRUD_"
  const separator = `${ ROLES.AUDIO_CRUD }_`
  const langCode = languageRoleString.slice(languageRoleString.indexOf(separator) + separator.length);

  return langCode.toUpperCase();
}