import { useEffect, useState } from "react";

import { db } from "../contexts/FirebaseContext";
import { MultilingualLocalesTypes } from "../types/translations";
import useAuth from "./useAuth";
import { ErrorException } from "../types/settings";

export default function useMultiLingualLocales() {
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [localeList, setLocaleList] = useState<Array<MultilingualLocalesTypes>>([]);

  useEffect(() => {
    async function getMultiLingualLocales() {
      try {
        setLoading(true);
        const dbMultilingualDocReferences = await db.collection("multilingual_locales")
          .get();

        const multilingualDocs = dbMultilingualDocReferences.docs.map((snapshot) => {
          const snapshotData = snapshot.data() as MultilingualLocalesTypes;
          return {
            name: snapshotData.name,
            locale: snapshotData.locale
          }
        })

        setLocaleList(multilingualDocs);
        setLoading(false);
      } catch (e: ErrorException) {
        setLoading(false);
        console.error(e);
      }
    }

    if (isAuthenticated) {
      getMultiLingualLocales();
    }

  }, [isAuthenticated]);

  return { loading, localeList }
}