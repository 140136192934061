// material
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  createFilterOptions,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// utils
//
import { useSnackbar } from "notistack";
import { Form, FormikProvider, useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import { LoadingButton } from "@mui/lab";

import { array, boolean, object, string, number } from "yup";
import {
  ContentFromFirestore,
  ContentUnitCategories,
  DisplayType,
  ModuleFromFirestore,
  ModuleWithCustomisedFields,
  Preview,
  ParentModuleFromFirestore
} from "models/ContentUnit";
import React, { useEffect, useState } from "react";
import TagsAutocomplete from "../content/TagsAutocomplete";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { pushItem } from "../../../utils/firebase";
import SortableAutocomplete from "../../SortableAutocomplete";
import ThemeAutocomplete from "../content/ThemeAutocomplete";
import { SupportedLanguages } from "../../../types/invitation/code";
import { Locale } from "../../../types/user";
import DeleteButton from "../content/DeleteButton";
import ReorderCustomisedChildrenButton from "../content/ReorderCustomisedChildrenButton";
import { MultilingualLocalesTypes } from "../../../types/translations";
import useMultiLingualLocales from "../../../hooks/useMultiLingualContentGenerationProgress";
import { ContentCollection } from "../../../utils/firebase/contentUtils";
import MultiLingualGenerationProgress from "../content/MultiLingualGenerationProgress";
import { MULTI_LINGUAL_OPTION } from "../../LanguageSelectionDropDown";
import ContentModuleFilterTagsDropdown from "../content/ContentModuleFilterTagsDropdown";
import { ErrorException } from "../../../types/settings";
import {CATEGORIES_OPTIONS} from "../user/CounsellorSearchFilterForm";

// ----------------------------------------------------------------------

const ModulesContentSchema = object({
  id: string().required(),
  type: string().required(),
  locale: string().required(),
  title: string().required(),
  assessmentBotContentId: string().optional(),
  description: string().optional(),
  duration: string().optional(),
  isFeatured: boolean().optional(),
  overviewCards: array(
    object().shape({
      tabTitle: string(),
      description: string(),
    })
  ),
  tags: array(string()),
  recommendationIds: array(string()).optional(),
  customisedChildren: array(
    object().shape({
      contentId: string().required(),
      description: string().optional(),
      displayStyle: string().optional(),
      title: string().optional(),
    })
  ).optional(),
  childrenIds: array(string()).optional(),
  themeId: string().optional(),
  isAIEnhancedModule: boolean().optional(),
  filters: array(string()).optional(),
  boosted: number().optional(),
  area: array(string()).optional(),
});

type PartialContentFromFirestore = Partial<
  ContentFromFirestore | ModuleFromFirestore | ParentModuleFromFirestore
>;

type Props = {
  data?: ModuleFromFirestore | ParentModuleFromFirestore;
  moduleId?: string;
  isParentModule?: boolean;
  selectedMultilingualLanguages: MultilingualLocalesTypes[];
  selectedLanguage: SupportedLanguages | string;
  contentUnitOptions: Array<string>;
  onSubmit: (
    doc: PartialContentFromFirestore,
    locale: SupportedLanguages | string,
    selectedMultilingualLanguages?: MultilingualLocalesTypes[],
    isOnlySaveNonTranslatedFields?: boolean
  ) => void;
  deleteModule: (id: string, selectedLanguage: SupportedLanguages | string) => void;
};

export default function ModulesContentForm({
  isParentModule,
  data,
  moduleId,
  selectedLanguage,
  contentUnitOptions,
  onSubmit,
  deleteModule,
selectedMultilingualLanguages
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [enableFormView, setEnableFormView] = useState<boolean>(false);
  const [recommendationIds, setRecommendationIds] = useState<Array<string>>([]);
  const [childrenIds, setChildrenIds] = useState<Array<string>>([]);
  const [selectedThemes, setSelectedThemes] = useState<string>();
  const filter = createFilterOptions<string>();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data ?? {
      id: moduleId ?? "",
      type: ContentUnitCategories.PRACTICE,
      locale: selectedLanguage,
      title: "",
      description: "",
      duration: "",
      sortPlace: 0,
      assessmentBotContentId: "",
      isFeatured: false,
      tags: [],
      recommendationIds: [],
      customisedChildren: [],
      linkedModuleId: "",
      childrenIds: [],
      filters: [],
      themeId: "",
      landbotId: "",
      screenId: "",
      screenParams: "",
      isAIEnhancedModule: false,
      boosted: 0,
      area: []
    },
    validationSchema: ModulesContentSchema,
    onSubmit: async (values) => {
      try {
        if (window.confirm(`Are you sure to save/update changes?`)) {
          enqueueSnackbar(`Updating ${ values.title }`);

          const doc = {
            ...values,
            tags: selectedTags,
            themeId: selectedThemes,
            childrenIds: childrenIds,
            locale: values.locale as Locale,
          };

          setEnableFormView(false)
          await onSubmit(doc, selectedLanguage, selectedMultilingualLanguages);

          enqueueSnackbar("Saved successfully!", { variant: "success" });
        }
      } catch (error: ErrorException) {
        console.error("Failed to save module content", error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    getFieldProps,
    handleReset,
    values,
  } = formik;

  useEffect(()=> {
    if (enableFormView) {
      setEnableFormView(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage, moduleId])


  const onSaveNonTranslatedFields = async () => {
    enqueueSnackbar(`Updating ${ values.title }`);

    const doc = {
      ...values,
      tags: selectedTags,
      themeId: selectedThemes,
      childrenIds: childrenIds,
      locale: values.locale as Locale,
    };

    setEnableFormView(false)
    await onSubmit(doc, selectedLanguage, selectedMultilingualLanguages, true);
  }

  const {
    targetMultilingualLocales,
    processedMultilingualLocales
  } = useMultiLingualLocales(selectedLanguage, data?.id ?? getFieldProps("id").value ?? "", isParentModule ? ContentCollection.PARENT_MODULES: ContentCollection.MODULES);
  const targetLocalesSize = targetMultilingualLocales?.length ?? 0
  const processedLocalesSize = processedMultilingualLocales?.length ?? 0

  const previewKey = "overviewCards";
  const overviewCardValue = getFieldProps(previewKey).value || [];
  const addOverviewTab = () => {
    setFieldValue(
      previewKey,
      pushItem(
        {
          tabTitle: "",
          description: "",
        },
        overviewCardValue || []
      )
    );
  };

  const customisedChildrenValue =
    getFieldProps("customisedChildren").value ||
    ([] as ModuleWithCustomisedFields[]);
  const addCustomisedChild = () => {
    setFieldValue(
      "customisedChildren",
      pushItem(
        {
          contentId: "",
          description: "",
          displayStyle: "",
          title: "",
        },
        customisedChildrenValue
      )
    );
  };

  const removeCustomisedChild = (index: number) => {
    setFieldValue(
      "customisedChildren",
      customisedChildrenValue.toSpliced(index, 1)
    );
  };

  const onSwitchToFormView = () => {
    setEnableFormView(true)
  }

  const updateCustomisedChildren = (
    callback: (
      prevChildIDs: ModuleWithCustomisedFields[]
    ) => ModuleWithCustomisedFields[]
  ) => {
    const prevChildIDs = customisedChildrenValue;
    const newChildIDs = callback(prevChildIDs);
    setFieldValue("customisedChildren", newChildIDs);
  };

  const areChildrenUpdated = touched.customisedChildren
    ? true
    : data?.customisedChildren?.length !== customisedChildrenValue.length;

  const displayStyleOptions = Object.values(DisplayType);

  useEffect(() => {
    if (data) {
      setSelectedTags(data.tags);
      setRecommendationIds(data.recommendationIds ?? []);
      setChildrenIds(data.childrenIds);
      setSelectedThemes(data.themeId);
    } else if (data === undefined) {
      // handle if user is creating a new module
      setSelectedTags([]);
      setRecommendationIds([]);
      setChildrenIds([]);
      setSelectedThemes("");
    }
  }, [data]);

  if (!enableFormView && targetLocalesSize && processedMultilingualLocales && targetLocalesSize !== processedLocalesSize) {
    return <MultiLingualGenerationProgress processedLocalesSize={ processedLocalesSize }
                                           onSwitchToFormView={onSwitchToFormView}
                                           targetLocalesSize={targetLocalesSize}
                                           processedMultilingualLocales={ processedMultilingualLocales }/>
  }

  return (
      <Grid container spacing={ 3 }>
        <Grid item xs={ 12 }>
          <Card sx={ { p: 3 } }>
            <FormikProvider value={ formik }>
              <Form noValidate onSubmit={ handleSubmit }>
                <fieldset style={ { border: "none" } }>
                  <FocusError formik={ formik }/>
                  <Stack spacing={ 3 }>
                    <Stack
                      direction={ { xs: "column" } }
                      spacing={ { xs: 3, sm: 2 } }
                    >
                      <Stack spacing={ 3 }>
                        <TextField
                          fullWidth
                          label="Id"
                          { ...getFieldProps("id") }
                          error={ Boolean(touched.id && errors.id) }
                          helperText={ touched.id && errors.id }
                        />

                        <TextField
                          select
                          label={ "Modules Content Type" }
                          placeholder={ "Modules Content Type" }
                          style={ { marginBottom: 15 } }
                          SelectProps={ { native: true } }
                          { ...getFieldProps("type") }
                        >
                          { Object.entries(ContentUnitCategories).map(
                            ([key, value]) => {
                              return (
                                <option key={ key } value={ value }>
                                  { value }
                                </option>
                              );
                            }
                          ) }
                        </TextField>

                        <TextField
                          fullWidth
                          label="Title"
                          { ...getFieldProps("title") }
                          error={ Boolean(touched.title && errors.title) }
                          helperText={ touched.title && errors.title }
                        />

                        <TextField
                          fullWidth
                          label="Description"
                          { ...getFieldProps("description") }
                          error={ Boolean(
                            touched.description && errors.description
                          ) }
                          helperText={ touched.description && errors.description }
                        />

                        <TextField
                          fullWidth
                          label="Locale"
                          { ...getFieldProps("locale") }
                          error={ Boolean(
                            touched.locale && errors.locale
                          ) }
                          helperText={ touched.locale && errors.locale }
                        />

                        {
                          values.type === ContentUnitCategories.MODULE_LINK && (
                          <TextField
                            fullWidth
                            label="Linked Module ID"
                            {...getFieldProps("linkedModuleId")}
                            error={Boolean(
                              touched.linkedModuleId && errors.linkedModuleId
                            )}
                            helperText={
                              touched.linkedModuleId && errors.linkedModuleId
                            }
                          />)
                        }

                        {
                          values.type === ContentUnitCategories.SCREEN_LINK &&
                        (
                          <>
                            <TextField
                              fullWidth
                              label="Screen ID"
                              {...getFieldProps("screenId")}
                              error={Boolean(
                                touched.screenId && errors.screenId
                              )}
                              helperText={
                                touched.screenId && errors.screenId
                              }
                            />
                            <TextField
                              fullWidth
                              label="Screen Params"
                              {...getFieldProps("screenParams")}
                              error={Boolean(
                                touched.screenParams && errors.screenParams
                              )}
                              helperText={
                                touched.screenParams && errors.screenParams
                              }
                            />
                          </>
                        )
                      }

                      <TextField
                        fullWidth
                        label="Assessment Bot Content Id"
                        {...getFieldProps("assessmentBotContentId")}
                        error={Boolean(
                          touched.assessmentBotContentId &&
                            errors.assessmentBotContentId
                          ) }
                          helperText={
                            touched.assessmentBotContentId &&
                            errors.assessmentBotContentId
                          }
                        />

                        <TextField
                          fullWidth
                          label="Duration"
                          { ...getFieldProps("duration") }
                          error={ Boolean(touched?.duration && errors?.duration) }
                          helperText={ touched?.duration && errors?.duration }
                        />

                        <TextField
                          fullWidth
                          label="Sort Place"
                          type="number"
                          InputLabelProps={ { shrink: true } }
                          { ...getFieldProps("sortPlace") }
                          error={ Boolean(touched?.sortPlace && errors?.sortPlace) }
                          helperText={ touched?.sortPlace && errors?.sortPlace }
                        />

                        <ContentModuleFilterTagsDropdown
                            getFieldProps={getFieldProps}
                            setFieldValue={setFieldValue}
                        />

                        <TextField
                            fullWidth
                            label="Boosted"
                            {...getFieldProps("boosted")}
                            error={Boolean(touched.boosted && errors.boosted)}
                            helperText={touched.boosted && errors.boosted}
                            type="number"
                        />

                        <Autocomplete
                            multiple
                            freeSolo
                            options={CATEGORIES_OPTIONS}
                            getOptionLabel={(option) => (typeof option === "string" ? option : option.label)} // Ensure correct label display
                            value={Array.isArray(formik.values.area) ? formik.values.area : []} // Ensure it's always an array
                            onChange={(_, selectedAreas) => {
                              const selectedValues = selectedAreas.map((option) =>
                                  typeof option === "string" ? option : option.value
                              );
                              setFieldValue("area", selectedValues);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} fullWidth label="Area" variant="outlined" />
                            )}
                        />

                        <FormControlLabel
                          label="Is featured"
                          { ...getFieldProps("isFeatured") }
                          control={
                            <Checkbox checked={ Boolean(values.isFeatured) }/>
                          }
                        />

                        <ThemeAutocomplete
                          showModulesThemeIds
                          selectedThemes={ selectedThemes ?? "" }
                          setSelectedThemes={ setSelectedThemes }
                        />

                        <TagsAutocomplete
                          selectedTags={ selectedTags }
                          setSelectedTags={ setSelectedTags }
                        />


                        <FormControlLabel
                            label="Is AI Enahnced Module"
                            { ...getFieldProps("isAIEnhancedModule") }
                            control={
                              <Checkbox checked={ Boolean(values.isAIEnhancedModule) }/>
                            }
                        />

                        <Stack>
                          <Divider orientation={ "horizontal" } variant={ "middle" }>
                            <Typography variant="h6">Children Modules</Typography>
                          </Divider>
                          <Grid container>
                            { customisedChildrenValue?.map(
                              (
                                item: ModuleWithCustomisedFields,
                                index: number
                              ) => {
                                let childLabel: string = "Customised Child";
                                if (customisedChildrenValue?.length > 1) {
                                  childLabel = `${ childLabel } #${ index + 1 }`;
                                }
                                return (
                                  <Grid key={ index } item xs={ 12 } md={ 6 }>
                                    <Paper elevation={ 4 } sx={ { p: 2, m: 2 } }>
                                      <Stack spacing={ 3 }>
                                        <Stack
                                          display={ "flex" }
                                          direction={ "row" }
                                          justifyContent={ "space-between" }
                                          alignItems={ "center" }
                                        >
                                          <Typography variant="h6">
                                            { childLabel }
                                          </Typography>
                                          <IconButton
                                            color="primary"
                                            onClick={ () => {
                                              removeCustomisedChild(index);
                                            } }
                                          >
                                            <DeleteIcon/>
                                          </IconButton>
                                        </Stack>
                                        <Autocomplete
                                          options={ contentUnitOptions ?? [] }
                                          value={
                                            customisedChildrenValue[index]
                                              .contentId
                                          }
                                          renderInput={ (params) => (
                                            <TextField
                                              { ...params }
                                              label="Content Id"
                                              defaultValue={ "" }
                                              { ...getFieldProps(
                                                `customisedChildren.${ index }.contentId`
                                              ) }
                                              type={ "text" }
                                              error={ Boolean(
                                                touched.customisedChildren &&
                                                errors?.customisedChildren
                                              ) }
                                            />
                                          ) }
                                          onChange={ (_, newValue) => {
                                            setFieldValue(
                                              `customisedChildren.${ index }.contentId`,
                                              newValue
                                            );
                                          } }
                                        />
                                        <TextField
                                          fullWidth
                                          sx={ { width: "100%" } }
                                          label={ "Title" }
                                          defaultValue={ "" }
                                          { ...getFieldProps(
                                            `customisedChildren.${ index }.title`
                                          ) }
                                          type={ "text" }
                                        />
                                        <TextField
                                          sx={ { width: "100%" } }
                                          multiline
                                          fullWidth
                                          label={ "Description" }
                                          { ...getFieldProps(
                                            `customisedChildren.${ index }.description`
                                          ) }
                                          defaultValue={ "" }
                                          type={ "text" }
                                        />
                                        <Stack>
                                          <FormLabel>Display style</FormLabel>
                                          <RadioGroup
                                            row
                                            sx={ {} }
                                            { ...getFieldProps(
                                              `customisedChildren.${ index }.displayStyle`
                                            ) }
                                          >
                                            <FormControlLabel
                                              value={ "" }
                                              control={ <Radio/> }
                                              label={ "none" }
                                            />
                                            { displayStyleOptions.map(
                                              (option, key) => (
                                                <FormControlLabel
                                                  key={ key }
                                                  value={ option }
                                                  control={ <Radio/> }
                                                  label={ option }
                                                />
                                              )
                                            ) }
                                          </RadioGroup>
                                        </Stack>
                                      </Stack>
                                    </Paper>
                                  </Grid>
                                );
                              }
                            ) }
                          </Grid>
                          <Stack
                            direction={ { xs: "column", sm: "row" } }
                            spacing={ 1 }
                            sx={ {
                              my: 1,
                              justifyContent: "space-between",
                              alignItems: "center",
                            } }
                          >
                            <Stack
                              direction={ { xs: "column", sm: "row" } }
                              spacing={ 1 }
                            >
                              <Button
                                color="success"
                                variant="contained"
                                endIcon={ <AddIcon/> }
                                onClick={ addCustomisedChild }
                              >
                                Add Customised Child
                              </Button>
                              { customisedChildrenValue.length > 1 && (
                                <ReorderCustomisedChildrenButton
                                  currentChildren={ customisedChildrenValue }
                                  setNewChildrenOrder={ (prevOrder) =>
                                    updateCustomisedChildren(prevOrder)
                                  }
                                />
                              ) }
                            </Stack>
                            { areChildrenUpdated && (
                              <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={ isSubmitting }
                              >
                                Save Changes
                              </LoadingButton>
                            ) }
                          </Stack>
                        </Stack>
                        <SortableAutocomplete
                          selectedIds={ childrenIds }
                          setSelectedIds={ (newSelectedIds) =>
                            setChildrenIds(newSelectedIds(childrenIds))
                          }
                          contentUnitOptions={ contentUnitOptions }
                        />
                        <Divider sx={ { mb: 1 } }/>
                        <Autocomplete
                          multiple
                          options={ contentUnitOptions ?? [] }
                          value={ recommendationIds }
                          getOptionLabel={ (option) => option }
                          filterSelectedOptions
                          renderInput={ (params) => (
                            <TextField
                              { ...params }
                              label="Recommendation Ids"
                              placeholder="Recommendation Ids"
                            />
                          ) }
                          onChange={ (_, newValue) => {
                            setRecommendationIds(newValue);
                            setFieldValue("recommendationIds", newValue);
                          } }
                          filterOptions={ (options, params) => {
                            const filtered = filter(options, params);

                            if (params.inputValue !== "") {
                              filtered.push(params.inputValue);
                            }

                            return filtered;
                          } }
                        />

                        { overviewCardValue?.map(
                          (item: Preview, index: number) => {
                            let titleLabel: string = "Overview Tab Title";
                            let descriptionLabel: string =
                              "Overview Tab Description";
                            if (overviewCardValue?.length > 1) {
                              titleLabel = `${ titleLabel }# ${ index + 1 }`;
                              descriptionLabel = `${ descriptionLabel }# ${
                                index + 1
                              }`;
                            }
                            return (
                              <Stack key={ index } spacing={ 3 }>
                                <TextField
                                  sx={ { width: "100%" } }
                                  label={ titleLabel }
                                  defaultValue={ "" }
                                  { ...getFieldProps(
                                    `overviewCards.${ index }.tabTitle`
                                  ) }
                                  type={ "text" }
                                />

                                <TextField
                                  sx={ { width: "100%" } }
                                  multiline
                                  label={ descriptionLabel }
                                  { ...getFieldProps(
                                    `overviewCards.${ index }.description`
                                  ) }
                                  defaultValue={ "" }
                                  type={ "text" }
                                />
                              </Stack>
                            );
                          }
                        ) }

                        <Stack
                          direction={ { xs: "column", sm: "row" } }
                          spacing={ 1 }
                          sx={ {
                            mt: 1,
                            justifyContent: "space-between",
                            alignItems: "center",
                          } }
                        >
                          <Button
                            color="success"
                            variant="contained"
                            endIcon={ <AddIcon/> }
                            onClick={ addOverviewTab }
                          >
                            Add Overview Tab
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Box
                    sx={ { mt: 3, display: "flex", justifyContent: "flex-end" } }
                  >
                    <Button sx={ { mr: 1 } } onClick={ handleReset } type="button">
                      Reset
                    </Button>
                    { moduleId && (
                      <DeleteButton
                        deleteItem={ () => {
                          deleteModule(moduleId, selectedLanguage);
                        } }
                      />
                    ) }

                    { moduleId && selectedLanguage === MULTI_LINGUAL_OPTION &&
                        <LoadingButton
                            sx={ { mr: 2 } }
                            type="button"
                            variant="contained"
                            onClick={ onSaveNonTranslatedFields }
                            loading={ isSubmitting }>
                            Save Non-Translated Fields Changes
                        </LoadingButton>
                    }

                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={ isSubmitting }
                    >
                      Save Changes
                    </LoadingButton>
                  </Box>
                </fieldset>
              </Form>
            </FormikProvider>
          </Card>
        </Grid>
      </Grid>
  );
}
