import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// material
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { AIPrompt } from "../../../types/aiPrompts";
import { createOrUpdateAIPrompt } from "../../../utils/firebase/aiPromptsUtils";
import { useGetTemplates } from "../../../hooks/useGetTemplates";
import { getOpenAITokenCount } from "../../../utils/firebase/cloudFunctions";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AIModelTypes, AIModelType } from "../../../utils/constants";
import { ErrorException } from "../../../types/settings";

// ----------------------------------------------------------------------

type AIPromptFormFormProps = {
  isEdit: boolean;
  isView?: boolean;
  isNew?: boolean;
  prompt: AIPrompt;
  multilineContent?: boolean;
};

export default function AIPromptForm({
  isNew,
  isView,
  isEdit,
  prompt,
  multilineContent,
}: AIPromptFormFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { templates } = useGetTemplates();

  const PromptSchema = Yup.object().shape({
    id: Yup.string().required(),
    template: Yup.string().when("aiModelType", {
      is: (val: string) => val === AIModelType.OPEN_AI,
      then: Yup.string().required("Template is required for OpenAI"),
      otherwise: Yup.string().optional(),
    }),
    content: Yup.string().when("aiModelType", {
      is: (val: string) => val === AIModelType.OPEN_AI,
      then: Yup.string().required("Content is required for OpenAI"),
      otherwise: Yup.string().optional(),
    }),
    model: Yup.string().optional(),
    temperature: Yup.number().optional(),
    introMessage: Yup.string().optional(),
    overwriteIntroMessage: Yup.boolean().optional(),
    contentToBeEmbedded: Yup.string().optional(),
    aiModelType: Yup.string().required(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: prompt?.id ?? "",
      template: prompt?.template ?? "",
      content: prompt?.content ?? "",
      model: prompt?.model ?? "",
      temperature: prompt?.temperature ?? 0,
      contentToBeEmbedded: prompt?.contentToBeEmbedded ?? "",
      aiModelType: prompt?.aiModelType ?? AIModelType.OPEN_AI,
    },

    validationSchema: PromptSchema,
    onSubmit: async (values) => {
      let successMessage = "Successfully saved changes";
      let errorMessage = "Failed to save changes";
      if (isNew) {
        successMessage = "Prompt Created Successfully";
        errorMessage = "Failed to create prompt";
      }
      try {
        await createOrUpdateAIPrompt(values.id, values);
        navigate(-1);
        enqueueSnackbar(successMessage, { variant: "success" });
      } catch (e: ErrorException) {
        console.error("FailedToCreatePrompt", e);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const {
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
  } = formik;

  const isAnthropicAIModel = values?.aiModelType === AIModelType.ANTHROPIC;

  let submitBtnTitle = "Create Prompt";
  if (isView) {
    submitBtnTitle = "View Only";
  } else if (isEdit) {
    submitBtnTitle = "Save Changes";
  }

  const fetchOpenAITokenCount = async () => {
    try {
      setIsLoading(true);
      const { template, model, content } = values;

      if (!template || !model || !content) {
        enqueueSnackbar(
          "Template, Model and Content is required to retrieve Token Count",
          { variant: "error" }
        );
        return;
      }
      const response = await getOpenAITokenCount({
        templateId: template,
        model,
        content,
      });

      alert("Token Count is: " + response?.data ?? "0");
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const AIModel = getFieldProps("aiModelType").value ?? "";
    let modelValue = "";

    if (AIModel === AIModelType.ANTHROPIC) {
      modelValue = "claude-3-5-sonnet@20240620";
    }

    if (isNew) {
      setFieldValue("model", modelValue);
    }
    // Disable the missing dependency warning due to multiple re-renders caused by useEffect dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFieldProps("aiModelType").value, isNew]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <fieldset disabled={isView} style={{ border: "none" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label="Id"
                    {...getFieldProps("id")}
                    error={Boolean(touched.id && errors.id)}
                    helperText={touched.id && errors.id}
                  />

                  <FormControl fullWidth>
                    <InputLabel id="open-ai-select-label">
                      Select AI Model
                    </InputLabel>
                    <Select
                      label="Select AI Model"
                      disabled={isView}
                      defaultValue={AIModelType.OPEN_AI}
                      {...getFieldProps("aiModelType")}
                    >
                      {AIModelTypes.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    label="Model"
                    {...getFieldProps("model")}
                    error={Boolean(touched.model && errors.model)}
                    helperText={touched.model && errors.model}
                  />

                  <TextField
                    fullWidth
                    label="Temperature"
                    inputMode={"numeric"}
                    {...getFieldProps("temperature")}
                    error={Boolean(touched.temperature && errors.temperature)}
                    helperText={touched.temperature && errors.temperature}
                  />

                  <Autocomplete
                    id="template"
                    options={templates.map((template) => template.id)}
                    value={values.template}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} label="Select template" />
                    )}
                    onChange={(_, newValue) => {
                      setFieldValue("template", newValue);
                    }}
                    noOptionsText="No options"
                  />

                  <TextField
                    id={"content"}
                    fullWidth
                    multiline={multilineContent}
                    label="Content"
                    {...getFieldProps("content")}
                    error={Boolean(touched.content && errors.content)}
                    helperText={touched.content && errors.content}
                  />

                  <TextField
                    id={"contentToBeEmbedded"}
                    fullWidth
                    multiline={multilineContent}
                    label="Content to be Embedded"
                    {...getFieldProps("contentToBeEmbedded")}
                    error={Boolean(
                      touched.contentToBeEmbedded && errors.contentToBeEmbedded
                    )}
                    helperText={
                      touched.contentToBeEmbedded && errors.contentToBeEmbedded
                    }
                  />

                  <Box
                    sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                  >
                    {!Boolean(isView) && !isAnthropicAIModel && (
                      <Tooltip
                        title="It only retrieves the OpenAI token count for the Content generated after merging relevant Snippets and Templates into a complete prompt"
                        placement="top"
                        arrow
                      >
                        <LoadingButton
                          type="button"
                          variant="contained"
                          onClick={fetchOpenAITokenCount}
                          loading={isSubmitting || isLoading}
                          sx={{ mr: 4 }}
                        >
                          Get OpenAI Token Content Count
                        </LoadingButton>
                      </Tooltip>
                    )}

                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting || isLoading}
                    >
                      {submitBtnTitle}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </fieldset>
      </Form>
    </FormikProvider>
  );
}
