import { useEffect, useState } from 'react';

// types
import { Changelog } from '../../types/changelog';
import { db } from '../../contexts/FirebaseContext';
import { ErrorException } from "../../types/settings";

/**
 * custom hook to fetch changelog for specific collection and specific ID.
 * @param {string} fireCollection collection name of changelog, for which changelogs are to fetch.
 * @param {string} docId optional documentId of which changes are to be listened,
 * if not provided all the changes in {fireCollection} are listened
 */
export function useChangelog(
  fireCollection: string,
  docId?: string
): {
  changelog: Array<Changelog>;
  loading: boolean;
} {
  const [changelog, setChangelog] = useState<Array<Changelog>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  let unsubscribe: any = null;

  useEffect(() => {
    fetchChangelog(fireCollection, docId);
    // eslint-disable-next-line
  }, [fireCollection, docId]);

  async function fetchChangelog(_collection: string, _docId: string | undefined) {
    try {
      if (_collection && _docId) {
        if (unsubscribe && typeof unsubscribe === 'function') {
          await unsubscribe?.();
        }

        db.collection(_collection)
          .doc(_docId)
          .collection('changelog')
          .onSnapshot(async (snapshot) => {
            const docs = ((await snapshot.docs?.map?.((item) => item?.data())) ??
              []) as Array<Changelog>;
            setChangelog(docs);
          });
      } else if (_collection) {
        if (unsubscribe && typeof unsubscribe === 'function') {
          await unsubscribe?.();
        }

        db.collection(_collection).onSnapshot(async (snapshot) => {
          const docs = ((await snapshot.docs?.map?.((item) => item?.data())) ??
            []) as Array<Changelog>;
          setChangelog(docs);
        });
      } else {
        console.error('colleciton or docId is missing.');
      }
    } catch (e: ErrorException) {
      console.error('errorInuseChangeLog: ', e);
    } finally {
      setLoading(false);
    }
  }

  return {
    changelog,
    loading
  };
}
