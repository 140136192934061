import { useState } from "react";

import firebase from "firebase/compat/app";

export function useMultiFactorRegistration(auth: firebase.auth.Auth) {
  const [verificationId, setVerificationId] = useState<string>("");
  const currentUser = auth.currentUser;

  const throwNotAuthenticatedError = () => {
    // TODO use AuthErrorCode.NULL_USER
    throw new Error("Not authenticated");
  };

  async function startRegistration(
    phoneNumber: Readonly<string>,
    recaptchaVerifier: Readonly<firebase.auth.ApplicationVerifier>
  ) {
    if (!currentUser) {
      return throwNotAuthenticatedError();
    }

    const multiFactorSession = await currentUser.multiFactor.getSession();
    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    const id = await phoneAuthProvider.verifyPhoneNumber(
      {
        phoneNumber,
        session: multiFactorSession,
      },
      recaptchaVerifier
    );

    setVerificationId(id);
  }

  async function finishRegistration(
    verificationCode: Readonly<string>,
    hint: Readonly<string>
  ) {
    if (!currentUser) {
      return throwNotAuthenticatedError();
    }

    const cred = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    const assertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    await currentUser.multiFactor.enroll(assertion, hint);
  }

  return {
    startRegistration,
    finishRegistration,
  };
}
