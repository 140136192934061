import firebase from "firebase/compat/app";
import * as CloudFunctions from "utils/firebase/cloudFunctions";
import { db } from "contexts/FirebaseContext";
import { useEffect, useState } from "react";

import { InvitationCode } from "../../types/invitation/code";
import { v4 } from "uuid";
import { createCode } from "utils/firebase/cloudFunctions";
import { ErrorException } from "../../types/settings";

export function useListCodes() {
  const [codes, setCodes] = useState<InvitationCode[]>([]);
  useEffect(() => {
    const unsub = db.collection("invitation_codes").onSnapshot(async (snap) => {
      const docs = snap.docs.map((doc) => doc.data());
      setCodes(docs as InvitationCode[]);
    });
    return () => unsub();
  }, []);

  return codes;
}

export async function getCode(
  code: string
): Promise<InvitationCode | undefined> {
  if (!code) {
    throw new Error("required param {code} is missing.");
  }

  const doc = await firebase
    .firestore()
    .collection("invitation_codes")
    .doc(code.toUpperCase())
    .get();

  if (!doc.exists) throw new Error("No code with this id exists");
  return assignIDs(doc?.data?.()) as InvitationCode;
}

export const assignIDs = (obj: any) => {
  Object?.keys?.(obj || {})?.forEach((key) => {
    if (typeof obj[key] === "object") {
      if (!obj.id) {
        obj.id = v4();
      }
      assignIDs(obj[key]);
    }
  });
  return obj;
};

export function prependItem(value: any, array: Array<any>) {
  const newArray = array.slice();
  newArray.unshift(value);
  return newArray;
}

export function pushItem(value: any, array: Array<any>) {
  const newArray = array.slice();
  newArray.push(value);
  return newArray;
}

export async function getUsersWithCode(
  code: string,
  queryPathName: "invitationCode" | "registrationCode"
) {
  try {
    const users = await CloudFunctions.getUsers({
      invitationCode: code,
      queryPathName,
    });

    // @ts-ignore
    return users?.data || [];
  } catch (e: ErrorException) {
    console.log("errorInuseUserWithCode: ", e);
    return [];
  }
}

export async function getUserRoles(data: { id?: string; email?: string, isUsingApiV2?: boolean }) {
  try {
    const res = await CloudFunctions.getUserRoles(data);
    // @ts-ignore
    return res?.data || [];
  } catch (e: ErrorException) {
    console.log(e);
    return [];
  }
}

// export for testing
export function parseFinalCodeData(data: Partial<InvitationCode>) {
  const finalData: any = {
    ...data,
    invitationCode: data?.code,
  };

  if (finalData?.expiresAt) {
    finalData.expiresAt =
      finalData?.expiresAt?.toDate?.()?.toISOString?.() || "";
  }

  if (finalData?.createdAt) {
    finalData.createdAt =
      finalData?.createdAt?.toDate?.()?.toISOString?.() ||
      new Date().toISOString();
  }

  return finalData;
}

export function editCodeOrCreateInvitationCode(
  data: InvitationCode,
  isCreate: boolean = false
) {
  const finalData = parseFinalCodeData(data);

  /**
   * seperate cloud functions for edit & create, because of seprate permissions for manage & create.
   * manage permissions allow edit but don't allow create
   */
  if (isCreate) {
    return createCode(finalData);
  }

  return CloudFunctions.editCode(finalData);
}

export function duplicateInvitationCode(data: InvitationCode) {
  const finalData = parseFinalCodeData(data);
  return CloudFunctions.duplicateCode(finalData);
}
