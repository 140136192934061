import { db } from "../../contexts/FirebaseContext";
import { MeditationVoice, MeditationVoiceCreateRequestType } from "../../types/meditationVoice";
import { DBCollectionNames } from "./constants";
import { omit } from "lodash";
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";

export async function createOrUpdateMeditationVoice(
  meditationVoice: MeditationVoiceCreateRequestType
) {
  return db
    .collection(DBCollectionNames.MEDITATION_VOICES)
    .doc(meditationVoice.documentId)
    .set({
      ...omit(meditationVoice, "documentId"),
      createdOrUpdatedAt: (firebase.firestore.Timestamp.fromDate(new Date()) as firebase.firestore.Timestamp).toDate()
    }, { merge: true });
}

export function useMeditationVoice(id: string) {
  const [loading, setLoading] = useState<boolean>(true);
  const [meditationVoice, setMeditationVoice] = useState<MeditationVoice>()

  useEffect(() => {
    if (!id?.length) {
      setLoading(false);
      return;
    }

    const unsub = db
      .collection(DBCollectionNames.MEDITATION_VOICES)
      .doc(id)
      .onSnapshot(
        (doc) => {
          if (doc?.exists) {
            setMeditationVoice({...doc.data(), id: doc.id} as MeditationVoice)
          }
          setLoading(false)
        }
      );

    return () => unsub?.();
  }, [id]);

  return { meditationVoice, loading }
}

export function useMeditationVoices() {
  const [loading, setLoading] = useState<boolean>(false);
  const [meditationVoices = [], setMeditationVoices] = useState<Array<MeditationVoice>>([]);

  useEffect(() => {
    let dbRef: firebase.firestore.CollectionReference | firebase.firestore.Query = db
      .collection(DBCollectionNames.MEDITATION_VOICES)

    setLoading(true);
    const unsub = dbRef
      .orderBy("createdOrUpdatedAt", "desc")
      .onSnapshot((snapshot) => {
        const updatedMeditationVoices = snapshot
          .docs
          .map((doc) => {
            const meditationVoiceItem = doc.data()
            return {
              ...meditationVoiceItem, createdOrUpdatedAt:
                (meditationVoiceItem.createdOrUpdatedAt as firebase.firestore.Timestamp).toDate(),
              id: doc.id
            } as MeditationVoice
          });
        setMeditationVoices(updatedMeditationVoices ?? []);
        setLoading(false);
      }, (error) => {
        console.error("errorInFetching:: updatedMeditationVoices:: ", error?.message);
        setLoading(false);
      });

    return () => unsub();
  }, []);

  return { meditationVoices, loading };
}

export const deleteMeditationVoiceByIdFromDB = async (
  mediationId: string
): Promise<void> => {
  return db
    .collection(DBCollectionNames.MEDITATION_VOICES)
    .doc(mediationId)
    .delete()
};