import firebase from "firebase/compat/app";

import { SupportedLanguages } from "./invitation/code";
import { SupportedLanguagesEnum } from "../utils/constants";
import { Timestamp } from "../components/_dashboard/codes/StatusCell";
import { ContentFromFirestore } from "../models/ContentUnit";

export type TranslationsTypes = {
  [key in SupportedLanguages]: TranslationItems;
};

export type TranslationItems = Record<string, string>;

export type NewTranslation = {
  [language in SupportedLanguages]: string;
} & {
  keyVal: string;
};

export type MultilingualLocalesTypes = {
  name: string;
  locale: string;
};

export type AppTranslationKeyDescriptionType = {
  id: string;
  key: string;
  description: string;
  value?: string;
  priority?: string;
  updatedAt?: Timestamp | Date;
  createdAt?: Timestamp | Date;
  aiModelType?: string;
  aiPrompt?: Array<string>;
};

export type AppTranslationKeyType = {
  id?: string;
  key?: string;
  translationId: string;
  locale: string;
  description: string;
  value: string;
  priority?: string;
  createdAt?: Timestamp | Date;
  updatedAt?: Timestamp | Date;
  approvedBy?: string | firebase.firestore.FieldValue | null;
};

export type AppLanguageTranslationType = {
  id?: string;
  locale: string;
  value: string;
  updatedAt?: Timestamp | Date;
  approvedBy?: string | firebase.firestore.FieldValue | null;
};

export type GenerateAndSaveAIBasedTranslationType = {
  translationId: string;
  targetLanguages: string[];
  translateKey: string;
  valueInEN: string;
  description: string;
  isSuggestedTranslation?: boolean;
  isAnthropicAiModel?: boolean;
  aiPrompt?: string;
};

export type MultiLingualContentGenerationType = Partial<ContentFromFirestore> & {
  collectionName: string;
  isOnlySaveNonTranslatedFields?: boolean;
}

export enum ContentVersionsField {
  BOT_DATA_VERSION = "botDataVersion",
  RATING_QUESTIONS_VERSION = "ratingQuestionsVersion",
  TRANSLATIONS_VERSION = "translationsVersion",
  SETTINGS_VERSION = "settingsVersion",
  MULTILINGUAL_LOCALES_VERSION = "multilingualLocalesVersion",
  SUGGESTED_TRANSLATIONS_VERSION = "suggestedTranslationsVersion",
}

export enum SupportedLanguagesValuesEnum {
  ENGLISH = "English",
  GERMAN = "German",
  FRENCH = "French",
  SPANISH = "Spanish",
}

export type SupportedLanguagesValues =
  | SupportedLanguagesValuesEnum.ENGLISH
  | SupportedLanguagesValuesEnum.FRENCH
  | SupportedLanguagesValuesEnum.GERMAN
  | SupportedLanguagesValuesEnum.SPANISH;

export const SUPPORTED_TRANSLATION_LANGUAGE_CODE_TO_NAME: {
  [key in SupportedLanguages]: SupportedLanguagesValues;
} = {
  [SupportedLanguagesEnum.ENGLISH]: SupportedLanguagesValuesEnum.ENGLISH,
  [SupportedLanguagesEnum.GERMAN]: SupportedLanguagesValuesEnum.GERMAN,
  [SupportedLanguagesEnum.FRENCH]: SupportedLanguagesValuesEnum.FRENCH,
  [SupportedLanguagesEnum.SPANISH]: SupportedLanguagesValuesEnum.SPANISH,
};

export const SUPPORTED_TRANSLATION_LANGUAGE_NAME_TO_CODE: {
  [key in SupportedLanguagesValues]: SupportedLanguages;
} = {
  [SupportedLanguagesValuesEnum.ENGLISH]: SupportedLanguagesEnum.ENGLISH,
  [SupportedLanguagesValuesEnum.GERMAN]: SupportedLanguagesEnum.GERMAN,
  [SupportedLanguagesValuesEnum.FRENCH]: SupportedLanguagesEnum.FRENCH,
  [SupportedLanguagesValuesEnum.SPANISH]: SupportedLanguagesEnum.SPANISH,
};

export type AppSideTranslations = {
  objectID: string;
  key: string;
  description: string;
  valueInEN: string;
  createdAt?: string;
  priority?: string;
} & {
  [key: string]: string;
};
