import firebase from "firebase/compat/app";
import { useCollection } from "react-firebase-hooks/firestore";
import { Collections, CreditPeriodResponse, OrderBy } from "../types";

const useFetchCreditPeriods = (invitationCode: string | undefined) => {
  const [snapshot, loading, error] = useCollection<
    CreditPeriodResponse | undefined
  >(
    firebase
      .firestore()
      .collection(
        `${Collections.INVITATION_CODES}/${invitationCode}/${Collections.INVITATION_CODE_CREDIT_PERIODS}`
      )
      .orderBy("startDate", OrderBy.DESC)
  );

  const creditPeriods: CreditPeriodResponse[] = [];
  snapshot?.docs.forEach((doc: { data: () => CreditPeriodResponse; id: string; }) =>
    creditPeriods.push({ ...doc.data(), id: doc.id } as CreditPeriodResponse)
  );

  return { creditPeriods, loading, error };
};

export default useFetchCreditPeriods;
