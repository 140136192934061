import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
// material
import {
  Card,
  Container,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from "@mui/material";

// routes
// hooks
import useSettings from "../../../../hooks/useSettings";

// components
import Page from "../../../../components/Page";
import Scrollbar from "../../../../components/Scrollbar";
import SearchNotFound from "../../../../components/SearchNotFound";
import TableToolbar from "../../../../components/common/TableToolbar";
import TableHeader from "../../../../components/common/TableHeader";
import LoadingScreen from "../../../../components/LoadingScreen";
import {getComparator} from "../../../../utils/list";
import {filter} from "lodash";
import * as CloudFunctions from "utils/firebase/cloudFunctions";
import {PATH_DASHBOARD} from "../../../../routes/paths";
import {useQuery} from "../../../../hooks/useQuery";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "displayName", label: "Display Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "isTherapist", label: "Is Therapist", alignRight: false },
  { id: "customClaims", label: "View Custom Claims", alignRight: false },
];

// ----------------------------------------------------------------------


let UserState = {
  pageNo: 0,
  pageSize: 20,
  orderBy: "",
  searchText: "",
  order: "desc"
}

type UserInfo = {
  id: string;
  fullname: string;
  email: string;
  therapistInfo?: {
    uid?: string;
    name?: string;
    id?: string;
    email?: string;
  }
}

export default function UserListing() {
  const { themeStretch } = useSettings();
  const { id: roleId } = useParams();
  const title = useQuery().get("roleName") ?? ""

  const [page, setPage] = useState(UserState.pageNo || 0);
  const [order, setOrder] = useState<"asc" | "desc">(UserState.order as "asc" | "desc");
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState(UserState.orderBy || "email");
  const [filterName, setFilterName] = useState(UserState.searchText);
  const [rowsPerPage, setRowsPerPage] = useState(UserState.pageSize || 5);
  const [usersInfo, setUsersInfo] = useState<Array<UserInfo>>([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getUsersByRoleId = async () => {
      setLoading(true);

      try {
        const response = await CloudFunctions.getUsersByRoleId({
          roleId
        })

        setUsersInfo(response?.data ?? []);
      } catch (err) {
        console.error('Failed to fetch users');
      } finally {
        setLoading(false);
      }
    }

    getUsersByRoleId()
  }, [roleId]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    setSelected(checked ? usersInfo?.map(user => user?.fullname ?? "") : [])
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event?.target?.value ?? 0, 10));
    setPage(0);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, _page: number) => {
    setPage(_page);
  };

  const handleFilterByName = (newFilter: string) => {
    setFilterName(newFilter);
  };

  const applySortFilter = (
    array: UserInfo[],
    comparator: (a: any, b: any) => number,
    query: string
  ) => {
    const stabilizedThis = array.map((el, index) => [el, index] as const);
    stabilizedThis.sort((a, b) => {
      const orderVal = comparator(a[0], b[0]);
      if (orderVal !== 0) return orderVal;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
          (_user) => (_user?.fullname && _user?.fullname?.toLowerCase()?.indexOf(query.toLowerCase()) !== -1) || _user?.email?.toLowerCase()?.indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const filteredUsersInfo = applySortFilter(
    usersInfo,
    getComparator(order, orderBy),
    filterName
  );
  const isUserNotFound = !Boolean(filteredUsersInfo.length) && !loading;

  if (loading) {
    return <LoadingScreen/>;
  }

  return (
    <Page title="Users">
      <Container maxWidth={ themeStretch ? false : "lg" }>
        <Typography variant="h5" gutterBottom>
          Users with Role:  <strong>{title}</strong>
        </Typography>

        <Card>
          <TableToolbar
            numSelected={ selected.length }
            filterName={ filterName }
            onFilterName={ handleFilterByName }
            searchPlaceholder={ "Search User by Name/Email" }
          />

          <Scrollbar>
            <TableContainer sx={ { minWidth: 800 } }>
              <Table>
                <TableHeader
                  order={ order }
                  orderBy={ orderBy }
                  headLabel={ TABLE_HEAD }
                  rowCount={ usersInfo.length }
                  numSelected={ selected.length }
                  onRequestSort={ handleRequestSort }
                  onSelectAllClick={ handleSelectAllClick }
                  hideCheckbox
                />
                <TableBody>
                  { filteredUsersInfo
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((userInfo: UserInfo) => {
                      const {
                        id,
                        fullname,
                        email,
                        therapistInfo,
                      } = userInfo;
                      const isItemSelected = selected.indexOf(therapistInfo?.name ?? fullname ?? "") !== -1;
                      const { uid, name } = therapistInfo ?? {};

                      const encodedEmail = encodeURIComponent(email ?? "");

                      return (
                        <TableRow
                          hover
                          key={ `${ name ?? fullname }-${ uid ?? id }` }
                          tabIndex={ -1 }
                          role="checkbox"
                          selected={ isItemSelected }
                          aria-checked={ isItemSelected }
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="subtitle2" noWrap>
                                { name ?? fullname }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="body1" noWrap>
                                { email }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              { therapistInfo?.id && (
                                <Link
                                  sx={{cursor: "pointer"}}
                                  onClick={ () => navigate(`${ PATH_DASHBOARD.therapist.root }/${ therapistInfo?.id }/view`) }
                                  variant="body2">
                                  View Therapist Profile
                                </Link>
                              ) }

                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Link
                                sx={{cursor: "pointer"}}
                                onClick={ () => navigate(`${ PATH_DASHBOARD.iam.userRoleManage }?userEmail=${ encodedEmail }`) }
                                variant="body2">
                                View/Edit All Roles
                              </Link>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    }) }
                </TableBody>
                { isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={ 6 } sx={ { py: 3 } }>
                        <SearchNotFound searchQuery={ filterName }/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) }
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={ [5, 10, 15, 20, 25, 50, 100] }
            component="div"
            count={ usersInfo.length }
            rowsPerPage={ rowsPerPage }
            page={ page }
            onPageChange={ handlePageChange }
            onRowsPerPageChange={ handleChangeRowsPerPage }
          />
        </Card>
      </Container>
    </Page>
  );
}
