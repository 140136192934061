import firebase from "firebase/compat/app";
import { FormikHelpers } from "formik";

export type FormValues = {
  id?: string;
  allowedCredits: number;
  active: boolean;
  unlimitedCredits: boolean;
};

export const InitialValues = {
  allowedCredits: 0,
  active: true,
  unlimitedCredits: false,
};

export interface CreditPeriodsContextValue {
  invitationCode: string | undefined;
  formDialogIsOpen: boolean;
  values: FormValues;
  handleSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => Promise<void>;
  handleClickOpen: (values?: FormValues) => void;
  handleClose: () => void;
}

export type CreatedBy = {
  uid: string;
  email?: string;
  name?: string;
  picture?: string;
};

export type CreditPeriodResponse = {
  id: string;
  active: boolean;
  allowedCredits: number;
  unlimitedCredits: boolean;
  startDate: firebase.firestore.Timestamp;
  endDate: firebase.firestore.Timestamp | null;
  createdBy: CreatedBy;
};

export enum Collections {
  INVITATION_CODES = "invitation_codes",
  INVITATION_CODE_CREDIT_PERIODS = "invitation_code_credit_periods",
}

export enum OrderBy {
  DESC = "desc",
  ASC = "asc",
}

export type UpdateInvitationCodeCreditPeriodProps = {
  invitationCode?: string;
  docId?: string;
  formValues: FormValues;
};
