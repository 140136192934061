import { db, storage } from "contexts/FirebaseContext";
import firebase from "firebase/compat/app";
import * as CloudFunctions from "utils/firebase/cloudFunctions";
import {
  Therapist,
  TherapistInvoice,
  TherapistProfileEditInputFields,
  TherapistProfileInputFields,
  SessionFromDB,
} from "../../types/therapists";
import { v4 } from "uuid";
import { ChatItem, UserInfo } from "../../types/chat";
import { retrieveLastCounsellorProgress } from "./counsellorProgressUtils";
import { ErrorException } from "../../types/settings";

export const PROFILE_TYPES = ["test", "production"] as const;
export type ProfileType = typeof PROFILE_TYPES[number];

export function getListTherapists(
  onSnapshot: (therapists: Therapist[]) => void
) {
  const unListen: any = db.collection("therapists").onSnapshot(async (snapshot) => {
    const therapists: Therapist[] = await Promise.all(snapshot.docs.map(async (doc) => {
      const therapistId = doc.id;

      const privateInformationDoc = await getTherapistPrivateInfo(therapistId);
      return { ...doc.data(), ...privateInformationDoc, id: therapistId } as Therapist;
    }));

    const filteredTherapists: Therapist[] = therapists?.filter(
      (t) => t.profileType !== undefined
    );

    for(let x in filteredTherapists){

      const therapist = filteredTherapists[x];

      try{
        const progress = await retrieveLastCounsellorProgress(therapist.id)

        filteredTherapists[x].groupProgressValidUserCount = progress?.assessmentQuestionsData.Z1.validUserCount || 0;
        filteredTherapists[x].groupProgressOverallScore = progress?.averageScore || 0;

      }catch(error){
        console.log(error)
      }

    }

    return onSnapshot(filteredTherapists);
  });
  return unListen;
}

export async function getTherapistPrivateInfo (therapistId: string){
  const snapshot = await db.collection(`therapists/${therapistId}/private_therapist`).doc(therapistId).get()
  return snapshot?.data() || {};
}

export function uploadImage(
  file: File,
  therapistId: string
): Promise<firebase.storage.UploadTaskSnapshot> {
  const randomFileName = v4();
  return storage
    .ref()
    .child(randomFileName)
    .put(file, { customMetadata: { therapistId } })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error(error);
      alert("Image upload failed");
    });
}

export async function createTherapistProfile(
  profileFields: TherapistProfileInputFields
): Promise<{ data: string }> {
  try {
    const result = await CloudFunctions.createTherapist({ ...profileFields });
    return result;
  } catch (error: ErrorException) {
    console.error(error);
    return { data: "" };
  }
}

export async function saveChangesToTherapistProfile(
  profileFields: TherapistProfileEditInputFields
): Promise<{ data: string }> {
  try {
    const result = await CloudFunctions.editTherapist(profileFields);
    return result;
  } catch (error: ErrorException) {
    console.error(error);
    return { data: error?.message || "something went wrong." };
  }
}

export async function getTherapistById(id: string): Promise<Therapist> {
  const doc = await firebase.firestore().collection("therapists").doc(id).get();
  if (!doc.exists) throw new Error("No therapist with this id exists");
  return { id: doc.id, ...doc.data() } as Therapist;
}

export async function syncTherapistWithRole(
  email: string,
  removeChatRoles: boolean
): Promise<any> {
  return CloudFunctions.syncEdit({ email, removeChatRoles });
}

export async function deleteTherapistProfile(
  id: string
): Promise<{ data: string }> {
  try {
    const result = await CloudFunctions.deleteTherapist({ therapistId: id });
    return result;
  } catch (error: ErrorException) {
    console.error(error);
    return { data: error?.message || "something went wrong." };
  }
}

export const getSpecificUser = async (userId: string) => {
  return db
    .collection("users")
    .doc(userId)
    .get()
    .then((snapshot) => {
      const { fullname, timezone } = (snapshot.data() || {
        fullname: "",
        timezone: "",
      }) as UserInfo;
      return { fullname, timezone };
    });
};

export const getUsersInfo = async (userId: string) => {
  return getSpecificUser(userId);
};

export function getAcceptedCallSessions(
  onSnapshot: (data: any, chatId: string) => void,
  allConversations: ChatItem[]
) {
  return allConversations.map((conversation: ChatItem) => {
    const { chatId, userId } = conversation;

    return db
      .collection(`messages/${chatId}/conversation`)
      .where("callAccepted", "==", true)
      .onSnapshot((snapshotMessages) => {
        if (snapshotMessages.size) {
          getUsersInfo(userId).then((user) => {
            const messages = snapshotMessages.docs.map((messageDoc) => {
              return {
                ...messageDoc.data(),
                messageId: messageDoc.id,
                userName: user.fullname,
              };
            });

            onSnapshot(messages, chatId);
          });
        }

        onSnapshot([], chatId);
      });
  });
}

export function getTherapistChats(
  therapistId: string,
  onSnapshot: (chats: ChatItem[]) => void
) {
  return db
    .collection("chats")
    .where("therapistUId", "==", therapistId)
    .onSnapshot(
      async (snapshot) => {
        const chats: ChatItem[] = snapshot.docs.map((doc) => {
          return {
            ...(doc.data() as ChatItem),
            chatId: doc.id,
          };
        });

        onSnapshot(chats);
      },
      (error) => {
        console.warn(`Error loading chats for user '${therapistId}'`, error);
      }
    );
}

export function getTherapistInvoices(
  therapistId: string,
  onSnapshot: (invoices: TherapistInvoice[]) => void
) {
  return db
    .collection(`therapists/${therapistId}/invoices`)
    .where("__name__", "!=", "savedInvoiceData")
    .onSnapshot(
      async (snapshot) => {
        const invoices: TherapistInvoice[] = snapshot.docs.map((doc) => {
          return {
            ...(doc.data() as TherapistInvoice),
            id: doc.id,
          };
        });

        const sortedInvoices = invoices.sort((a, b) => {
          const aDate = new Date(a.year, a.month);
          const bDate = new Date(b.year, b.month);
          return bDate.getTime() - aDate.getTime();
        })

        onSnapshot(sortedInvoices);
    },
    (error) => {
      console.warn(`Error loading invoices for user '${therapistId}'`, error);
    }
  );
}

export async function updateInvoiceComment({
  therapistId,
  invoiceId,
  newComment,
}: {
  therapistId: string;
  invoiceId: string;
  newComment: string;
}) {
  await db
    .collection(`therapists/${therapistId}/invoices`)
    .doc(invoiceId)
    .update({ adminComment: newComment });
}

export const getInvoiceById = async (
  therapistId: string,
  invoiceId: string
) => {
  return db
    .collection(`therapists/${therapistId}/invoices`)
    .doc(invoiceId)
    .get()
    .then((snapshot) => {
      return {
        ...(snapshot.data() as TherapistInvoice),
        id: snapshot.data()?.id ?? "",
      };
    });
};

export const getSessionById = async (
  therapistId: string,
  sessionId: string
) => {
  return db
    .collection(`therapists/${therapistId}/sessions`)
    .doc(sessionId)
    .get()
    .then((snapshot) => {
      return {
        ...(snapshot.data() as SessionFromDB),
      };
    });
};

export async function updateSession({
  therapistId,
  sessionId,
  updatedSession,
}: {
  therapistId: string;
  sessionId: string;
  updatedSession: Partial<SessionFromDB>;
}) {
  await db
    .collection(`therapists/${therapistId}/sessions`)
    .doc(sessionId)
    .update(updatedSession);
}

export function getAllTherapistsFromDB(
  onSnapshot: (requests: Therapist[]) => void
) {
  return db.collection("therapists")
    .onSnapshot(
      async (snapshot) => {
        let therapistPromises: Promise<Therapist>[] = snapshot.docs.map(async (doc) => {
          return doc.data() as Therapist;
        });

        const therapists = await Promise.all(therapistPromises);
        onSnapshot(therapists);
      },
      (error) => {
        console.warn(`Error loading the therapists`, error);
      }
    );
}
