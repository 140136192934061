import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { isString, omitBy } from "lodash";
import * as Yup from "yup";
import { v4 as UUID } from "uuid";

// material
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Tooltip
} from "@mui/material";
import firebase from "firebase/compat/app";

import {
  AudioMeditationGenerationRequestType,
  AudioMeditationGenerationType, AudioMeditationWithoutGenerationType,
  ManualAudioMeditationCreationRequestType
} from "../../../types/audioMeditationGenerations";
import { generateAudioMeditation } from "../../../utils/firebase/cloudFunctions";
import { capitalizeFirstLetter } from "../../../utils/stringUtils";
import LoadingScreen from "../../LoadingScreen";
import {LANGUAGE_CODE_TO_NAMES, LANGUAGE_NAMES_TO_CODE, openAIModels} from "../../../utils/constants";
import {
  createOrUpdateManualAudioMeditation,
  updateAudioMeditationWithoutGeneratingAudio
} from "../../../utils/firebase/audioMeditationGenerationsUtil";
import MeditationVoiceAutocomplete from "./MeditationVoiceAutocomplete";
import AudioTextComponent from "./AudioTextComponent";
import { ErrorException } from "../../../types/settings";

// ----------------------------------------------------------------------

type AudioMeditationGenerationFormProps = {
  isEdit: boolean;
  isView?: boolean;
  isNew?: boolean;
  isManualEntered?: boolean;
  loading: boolean;
  audioMeditation: AudioMeditationGenerationType;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AudioMeditationGenerationForm({
                                                        isView,
                                                        isEdit,
                                                        audioMeditation,
                                                        loading,
                                                        isManualEntered
                                                      }: AudioMeditationGenerationFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false)

  const getAudioMeditationSchema = () => {
    if (isManualEntered) {
      return Yup.object().shape({
        title: Yup.string().required("Title is required"),
        sourceText: Yup.string().optional(),
        sourceLang: Yup.string().required("Source Language is required"),
        targetLang: Yup.string().required("Target Language is required"),
        meditationId: Yup.string().required("Meditation is required"),
        voiceId: Yup.string().required("Voice ID is required"),
        audioUrl: Yup.string().required("Meditation Audio Url is required"),
        isActive: Yup.boolean().optional(),
      })
    }

    return Yup.object().shape({
      title: Yup.string().required("Title is required"),
      sourceText: Yup.string().required("Source Text is required"),
      sourceLang: Yup.string().required("Source Language is required"),
      targetLang: Yup.array().of(Yup.string().min(1, "Please select at least one target Language").required()).required("Target Language is required"),
      formality: Yup.string().optional(),
      voiceId: Yup.string().required("Voice ID is required"),
      comment: Yup.string().optional(),
      skipExternalProcessing: Yup.boolean().optional(),
      isActive: Yup.boolean().optional(),
      translator: Yup.string().required(),
      openAIModel: Yup.string().required(),
      meditationId: Yup.string().required("Meditation is required"),
      stability: Yup.number().required("Voice Stability is required"),
      similarityBoost: Yup.number().required("Voice Similarity Boost is required"),
      style: Yup.number().required("Voice Style is required"),
      useSpeakerBoost: Yup.boolean().optional(),
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: audioMeditation?.id ?? "",
      title: audioMeditation?.title ?? "",
      sourceText: audioMeditation?.sourceText ?? "",
      sourceLang: audioMeditation?.sourceLang ?? "",
      targetLang: isManualEntered ? audioMeditation?.targetLang : (audioMeditation?.targetLang ? [audioMeditation?.targetLang] : []),
      formality: audioMeditation?.formality ?? "",
      voiceId: audioMeditation?.voiceConfig?.id ?? "",
      comment: audioMeditation?.comment ?? "",
      audioUrl: audioMeditation?.targetLanguageVoiceUrl ?? "",
      skipExternalProcessing: audioMeditation?.skipExternalProcessing ?? false,
      isActive: audioMeditation?.isActive ?? false,
      translator: audioMeditation?.translator ?? "google",
      openAIModel: audioMeditation?.openAIModel ?? "gpt-4-1106-preview",
      meditationId: audioMeditation?.meditationId ?? "",
      stability: audioMeditation?.voiceConfig?.setting?.stability ?? '0.70',
      similarityBoost: audioMeditation?.voiceConfig?.setting?.similarityBoost ?? '0.10',
      style: audioMeditation?.voiceConfig?.setting?.style ?? '0.0',
      useSpeakerBoost: audioMeditation?.voiceConfig?.setting?.useSpeakerBoost ?? false,
    },

    validationSchema: getAudioMeditationSchema,
    onSubmit: async (values) => {
      const processManualEnteredMeditation = async () => {
        let documentId = values.id
        if (!documentId?.length) {
          documentId = UUID()
        }
        const meditationRequest: ManualAudioMeditationCreationRequestType = {
          documentId,
          title: values.title,
          sourceText: values.sourceText,
          sourceLang: values.sourceLang,
          targetLang: values.targetLang as string,
          isActive: values.isActive ?? false,
          meditationId: values.meditationId ?? "",
          voiceId: values.voiceId,
          audioUrl: values.audioUrl,
          isManualCreated: true,
          createdAt: firebase.firestore.Timestamp.fromDate(new Date())
        }

        try {
          await createOrUpdateManualAudioMeditation(meditationRequest);
          enqueueSnackbar(`Manual Audio Meditation ${ documentId ? 'Updated' : 'Created' } Successfully`, {
            variant: "success",
          });
          navigate(-1)
        } catch (e: ErrorException) {
          console.error("FailedToCreateOrUpdateManualAudioMeditation", e);
          enqueueSnackbar(`Failed to ${ documentId ? 'Update' : 'Create' } Manual audio meditation`, {
            variant: "error",
          });
        }
      }

      if (isManualEntered) {
        return processManualEnteredMeditation()
      }

      const meditationRequest: AudioMeditationGenerationRequestType = omitBy({
        documentId: values.id,
        title: values.title,
        sourceText: values.sourceText,
        sourceLang: values.sourceLang,
        targetLang: values.targetLang,
        formality: values.formality,
        voiceId: values.voiceId,
        isActive: values.isActive,
        comment: values.comment,
        meditationId: values.meditationId,
        voiceSetting: {
          stability: values.stability,
          style: values.style,
          similarityBoost: values.similarityBoost,
          useSpeakerBoost: values.useSpeakerBoost,
        },
        translator: values.translator,
        openAIModel: values.openAIModel,
        skipExternalProcessing: values.skipExternalProcessing,
      }, item => isString(item) && !item.length) as AudioMeditationGenerationRequestType

      generateAudioMeditation(meditationRequest)
        .catch((error) => {
          console.error("FailedToGenerateAudioMeditation", error);
        })
      navigate(-1);
      enqueueSnackbar("Audio meditation generation has started. You can view the current status in real time on the Audio Meditation Generation listing page.", { variant: "success" });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  let submitBtnTitle = `${ isManualEntered ? 'Create' : 'Generate' } Audio Meditation`;
  if (isView) {
    submitBtnTitle = "View Only";
  } else if (isEdit && !isManualEntered) {
    submitBtnTitle = "Save With Generating Audio";
  } else if (isEdit && isManualEntered) {
    submitBtnTitle = "Update Audio Meditation";
  }

  const onDeleteTargetLanguage = (deletionLanguageVal: string) => () => {
    setFieldValue('targetLang', (formik.values.targetLang as string[]).filter((lang) => {
      return lang !== deletionLanguageVal
    }))
  }

  const onSaveWithoutGeneratingAudio = async () => {
    const {
      id: documentId,
      title,
      comment,
      isActive,
      meditationId,
    } = formik.values

    if (!formik.isValid) {
      enqueueSnackbar("Please enter all the required fields to save...", { variant: "error" });

      return
    }

    try {
      setSaving(true)

      await updateAudioMeditationWithoutGeneratingAudio(
        documentId ?? "",
        {
          comment,
          isActive,
          meditationId,
          title
        } as AudioMeditationWithoutGenerationType
      )

      enqueueSnackbar("Audio Meditation Update Successfully ", { variant: "success" });
    } catch (e: ErrorException) {
      console.error("fromUseAudioApproval: error while approving: ", e?.message)
    } finally {
      setSaving(false)
    }
  }

  const renderTranslatedFields = () => {
    if (!isView && !isEdit) {
      return null
    }

    return (<>
      { Boolean(audioMeditation?.translatedTextFromTranslator) && <TextField
          fullWidth
          multiline
          minRows={ 5 }
          value={ audioMeditation?.translatedTextFromTranslator }
          label={ `Translated Text From ${ capitalizeFirstLetter(audioMeditation?.translator ?? '') }` }
          disabled
      />
      }

      { Boolean(audioMeditation?.AIProcessedTranslatedText) && <TextField
          fullWidth
          multiline
          minRows={ 5 }
          value={ audioMeditation?.AIProcessedTranslatedText }
          label="AI Processed Translated Text"
          disabled
      />
      }

      { Boolean(audioMeditation?.textsAudiosChunksWithDuration?.length) &&
          <AudioTextComponent audioTextChunks={audioMeditation?.textsAudiosChunksWithDuration || []} />
      }
    </>)
  }

  const renderStatusAndMetaFields = () => {
    if (!isView && !isEdit) {
      return null
    }

    return (<>
      { Boolean(audioMeditation?.failureReason) && <TextField
          fullWidth
          multiline
          minRows={ 2 }
          value={ audioMeditation?.failureReason }
          label="Failure Reason"
          disabled
      />
      }
      { Boolean(audioMeditation?.status) &&
          <TextField
              fullWidth
              value={ audioMeditation?.status }
              label="Status"
              disabled
          />
      }
    </>)
  }

  if (loading) {
    return <LoadingScreen/>;
  }

  return (
    <FormikProvider value={ formik }>
      <Form noValidate autoComplete="off" onSubmit={ handleSubmit }>
        <fieldset disabled={ isView } style={ { border: "none" } }>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 }>
              <Card sx={ { p: 3 } }>
                <Stack spacing={ 3 }>
                  <TextField
                    fullWidth
                    label="Title"
                    { ...getFieldProps("title") }
                    disabled={ isView }
                    error={ Boolean(touched.title && errors.title) }
                    helperText={ touched.title && errors.title }
                  />

                  <TextField
                    fullWidth
                    label="Source Language"
                    disabled={ isView }
                    { ...getFieldProps("sourceLang") }
                    error={ Boolean(touched.sourceLang && errors.sourceLang) }
                    helperText={ touched.sourceLang && errors.sourceLang }
                  />

                  <TextField
                    fullWidth
                    multiline
                    minRows={ 5 }
                    disabled={ isView }
                    label="Source Text"
                    { ...getFieldProps("sourceText") }
                    error={ Boolean(touched.sourceText && errors.sourceText) }
                    helperText={ touched.sourceText && errors.sourceText }
                  />

                  <FormControl fullWidth>
                    <InputLabel id="target-lang">Target Languages</InputLabel>
                    <Select
                      label="Target Languages"
                      disabled={ isView }
                      multiple={ !isManualEntered }
                      { ...getFieldProps("targetLang") }
                      input={ <OutlinedInput id="select-multiple-chip" label="Chip" color="primary"/> }
                      MenuProps={ MenuProps }
                      renderValue={ (selected: any) => {
                        return (
                          <Box sx={ { display: 'flex', flexWrap: 'wrap', gap: 0.5 } }>
                            { selected?.map?.((value: string) => (
                              <Chip key={ value } label={ LANGUAGE_CODE_TO_NAMES[value] ?? value } color="info"
                                    variant="outlined"
                                    onMouseDown={ (event) => event.stopPropagation() }
                                    onDelete={ onDeleteTargetLanguage(value) }/>
                            )) ?? <Chip color="info" variant="outlined"
                                        label={ LANGUAGE_CODE_TO_NAMES[selected] ?? "" }/> }
                          </Box>
                        );
                      } }>
                      { Object.keys(LANGUAGE_NAMES_TO_CODE).map((key: string) => (
                        <MenuItem
                          key={ key }
                          value={ LANGUAGE_NAMES_TO_CODE[key] }>
                          { key }
                        </MenuItem>
                      )) }
                    </Select>
                  </FormControl>

                  { Boolean(!isManualEntered) &&
                      <>
                          <FormControl fullWidth>
                              <InputLabel id="translator-select-label">Translator</InputLabel>
                              <Select
                                  label="Translator"
                                  disabled={ isView }
                                  { ...getFieldProps("translator") }>
                                  <MenuItem value="google">Google</MenuItem>
                                  <MenuItem value="deepl">Deepl</MenuItem>
                              </Select>
                          </FormControl>

                          <FormControl fullWidth>
                              <InputLabel id="formality-select-label">Formality</InputLabel>
                              <Select
                                  label="Formality"
                                  disabled={ isView }
                                  { ...getFieldProps("formality") }>
                                  <MenuItem value="less">Less</MenuItem>
                                  <MenuItem value="default">Default</MenuItem>
                                  <MenuItem value="more">More</MenuItem>
                                  <MenuItem value="prefer_more">Prefer More</MenuItem>
                                  <MenuItem value="prefer_less">Prefer Less</MenuItem>
                              </Select>
                          </FormControl>

                          <FormControl fullWidth>
                            <InputLabel id="open-ai-select-label">Select Open AI Model</InputLabel>
                            <Select
                                label="Select Open AI Model"
                                disabled={ isView }
                                { ...getFieldProps("openAIModel") }>
                              {openAIModels.map((model) => (
                                  <MenuItem key={model.value} value={model.value}>
                                    {model.label}
                                  </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                        { renderTranslatedFields() }

                          <TextField
                              fullWidth
                              label="Comment"
                              disabled={ isView }
                              { ...getFieldProps("comment") }
                              error={ Boolean(touched.comment && errors.comment) }
                              helperText={ touched.comment && errors.comment }
                          />

                      </>
                  }

                  <TextField
                    fullWidth
                    label="Meditation"
                    disabled={ isView }
                    { ...getFieldProps("meditationId") }
                    error={ Boolean(touched.meditationId && errors.meditationId) }
                    helperText={ touched.meditationId && errors.meditationId }
                  />

                  <MeditationVoiceAutocomplete
                    freeSolo={false}
                    disabled={ isView }
                    onChangeValue={ (value) => {
                      setFieldValue("voiceId", value)
                    } }
                    { ...getFieldProps("voiceId") }
                    helperText={ touched.voiceId && errors.voiceId }
                  />


                  { Boolean(isManualEntered) &&
                      <TextField
                          fullWidth
                          label="Meditation Audio Url"
                          disabled={ isView }
                          { ...getFieldProps("audioUrl") }
                          error={ Boolean(touched.audioUrl && errors.audioUrl) }
                          helperText={ touched.audioUrl && errors.audioUrl }/>
                  }

                  { Boolean(!isManualEntered) &&
                      <>
                          <TextField
                              fullWidth
                              label="Voice Stability"
                              disabled={ isView }
                              { ...getFieldProps("stability") }
                              error={ Boolean(touched.stability && errors.stability) }
                              helperText={ touched.stability && errors.stability }/>

                          <TextField
                              fullWidth
                              disabled={ isView }
                              label="Voice Similarity Boost"
                              { ...getFieldProps("similarityBoost") }
                              error={ Boolean(touched.similarityBoost && errors.similarityBoost) }
                              helperText={ touched.similarityBoost && errors.similarityBoost }/>

                          <TextField
                              fullWidth
                              label="Voice Style"
                              disabled={ isView }
                              { ...getFieldProps("style") }
                              error={ Boolean(touched.style && errors.style) }
                              helperText={ touched.style && errors.style }/>

                        { renderStatusAndMetaFields() }
                      </>
                  }

                  <FormControlLabel
                    label="Is Active"
                    disabled={ isView }
                    control={ <Checkbox
                      defaultChecked={ audioMeditation?.isActive ?? false } { ...getFieldProps("isActive") } /> }
                  />

                  { Boolean(!isManualEntered) &&
                      <>
                          <FormControlLabel
                              label="Use Speaker Boost"
                              disabled={ isView }
                              control={ <Checkbox
                                defaultChecked={ audioMeditation?.voiceConfig?.setting?.useSpeakerBoost ?? false } { ...getFieldProps("useSpeakerBoost") } /> }
                          />

                          <FormControlLabel
                              label="Skip External Translation Processing"
                              disabled={ isView }
                              control={ <Checkbox
                                defaultChecked={ audioMeditation?.skipExternalProcessing ?? false } { ...getFieldProps("skipExternalProcessing") } /> }
                          />
                      </>
                  }

                  { Boolean(audioMeditation?.targetLanguageVoiceUrl) &&
                      <Link href={ audioMeditation?.targetLanguageVoiceUrl } target="_blank">Download Audio File</Link>
                  }


                  <Box sx={ { mt: 3, display: "flex", justifyContent: "flex-end" } }>
                    { Boolean(isEdit) && !isManualEntered &&
                        <Tooltip title="It will update only `Title`, `Meditation`, `Comment` and `isActive` fields." placement="top" arrow>
                            <LoadingButton
                                type="button"
                                variant="contained"
                                onClick={ onSaveWithoutGeneratingAudio }
                                loading={ saving || isSubmitting }
                                sx={ { mr: 4 } }>
                                Save Without Generating Audio
                            </LoadingButton>
                        </Tooltip>
                    }

                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={ saving || isSubmitting }>
                      { submitBtnTitle }
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </fieldset>
      </Form>
    </FormikProvider>
  );
}
