import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

// routes
import { PATH_DASHBOARD } from "../../../routes/paths";

// hooks
import useSettings from "../../../hooks/useSettings";

// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { useSnackbar } from "notistack";
import TableToolbar from "../../../components/common/TableToolbar";
import TableHeader from "../../../components/common/TableHeader";
import MoreMenu from "../../../components/common/MoreMenu";
import { filter } from "lodash";
import { Anonymous } from "../../../types/list";
import {
  deleteReleaseNoteById,
  useReleaseNotesList,
} from "../../../utils/firebase/releaseNotesUtils";
import { ReleaseNotes } from "../../../types/releaseNotes";
import { LanguageSelectionDropDown } from "../../../components/LanguageSelectionDropDown";
import { SupportedLanguages } from "../../../types/invitation/code";
import { SupportedLanguagesEnum } from "../../../utils/constants";
import { format } from "date-fns";
import { ErrorException } from "../../../types/settings";

// ----------------------------------------------------------------------

let ReleaseNotesPersistState = {
  selectedLanguage: SupportedLanguagesEnum.ENGLISH
}

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "releasedAt", label: "Released at", alignRight: false },
  { id: "actions", label: "actions", alignRight: true },
];

// ----------------------------------------------------------------------

function applySortFilter(
  array: ReleaseNotes[],
  comparator: (a: any, b: any) => number,
  query: string
) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (group) => group.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === "desc"
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

export default function ReleaseNotesList() {
  const { themeStretch } = useSettings();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deletingId, setDeletingId] = useState<string>();
  const [language, setLanguage] = useState<SupportedLanguages>(
    ReleaseNotesPersistState.selectedLanguage
  );

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { localeList } = useSettings()
  const { releaseNotes = [], loading, removeReleaseNoteById } = useReleaseNotesList(language);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      setSelected(releaseNotes?.map((n) => n.title));
      return;
    }
    setSelected([]);
  };


  useEffect(() => {
    return ()=> {
      ReleaseNotesPersistState = {
        selectedLanguage: language
      }
    }
  }, [language]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleFilterByName = (filterName: string) => {
    setFilterName(filterName);
  };

  const handleDeleteReleaseNote = async (releaseNoteId: string) => {
    try {
      if (deletingId) {
        return;
      }

      setDeletingId(releaseNoteId);

      if (window.confirm("Do you really want to delete this release note?")) {
        await deleteReleaseNoteById(releaseNoteId, language);
        removeReleaseNoteById(releaseNoteId);
        enqueueSnackbar("Release note Deleted Successfully", {
          variant: "success",
        });
      }
    } catch (e: ErrorException) {
      console.error("FailedToDeleteReleaseNote", e);
      enqueueSnackbar("Failed to delete release note", {
        variant: "error",
      });
    } finally {
      setDeletingId("");
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - releaseNotes.length) : 0;

  const filteredGroup = applySortFilter(
    releaseNotes,
    getComparator(order, orderBy),
    filterName
  );

  const isGroupNotFound = filteredGroup.length === 0 && !loading;

  return (
    <Page title="Release note">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading=""
          links={[]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.releaseNotes.new}
              startIcon={<Icon icon={plusFill} />}
            >
              Add New Release Note
            </Button>
          }
        />

        <Box sx={{ marginBottom: 3 }}>
          <LanguageSelectionDropDown
            fieldName={"language"}
            showMultiLingualOption
            skipMultiLingualSelection
            label={'Select Language'}
            selectedValue={language}
            localeList={ localeList }
            onSelectionChanged={(key, value) => setLanguage(value)}
          />
        </Box>

        <Card>
          <TableToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            searchPlaceholder={"search Release note"}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={releaseNotes.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  hideCheckbox
                />
                <TableBody>
                  {filteredGroup
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: ReleaseNotes) => {
                      const { id, title, releasedAt } = row;
                      const isItemSelected = selected.indexOf(title) !== -1;
                      const releasedAtTime = releasedAt ? format(
                        new Date(releasedAt),
                        "dd-MMM-yyyy, HH:mm"
                      ): "--";

                      return (
                        <TableRow
                          hover
                          key={title}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="subtitle2" noWrap>
                                {title}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="body1" noWrap>
                                {releasedAtTime}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="right">
                            <MoreMenu
                              deleting={deletingId === id}
                              onDelete={() =>
                                handleDeleteReleaseNote(id || title)
                              }
                              onEdit={() => {
                                navigate(
                                  `${PATH_DASHBOARD.releaseNotes.root}/${language}/${id}/edit`,
                                  {
                                    state: row,
                                  }
                                );
                              }}
                              onView={() => {
                                navigate(
                                  `${PATH_DASHBOARD.releaseNotes.root}/${language}/${id}/view`,
                                  {
                                    state: row,
                                  }
                                );
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isGroupNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={releaseNotes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, _page) => setPage(_page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
