import firebase from "firebase/compat/app";
import { ReleaseNoteId, ReleaseNotes } from "../../types/releaseNotes";
import { SupportedLanguages } from "../../types/invitation/code";
import { SupportedLanguagesEnum } from "../constants";
import { useEffect, useState } from "react";
import { format } from "date-fns";

export function useReleaseNotesList(
  language: SupportedLanguages = SupportedLanguagesEnum.ENGLISH
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [releaseNotes, setReleaseNotes] = useState<Array<ReleaseNotes>>([]);

  useEffect(() => {
    async function getReleaseNotes() {
      setLoading(true);
      const documentReference = await firebase
        .firestore()
        .collection("release_notes")
        .doc(language)
        .get();

      if (!documentReference.exists) {
        setReleaseNotes([]);
        setLoading(false);
      }

      const docData = documentReference.data();
      if (!docData) {
        setLoading(false);
        return [];
      }

      const dataArray: Array<ReleaseNotes> = Object.keys(docData).map(
        (key) => ({
          ...docData[key],
          releasedAt:
            (docData[key]?.releasedAt as firebase.firestore.Timestamp)?.toDate?.(),
        })
      );

      const sortReleaseNotes = dataArray.sort(
        (a: ReleaseNotes, b: ReleaseNotes) => {
          const dateA = new Date(a.releasedAt);
          const dateB = new Date(b.releasedAt);
          return dateB.getTime() - dateA.getTime();
        }
      );

      setReleaseNotes(sortReleaseNotes);
      setLoading(false);
    }

    getReleaseNotes();
  }, [language]);


  const removeReleaseNoteById = (id: string) => {
    setReleaseNotes(releaseNotes.filter(releaseNote => releaseNote.id !== id))
  }

  return { releaseNotes, loading, removeReleaseNoteById };
}

export const createOrUpdateReleaseNote = async (
  doc: any,
  selectedLanguage: SupportedLanguages | string
): Promise<void> => {
  const docName = doc?.id ?? "";
  await firebase
    .firestore()
    .collection("release_notes")
    .doc(selectedLanguage)
    .set(
      {
        [docName]: {
          ...doc,
        },
      },
      { merge: true }
    );
};

export const deleteReleaseNoteById = async (
  releaseNoteId: string,
  selectedLanguage: SupportedLanguages
): Promise<void> => {
  return firebase
    .firestore()
    .collection("release_notes")
    .doc(selectedLanguage)
    .update({ [releaseNoteId]: firebase.firestore.FieldValue.delete() });
};

export async function getReleaseNoteById(
  id: ReleaseNoteId,
  selectedLanguage: SupportedLanguages | string
): Promise<ReleaseNotes> {
  const doc = await firebase
    .firestore()
    .collection(`release_notes`)
    .doc(selectedLanguage)
    .get();

  if (!doc.exists) {
    throw new Error("No release note with this id exists");
  }

  const docData = doc?.data()?.[id] ?? {};
  let releasedAt = (docData?.releasedAt as firebase.firestore.Timestamp)?.toDate?.();
  if (releasedAt) {
    return {
      ...docData,
      releasedAt: format(releasedAt,
        "yyyy-MM-dd'T'kk:mm:ss"
      ) ?? ""
    }
  }

  return {...docData, releasedAt: ""}
}
