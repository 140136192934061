import { ReactNode } from "react";
import { Alert, AlertTitle, Container } from "@mui/material";
import { hasValidPermissions } from "../utils/helpers";
import { CCRolesPossibleVals } from "../types/role/CCRoles";
import { useCurrentRole } from "hooks/useCurrentRole";

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: Readonly<CCRolesPossibleVals[]>;
  children: ReactNode | string;
};

export default function RoleBasedGuard({
  accessibleRoles,
  children,
}: RoleBasedGuardProp) {
  const currentRoles = useCurrentRole();

  let roleMessage = "You do not have permission to access this page";

  if (!hasValidPermissions(currentRoles, accessibleRoles)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          {roleMessage}
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
