import * as yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { CounsellorSearchFilter } from "../../../types/CounsellorSearchFilters";
import {
  TextField,
  Stack,
  Divider,
  Typography,
  Box,
  Card,
  Autocomplete, Checkbox, FormControlLabel,
} from "@mui/material";
import Label from "components/Label";
import { LoadingButton } from "@mui/lab";
import { updateCounsellorSearchFilter } from "utils/firebase/cloudFunctions";
import { useSnackbar } from "notistack";
import { ErrorException } from "../../../types/settings";
import React from "react";

const CounsellorSearchFilterSchema = yup.object().shape({
  id: yup.string().required(),
  translations: yup
    .object()
    .shape({
      en: yup.string().required("English translation is required"),
      fr: yup.string(),
      de: yup.string(),
      es: yup.string(),
    })
    .required(),
  categories: yup.array().of(yup.string()),
  flags: yup.array().of(yup.string()),
  isSpecialExclusive: yup.boolean().optional(),
});

const FLAG_OPTIONS = [
  {
    label: "Care onboarding priority",
    value: "webOnboardingVisible",
  },
  {
    label: "Exclusive",
    value: "exclusive",
  },
];

export const CATEGORIES_OPTIONS = [
  { label: "Body", value: "body" },
  { label: "Mind", value: "mind" },
  { label: "Work", value: "work" },
  { label: "Relationships", value: "relationships" },
  { label: "Rest & Recreation", value: "restAndRecreation" },
  { label: "Other", value: "other" },
];

type FormProps = {
  filter: CounsellorSearchFilter;
};

function CounsellorSearchFilterForm({ filter }: FormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    id: filter.id ?? "",
    translations: {
      en: filter.translations.en ?? "",
      fr: filter.translations.fr ?? "",
      de: filter.translations.de ?? "",
      es: filter.translations.es ?? "",
    },
    categories: filter.categories ?? [],
    flags: filter.flags ?? [],
    isSpecialExclusive: filter.isSpecialExclusive ?? false,
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: CounsellorSearchFilterSchema,
    onSubmit: async (updatedFilter) => {
      const payload = new CounsellorSearchFilter(
        updatedFilter.id,
        updatedFilter
      );
      try {
        await updateCounsellorSearchFilter(payload);
        enqueueSnackbar(
          `Updated filter '${payload.getLabel()}' (${payload.id})`,
          { variant: "success" }
        );
      } catch (e: ErrorException) {
        console.error(e);
        enqueueSnackbar(
          `Error updating filter '${payload.getLabel()}' (${payload.id})`,
          { variant: "error" }
        );
      }
    },
  });
  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    setFieldValue,
    isSubmitting,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Card sx={{ py: 2, px: 2 }}>
          <Stack spacing={2}>
            <Typography variant="h6">
              {filter.id}: {filter.translations.en ?? "Untitled filter tag"}
            </Typography>
            <Divider
              orientation={"horizontal"}
              variant={"middle"}
              sx={{ mb: 3 }}
            />
            <Stack direction={{ xs: "column" }} spacing={{ xs: 3, sm: 2 }}>
              <Autocomplete
                value={formik.values.categories}
                defaultValue={filter.categories}
                multiple
                freeSolo
                options={CATEGORIES_OPTIONS}
                onChange={(_, data) => {
                  const selectedValues = data.map((option) =>
                    typeof option === "string" ? option : option.value
                  );
                  setFieldValue("categories", selectedValues);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Categories"
                    variant="outlined"
                  />
                )}
              />
              <Autocomplete
                value={formik.values.flags}
                defaultValue={filter.flags}
                multiple
                freeSolo
                options={FLAG_OPTIONS}
                onChange={(_, data) => {
                  const selectedValues = data.map((option) =>
                    typeof option === "string" ? option : option.value
                  );
                  setFieldValue("flags", selectedValues);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Flags"
                    variant="outlined"
                  />
                )}
              />
              <FormControlLabel
                  label="Is Special Exclusive"
                  control={ <Checkbox
                      defaultChecked={Boolean(filter.isSpecialExclusive)}
                      { ...getFieldProps("isSpecialExclusive") }
                  /> }
              />
            </Stack>
            <Divider
              orientation={"horizontal"}
              variant={"middle"}
              sx={{ mb: 3 }}
            />
            <Label sx={{ my: 2, py: 2, justifyContent: "center" }}>
              Translations
            </Label>
            <Stack direction={{ xs: "row" }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                fullWidth
                label="English"
                {...getFieldProps("translations.en")}
                error={Boolean(
                  touched.translations?.en && errors.translations?.en
                )}
                helperText={touched.translations?.en && errors.translations?.en}
              />
              <TextField
                fullWidth
                label="Spanish"
                {...getFieldProps("translations.es")}
              />
            </Stack>
            <Stack direction={{ xs: "row" }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                fullWidth
                label="French"
                {...getFieldProps("translations.fr")}
              />
              <TextField
                fullWidth
                label="German"
                {...getFieldProps("translations.de")}
              />
            </Stack>
          </Stack>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </Box>
        </Card>
      </Form>
    </FormikProvider>
  );
}

export default CounsellorSearchFilterForm;
