import React from "react";
import { useLocation, useParams } from "react-router-dom";
// material
import { Container } from "@mui/material";
// hooks
import useSettings from "../../../../hooks/useSettings";
// components
import Page from "../../../../components/Page";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";

import RolesForm from "../../../../components/_dashboard/roles/RolesForm";
import { useRole } from "../../../../utils/firebase/rolesUtil";
import { AccessRole } from "../../../../types/role/CCRoles";

// ----------------------------------------------------------------------

type ManualCreatedType = {
  isManualCreated?: boolean
}

export default function CreateRoles() {
  const { themeStretch } = useSettings();
  const { pathname, state } = useLocation();
  const { id: roleID } = useParams();

  const { role, loading } = useRole(roleID ?? "");

  const isEdit = pathname.includes("/edit");
  const isView = pathname.includes("/view");
  const isNew = pathname.includes("/new");

  let pageTitle = "Create a new Role";
  if (isView) {
    pageTitle = `View Role`;
  } else if (isEdit) {
    pageTitle = `Edit Role`;
  }

  return (
    <Page title={ pageTitle }>
      <Container maxWidth={ themeStretch ? false : "lg" }>
        <HeaderBreadcrumbs heading={ pageTitle } links={ [] }/>

        <RolesForm
          isEdit={ isEdit }
          isView={ isView }
          isNew={ isNew }
          loading={ loading }
          role={ role as AccessRole }
        />
      </Container>
    </Page>
  );
}
