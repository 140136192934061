import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

// material
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import { Template } from "../../../types/template";
import { createOrUpdateTemplates } from "../../../utils/templateUtils";
import { useNavigate } from "react-router";
import { useGetSnippets } from "../../../hooks/useGetSnippets";
import firebase from "firebase/compat/app";
import { IsJsonString } from "../../../utils/stringUtils";
import Select from "@mui/material/Select";
import { AIModelType, AIModelTypes } from "../../../utils/constants";
import MenuItem from "@mui/material/MenuItem";
import { ErrorException } from "../../../types/settings";

// ----------------------------------------------------------------------

type TemplateFormProps = {
  isEdit: boolean;
  isView?: boolean;
  isNew?: boolean;
  template: Template;
};

export default function TemplateForm({
  isNew,
  isView,
  isEdit,
  template,
}: TemplateFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { snippets } = useGetSnippets();
  const navigate = useNavigate();

  const TemplateSchema = Yup.object().shape({
    id: Yup.string().required(),
    templateBody: Yup.string().required(),
    introMessage: Yup.string().optional(),
    injectContentLibrary: Yup.boolean().optional(),
    functionSnippet: Yup.string().optional(),
    skipOpenAIFunctionProperties: Yup.string().optional(),
    replaceOpenAIFunctionProperties: Yup.string().optional(),
    overrideFunctionSnippet: Yup.string().optional(),
    aiModelType: Yup.string().required(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: template?.id,
      introMessage: template?.introMessage ?? "",
      templateBody:
        template?.templateBody ??
        "{{ header_v1 }} \n \n{{ content }} \n \n{{ footer_v1 }} \n \n{{ extra_details_v1 }}",
      injectContentLibrary: template?.injectContentLibrary ?? false,
      functionSnippet: template?.functionSnippet ?? "",
      replaceOpenAIFunctionProperties:
        template?.replaceOpenAIFunctionProperties ?? "",
      skipOpenAIFunctionProperties:
        template?.skipOpenAIFunctionProperties ?? "",
      overrideFunctionSnippet: template?.overrideFunctionSnippet ?? "",
      aiModelType: template?.aiModelType ?? AIModelType.OPEN_AI,
    },
    onSubmit: async (values) => {
      let successMessage = "Successfully saved changes";
      let errorMessage = "Failed to save changes";

      if (isNew) {
        successMessage = "Template Created Successfully";
        errorMessage = "Failed to create template";
      }
      try {
        const replaceOpenAIFunctionProperties =
          values?.replaceOpenAIFunctionProperties ?? "";
        if (
          replaceOpenAIFunctionProperties?.length &&
          !IsJsonString(replaceOpenAIFunctionProperties)
        ) {
          enqueueSnackbar(
            "Replace Open AI Function Properties should  be valid JSON",
            { variant: "error" }
          );
          return;
        }

        const templateRequest = {
          ...values,
          replaceOpenAIFunctionProperties,
          createdOrUpdatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        };
        await createOrUpdateTemplates(values.id, templateRequest);
        enqueueSnackbar(successMessage, { variant: "success" });
        navigate(-1);
      } catch (e: ErrorException) {
        console.error("FailedToCreateTemplate", e);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    },
    validationSchema: TemplateSchema,
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  const isAnthropicAIModel = values?.aiModelType === AIModelType.ANTHROPIC;

  let submitBtnTitle = "Create Template";
  if (isView) {
    submitBtnTitle = "View Only";
  } else if (isEdit) {
    submitBtnTitle = "Save Changes";
  }

  function getTemplateBodyPlaceholder() {
    let templateBodyPlaceholder =
      "{{ header_v1 }} \n \n{{ content }} \n \n{{ footer_v1 }} \n \n{{ extra_details_v1 }}";
    const AIModel = getFieldProps("aiModelType").value ?? "";

    if (AIModel === AIModelType.ANTHROPIC) {
      templateBodyPlaceholder = `{{single_key_language_instructions}}

Here is the input:
{{{  
   "targetLanguageName":  {{targetLanguageName}},
"targetLanguageLocale":  {{targetLanguageLocale}},
"valueInEN": {{valueInEN}},
 "description": {{description}},
"keyToTranslate": {{keyToTranslate}}
 "approvedTranslationsInTargetLang": {{approvedTranslationsInTargetLang}},
 "suggestedTranslationsInTargetLang": {{suggestedTranslationsInTargetLang}},
"other_instructions": ""
}}}`;
    }

    return templateBodyPlaceholder;
  }

  useEffect(() => {
    const AIModel = getFieldProps("aiModelType").value ?? "";
    let templateBodyValue =
      "{{ header_v1 }} \n \n{{ content }} \n \n{{ footer_v1 }} \n \n{{ extra_details_v1 }}";

    if (AIModel === AIModelType.ANTHROPIC) {
      templateBodyValue = `{{single_key_language_instructions}}

Here is the input:
{{{  
   "targetLanguageName":  {{targetLanguageName}},
"targetLanguageLocale":  {{targetLanguageLocale}},
"valueInEN": {{valueInEN}},
 "description": {{description}},
"keyToTranslate": {{keyToTranslate}}
 "approvedTranslationsInTargetLang": {{approvedTranslationsInTargetLang}},
 "suggestedTranslationsInTargetLang": {{suggestedTranslationsInTargetLang}},
"other_instructions": ""
}}}`;
    }

    if (isNew) {
      setFieldValue("templateBody", templateBodyValue);
    }

    // Disable the missing dependency warning due to multiple re-renders caused by useEffect dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFieldProps("aiModelType").value, isNew]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <fieldset disabled={isView} style={{ border: "none" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label="Id"
                    disabled={isEdit}
                    {...getFieldProps("id")}
                    error={Boolean(touched.id && errors.id)}
                    helperText={touched.id && errors.id}
                  />

                  <FormControl fullWidth>
                    <InputLabel id="open-ai-select-label">
                      Select AI Model
                    </InputLabel>
                    <Select
                      label="Select AI Model"
                      disabled={isView}
                      {...getFieldProps("aiModelType")}
                    >
                      {AIModelTypes.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                      id={"introMessage"}
                      fullWidth
                      multiline={true}
                      type={"text"}
                      minRows={4}
                      label="Intro Message"
                      placeholder={"Refer to me as {{pronounAs}} and speak to me in a {{tone}}, and {{communicationStyle}}"}
                      {...getFieldProps("introMessage")}
                      error={Boolean(touched.introMessage && errors.introMessage)}
                      helperText={touched.introMessage && errors.introMessage}
                  />

                  <TextField
                    id={"templateBody"}
                    fullWidth
                    multiline={true}
                    type={"text"}
                    minRows={6}
                    label="Template Body"
                    placeholder={getTemplateBodyPlaceholder()}
                    {...getFieldProps("templateBody")}
                    error={Boolean(touched.templateBody && errors.templateBody)}
                    helperText={touched.templateBody && errors.templateBody}
                  />

                  {!isAnthropicAIModel && (
                    <>
                      {" "}
                      <FormControlLabel
                        label="Inject Content Library"
                        disabled={isView}
                        control={
                          <Checkbox
                            {...getFieldProps("injectContentLibrary")}
                            defaultChecked={
                              template?.injectContentLibrary ?? false
                            }
                          />
                        }
                      />
                      <Autocomplete
                        id="functionSnippet"
                        options={snippets
                          .filter((snippet) => snippet.isAIFunction)
                          .map((snippet) => snippet.id)}
                        value={values.functionSnippet}
                        getOptionLabel={(option) => option}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField {...params} label="Function snippet" />
                        )}
                        onChange={(_, newValue) => {
                          setFieldValue("functionSnippet", newValue);
                        }}
                      />
                      <TextField
                        id={"overrideFunctionSnippet"}
                        fullWidth
                        multiline={true}
                        type={"text"}
                        minRows={3}
                        label="Override function snippet"
                        placeholder={
                          "<% if (isAudioConversation) { %>\n functions_triage \n<% } else { %>\n functions_bot \n <% } %>"
                        }
                        {...getFieldProps("overrideFunctionSnippet")}
                        error={Boolean(
                          touched.overrideFunctionSnippet &&
                            errors.overrideFunctionSnippet
                        )}
                        helperText={
                          touched.overrideFunctionSnippet &&
                          errors.overrideFunctionSnippet
                        }
                      />
                      <TextField
                        id={"skipOpenAIFunctionProperties"}
                        fullWidth
                        type={"text"}
                        label="Skip Open AI Function Properties (Optional)"
                        placeholder={"add_to_toolkit, go_back, ...."}
                        {...getFieldProps("skipOpenAIFunctionProperties")}
                        error={Boolean(
                          touched.skipOpenAIFunctionProperties &&
                            errors.skipOpenAIFunctionProperties
                        )}
                        helperText={
                          touched.skipOpenAIFunctionProperties &&
                          errors.skipOpenAIFunctionProperties
                        }
                      />
                      <TextField
                        id={"replaceOpenAIFunctionProperties"}
                        fullWidth
                        multiline={true}
                        type={"text"}
                        minRows={6}
                        label="Replace Open AI Function Properties (Optional)"
                        placeholder={JSON.stringify(
                          JSON.parse(
                            `{"add_to_toolkit":{"properties":{"moduleName":{"type":"string"}},"required":["moduleName"],"type":"object"}}`
                          ),
                          null,
                          2
                        )}
                        {...getFieldProps("replaceOpenAIFunctionProperties")}
                        error={Boolean(
                          touched.replaceOpenAIFunctionProperties &&
                            errors.replaceOpenAIFunctionProperties
                        )}
                        helperText={
                          touched.replaceOpenAIFunctionProperties &&
                          errors.replaceOpenAIFunctionProperties
                        }
                      />
                    </>
                  )}
                  <Box
                    sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                  >
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {submitBtnTitle}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </fieldset>
      </Form>
    </FormikProvider>
  );
}
