import { useEffect, useRef, useState } from "react";
import {
  multiFactor,
  TotpMultiFactorGenerator,
  TotpSecret,
} from "firebase/auth";
import { Typography } from "@mui/material";
import { ButtonLink } from "components/ButtonLink";
import { PaperPage } from "components/PaperPage";
import { PATH_DASHBOARD } from "routes/paths";
import { AuthErrorCode, MFAStep } from "../../types/authentication";
import LoadingScreen from "../../components/LoadingScreen";
import firebase from "firebase/compat";
import { EmailVerificationDialog } from "../../components/EmailVerificationDialog";
import { isProviderUsed } from "../../utils/isProviderUsed";
import {
  PasswordFormValues,
  ReauthenticateForm,
} from "../../components/ReauthenticateForm";
import { FormikHelpers } from "formik";
import {
  QrCodeWithInputFieldValues,
  ValidateTotpCode,
} from "../../components/ValidateTotpCode";
import { ErrorException } from "../../types/settings";

export function EnrollTOTPPage() {
  const [step, setStep] = useState<MFAStep>(MFAStep.ENROLL);
  const [loading, setLoading] = useState<boolean>(true);
  const [qrCodeUrl, setQrCodeUrl] = useState<string>("");
  const totpSecretRef = useRef<TotpSecret | null>(null);
  const currentUser = firebase.auth().currentUser as firebase.User;
  const { emailVerified } = currentUser;
  let title = "Success";
  let message = "Logging you in";
  if (step === MFAStep.ERROR) {
    title = "An error occurred";
    message = "An error occurred. Try again or contact support if the issue persists";
  }

  useEffect(() => {
    const fetchTotpSecret = async () => {
      try {
        const multiFactorSession = await multiFactor(currentUser).getSession();
        totpSecretRef.current = await TotpMultiFactorGenerator.generateSecret(
          multiFactorSession
        );
        const totpUri = totpSecretRef.current?.generateQrCodeUrl(
          currentUser?.displayName ?? currentUser?.email ?? "",
          "Kyan Health"
        );

        setQrCodeUrl(totpUri);
        setLoading(false);
      } catch (err) {
        if (String(err).includes("auth/requires-recent-login")) {
          setStep(MFAStep.REFRESH_LOGIN);
        }
        console.error("Error", err);
      }
    };

    if (currentUser?.emailVerified && step === MFAStep.ENROLL) {
      fetchTotpSecret();
    }
  }, [currentUser, step]);

  if ([MFAStep.SUCCESS, MFAStep.ERROR].includes(step)) {
    return (
      <PaperPage headTitle={"Complete Sign-in"} title={title}>
        <Typography textAlign="center">{message}</Typography>
        <ButtonLink
          to={PATH_DASHBOARD.root}
          title={"Continue"}
          aTag
        />
      </PaperPage>
    );
  }

  if (!emailVerified) {
    return (
      <PaperPage headTitle={"Enroll in TOTP authentication"} title={"Enroll in TOTP authentication"}>
        <EmailVerificationDialog />
      </PaperPage>
    );
  }

  if (step === MFAStep.REFRESH_LOGIN) {
    const usesPasswordAuthentication = isProviderUsed(
      currentUser,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    );
    const usesGoogleSignIn = isProviderUsed(
      currentUser,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
    );

    return (
      <PaperPage headTitle={"Enroll in TOTP authentication"} title={"Enroll in TOTP authentication"}>
        <ReauthenticateForm
          showPasswordAuthentication={usesPasswordAuthentication}
          showGoogleAuthentication={usesGoogleSignIn}
          email={currentUser?.email ?? ""}
          onGoogleSignIn={() => {
            currentUser
              .reauthenticateWithPopup(new firebase.auth.GoogleAuthProvider())
              .then(() => {
                setStep(MFAStep.ENROLL);
              });
          }}
          onSubmit={async (
            values: PasswordFormValues,
            helpers: FormikHelpers<PasswordFormValues>
          ) => {
            const { password } = values;
            const cred = firebase.auth.EmailAuthProvider.credential(
              currentUser?.email ?? "",
              password
            );

            try {
              await currentUser.reauthenticateWithCredential(cred);
              setStep(MFAStep.ENROLL);
            } catch (e: ErrorException) {
              if (e.code !== AuthErrorCode.INVALID_PASSWORD) {
                console.error("Error reauthenticating the current user", e);
                setStep(MFAStep.ERROR);
                return;
              }
              helpers.setFieldError("password", "invalid");
            }
          }}
        />
      </PaperPage>
    );
  }

  if (!currentUser) {
    return null;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <PaperPage headTitle={"Enroll in TOTP authentication"} title={"Enroll in TOTP authentication"}>
      <ValidateTotpCode
        qrCodeUrl={qrCodeUrl}
        onSubmit={async (
          values: QrCodeWithInputFieldValues,
          helpers: FormikHelpers<QrCodeWithInputFieldValues>
        ) => {
          try {
            const verificationCode = values?.verifyCode;

            const multiFactorAssertion =
              TotpMultiFactorGenerator.assertionForEnrollment(
                totpSecretRef.current as TotpSecret,
                verificationCode
              );
            await multiFactor(currentUser).enroll(
              multiFactorAssertion,
              currentUser?.displayName ?? currentUser?.email ?? ""
            );

            setStep(MFAStep.SUCCESS);
          } catch (e: ErrorException) {
            helpers.setFieldError("verifyCode", "invalid");
            console.error("Error occurred trying to enroll a new factor", e);
            setStep(MFAStep.ERROR);
          }
        }}
      />
    </PaperPage>
  );
}
