import { ErrorException } from "../types/settings";

export async function validatePhoneNumber(input: string | undefined) {
  if (!input) {
    return false;
  }

  const { PhoneNumberUtil } = await import("google-libphonenumber");

  const util = PhoneNumberUtil.getInstance();
  try {
    const number = util.parseAndKeepRawInput(input);
    return util.isValidNumber(number);
  } catch (e: ErrorException) {
    return false;
  }
}
