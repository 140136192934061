import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material, lodash
import { alpha, styled } from '@mui/material/styles';
import { Box, CardActionArea, Drawer, Stack, Tooltip, Typography } from '@mui/material';
import { capitalize } from "lodash";
// hooks
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import LogoText from '../../components/LogoText';
import LogoWithSpeechBubble from 'components/_dashboard/LogoWithSpeechBubble';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
import { useCurrentRole } from '../../hooks/useCurrentRole';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

const UserInfo = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 2.5),
  display: 'flex',
  flexDirection: 'row'
}));

const LogoContainer = styled('div')(() => ({
  position: 'absolute',
  bottom: 0,
  width: '100%'
}));

const StyledScrollBar = styled(Scrollbar)(({ theme }) => ({
  background: theme.palette.background.neutral
}));

// ----------------------------------------------------------------------

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={ onToggleCollapse }
        sx={ {
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        } }
      >
        <Box
          sx={ {
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          } }
        />
      </CardActionArea>
    </Tooltip>
  );
}

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const usrRoles = useCurrentRole();

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <StyledScrollBar
      sx={ {
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      } }
    >
      <Stack
        spacing={ 3 }
        sx={ {
          px: 2.5,
          pt: 3,
          pb: 2,
          position: 'relative',
          zIndex: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        } }
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={ RouterLink } to="/" sx={ { display: 'inline-flex' } }>
            <LogoText/>
          </Box>

          <MHidden width="lgDown">
            { !isCollapse && (
              <IconCollapse onToggleCollapse={ onToggleCollapse } collapseClick={ collapseClick }/>
            ) }
          </MHidden>
        </Stack>

        { isCollapse ? (
          <MyAvatar sx={ { mx: 'auto', mb: 2 } }/>
        ) : (
          <AccountStyle>
            <UserInfo>
              <MyAvatar/>
              <Box sx={ { ml: 2 } }>
                <Typography variant="subtitle2" sx={ { color: 'text.primary' } }>
                  { capitalize(user?.displayName) }
                </Typography>
                { Boolean(user?.email) &&
                    <Typography variant="caption" sx={ {
                      color: 'text.primary',
                      width: '160px !important',
                      display: 'block'
                    } } noWrap>{ user?.email }</Typography>
                }
              </Box>
            </UserInfo>
          </AccountStyle>
        ) }
      </Stack>

      <NavSection
        sx={ { position: 'relative', zIndex: 2 } }
        navConfig={ sidebarConfig(usrRoles) }
        isShow={ !isCollapse }
      />

      { !isCollapse && (
        <Stack
          spacing={ 3 }
          alignItems="center"
          sx={ { px: 5, pb: 5, mt: '250px', width: 1, textAlign: 'center', position: 'relative' } }
        >
          <LogoContainer>
            <LogoWithSpeechBubble text={ user?.displayName }/>
          </LogoContainer>

          <div>
            <Typography gutterBottom variant="subtitle1"/>
          </div>
        </Stack>
      ) }
    </StyledScrollBar>
  );

  return (
    <RootStyle
      sx={ {
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      } }
    >
      <MHidden width="lgUp">
        <Drawer
          open={ isOpenSidebar }
          onClose={ onCloseSidebar }
          PaperProps={ {
            sx: { width: DRAWER_WIDTH }
          } }
        >
          { renderContent }
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={ onHoverEnter }
          onMouseLeave={ onHoverLeave }
          PaperProps={ {
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          } }
        >
          { renderContent }
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
