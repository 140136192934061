import { Icon } from "@iconify/react";
import React, { useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from "@mui/material";

// routes
import { PATH_DASHBOARD } from "../../../../routes/paths";

// hooks
import useSettings from "../../../../hooks/useSettings";

// components
import Page from "../../../../components/Page";
import Scrollbar from "../../../../components/Scrollbar";
import SearchNotFound from "../../../../components/SearchNotFound";
import HeaderBreadcrumbs from "../../../../components/HeaderBreadcrumbs";
import { useSnackbar } from "notistack";
import TableToolbar from "../../../../components/common/TableToolbar";
import TableHeader from "../../../../components/common/TableHeader";
import MoreMenu from "../../../../components/common/MoreMenu";
import LoadingScreen from "../../../../components/LoadingScreen";
import { useCopyToClipBoard } from "../../../../hooks/useCopyToClipBoard";
import { ErrorException } from "../../../../types/settings";
import { deleteRoleByIdFromDB, useRoles } from "../../../../utils/firebase/rolesUtil";
import { AccessRole } from "../../../../types/role/CCRoles";
import { format } from "date-fns";
import { dateTimeDisplayFormat } from "../../../../utils/analytics/analyticsDefaults";
import { getComparator } from "../../../../utils/list";
import { filter } from "lodash";


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  { id: "createdOrUpdatedAt", label: "Created/Updated At", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

// ----------------------------------------------------------------------


let RoleState = {
  pageNo: 0,
  pageSize: 20,
  orderBy: "",
  searchText: "",
  order: "desc"
}

export default function RolesList() {
  const { themeStretch } = useSettings();
  const [page, setPage] = useState(RoleState.pageNo || 0);
  const [order, setOrder] = useState<"asc" | "desc">(RoleState.order as "asc" | "desc");
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState(RoleState.orderBy || "createdOrUpdatedAt");
  const [filterName, setFilterName] = useState(RoleState.searchText);
  const [rowsPerPage, setRowsPerPage] = useState(RoleState.pageSize || 5);
  const [deletingId, setDeletingId] = useState<string>();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { copyToClipBoard } = useCopyToClipBoard();

  const {
    roles = [],
    loading,
  } = useRoles()

  const headerActionNode = <Box sx={ { mt: 3, display: "flex", justifyContent: "flex-end" } }>
    <Button
      variant="contained"
      sx={ { mr: 4 } }
      component={ RouterLink }
      state={ { isManualCreated: true } }
      to={ PATH_DASHBOARD.iam.roles.new }
      startIcon={ <Icon icon={ plusFill }/> }
    >
      Create new Role
    </Button>
  </Box>

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    setSelected(checked ? roles?.map(rol => rol.title) : [])
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event?.target?.value ?? 0, 10));
    setPage(0);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, _page: number) => {
    setPage(_page);
  };

  const handleFilterByName = (newFilter: string) => {
    setFilterName(newFilter);
  };

  const applySortFilter = (
    array: AccessRole[],
    comparator: (a: any, b: any) => number,
    query: string
  ) => {
    const stabilizedThis = array.map((el, index) => [el, index] as const);
    stabilizedThis.sort((a, b) => {
      const orderVal = comparator(a[0], b[0]);
      if (orderVal !== 0) return orderVal;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (role) => role.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }


  const handleDeleteRole = async (selectedRole: AccessRole) => {
    try {
      const selectedRoleId = selectedRole.id

      if (!selectedRoleId) return;

      setDeletingId(selectedRoleId);

      if (window.confirm(`Do you really want to delete this Role (${ selectedRole.title })?`)) {
        await deleteRoleByIdFromDB(selectedRoleId);
        enqueueSnackbar("Role Deleted Successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Delete Role cancel by user", {
          variant: "error",
        });
      }
    } catch (e: ErrorException) {
      console.error("FailedToDeleteRole", e);
      enqueueSnackbar("Failed to delete Role", {
        variant: "error",
      });
    } finally {
      setDeletingId("");
    }
  };

  const filteredRoles = applySortFilter(
    roles,
    getComparator(order, orderBy),
    filterName
  );
  const isRoleNotFound = !Boolean(filteredRoles.length) && !loading;

  const onDeleteAction = (item: AccessRole) => {
    return () => handleDeleteRole(item)
  }

  const onEditAction = (id?: string) => {
    return () => {
      navigate(
        `${ PATH_DASHBOARD.iam.roles.root }/${ id }/edit`
      );
    }
  }

  const onUsersList = (roleId?: string, roleName?: string) => {
    return () => {
      navigate(
        `${ PATH_DASHBOARD.iam.roles.root }/${ roleId }/users-list?roleName=${roleName}`
      );
    }
  }
  
  if (loading) {
    return <LoadingScreen/>;
  }

  return (
    <Page title="Roles">
      <Container maxWidth={ themeStretch ? false : "lg" }>
        <HeaderBreadcrumbs
          heading=""
          links={ [] }
          action={headerActionNode}
        />

        <Card>
          <TableToolbar
            numSelected={ selected.length }
            filterName={ filterName }
            onFilterName={ handleFilterByName }
            searchPlaceholder={ "Search Role" }
          />

          <Scrollbar>
            <TableContainer sx={ { minWidth: 800 } }>
              <Table>
                <TableHeader
                  order={ order }
                  orderBy={ orderBy }
                  headLabel={ TABLE_HEAD }
                  rowCount={ roles.length }
                  numSelected={ selected.length }
                  onRequestSort={ handleRequestSort }
                  onSelectAllClick={ handleSelectAllClick }
                  hideCheckbox
                />
                <TableBody>
                  { filteredRoles
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((roleItem: AccessRole) => {
                      const {
                        id,
                        title,
                        createdOrUpdatedAt,
                      } = roleItem;
                      const isItemSelected = selected.indexOf(title) !== -1;

                      return (
                        <TableRow
                          hover
                          key={ `${ title }-${ id }` }
                          tabIndex={ -1 }
                          role="checkbox"
                          selected={ isItemSelected }
                          aria-checked={ isItemSelected }
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="subtitle2" noWrap>
                                { title }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="normal"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={ 2 }
                            >
                              <Typography variant="body1" noWrap>
                                { createdOrUpdatedAt ? format(createdOrUpdatedAt, dateTimeDisplayFormat) : "---" }
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="right">
                            <MoreMenu
                              deleting={ deletingId === id }
                              onDelete={ onDeleteAction(roleItem) }
                              onEdit={ onEditAction(id) }
                              onList={ onUsersList(id, title) }
                              onView={ () => {
                                navigate(
                                  `${ PATH_DASHBOARD.iam.roles.root }/${ id }/view`
                                );
                              } }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }) }
                </TableBody>
                { isRoleNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={ 6 } sx={ { py: 3 } }>
                        <SearchNotFound searchQuery={ filterName }/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) }
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={ [5, 10, 15, 20, 25, 50, 100] }
            component="div"
            count={ roles.length }
            rowsPerPage={ rowsPerPage }
            page={ page }
            onPageChange={ handlePageChange }
            onRowsPerPageChange={ handleChangeRowsPerPage }
          />
        </Card>
      </Container>
    </Page>
  );
}
