import React from "react";
import { Box, Chip, Typography, Container } from "@mui/material";

export default function CustomClaimsByRoles(
  {
    title,
    customClaims,
      style
  }: {
    title: string;
    customClaims: Array<string>;
    style?: Record<string, string>;
  }) {

  if (!customClaims?.length) {
    return null;
  }

  return (
    <Container style={{ padding: 0 }}>
      <Typography my={1} variant={ "subtitle2" } style={style ?? {}}>{ title }</Typography>
      <hr/>

      <Box
        pt={1}
        flexDirection={ "row" }
        display={ "flex" }
        flex={ 1 }
        flexWrap={ "wrap" }
      >
        {
          customClaims?.map(item => (
            <Box key={ item } mt={ 1 } mr={ 1 }>
              <Chip key={ item } label={ item }/>
            </Box>
          ))
        }
      </Box>
    </Container>
  )
}

